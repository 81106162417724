import React, { useEffect } from 'react';
import { DefenceEquipmentsHero } from '../../../common/Components/HeroSection.jsx';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree.jsx';
import SectionFour from './SectionFour';
import { DefenceEquipmentsAcc } from '../../../common/Components/AccordionSection.jsx';
import { DefenceEquipmentsCta } from '../../../common/Components/CtaSection.jsx';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";


const Defence = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <DefenceEquipmentsHero/>
        <SectionTwo/>
        <SectionThree/>
        <SectionFour/>
        <DefenceEquipmentsAcc/>
        <DefenceEquipmentsCta/>
    </>
  )
}

export default Defence;