import React from 'react';
import {Link } from 'react-router-dom';



const Footer = () => {
  
  return (
    <footer >
      <div className="footer-main position-relative bg-blue">
        <div className="footer-shape-1" style={{"backgroundImage":"url(.//images/shape-1.png)"}}>
        </div>
        <div className="footer-shape-2" style={{"backgroundImage":"url(.//images/shape-2.png)"}}>
        </div>
        <div className="footer-top section-space-small">
          <div className="container">
            <div className="row g-4">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                <div className="footer-widget-1">
                  <Link to="./"><figure className="image">
                    <img src="/images/logo-white.webp" alt="Unicon Logo" />
                  </figure></Link>
                  <p className="mt-40 mb-30" style={{ color: "#fff", fontSize: "13px" }}>
                    Trust Unicon for reliable, advanced technology that helps you maintain a cleaner and safer environment.
                  </p>

                  <div className="footer-socials p-relative">
                    <a className='text-decoration-none' target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/UniconEngineersCBE/"><i className="fab fa-facebook-f"></i></a>
                    <a target="_blank" href ="https://www.instagram.com/unicon_esp/" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
                    <a target="_blank" href="https://x.com/EspUnicon/" rel="noopener noreferrer"><i className="bi bi-twitter-x"></i></a>
                    <a  className='text-decoration-none'target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/unicon-engineers/about/"><i className="fab fa-linkedin-in"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@uniconengineers6035"><i className="bi bi-youtube"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-6">
                <div className="footer-widget-2 pl-50">
                  <h5 className="mb-20 footer-title">Quick Links</h5>
                  <ul className="service-list ps-0" style={{width:'fit-content'}}>
                   <Link className="text-decoration-none" to="./"> <li>Home</li></Link>
                   <Link className="text-decoration-none" to="./profile"> <li>Profile</li></Link>
                   <Link className="text-decoration-none" to="./design"> <li>Design</li></Link>
                   <Link className="text-decoration-none" to="./products"> <li>Products</li></Link>
                   <Link className="text-decoration-none" to="./services"> <li>Services</li></Link>
                   <Link className="text-decoration-none" to="./job"><li>Job</li></Link>
                   <Link className="text-decoration-none" to="./catalogues"> <li>Catalogues</li></Link>
                   <Link className="text-decoration-none" to="./questionnaire"><li>Questionnaires</li></Link>
                   <Link className="text-decoration-none" to="./contact"> <li>Contact</li></Link>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-3 col-lg-3 col-md-6">
                <div className="footer-widget-2 pl-50">
                  <h5 className="mb-20 footer-title">Our Services & Products</h5>

                  <h6 className="text-white mb-3">Products</h6>
                  <ul className="service-list mb-4 ps-0">
                  <Link className="text-decoration-none" to="./products#air-pollution"> <li>Air Pollution Control Systems</li></Link>
                  <Link className="text-decoration-none" to="./products#material-handling"> <li>Material Handling Systems</li></Link>
                  <Link className="text-decoration-none" to="./products#process-equipments"> <li>Process Equipments</li></Link>
                  </ul>

                  <h6 className="text-white mb-3">Services</h6>
                  <ul className="service-list ps-0">
                  <Link className="text-decoration-none" to="./services#inspection-testing-services"> <li>Inspection & Testing Services</li></Link>
                  <Link className="text-decoration-none" to="./services#project-management"> <li>Project Management & Maintenance</li></Link>
                  <Link className="text-decoration-none" to="./services#spares_and_support"><li>Spares and Support</li></Link>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                <div className="footer-widget-4 pr-30">
                  <h5 className="mb-20 footer-title">Reach Us @</h5>
                  <div className="contact-foot">
                    <div className="d-flex align-items-start gap-3 pb-4 mb-3 border-bottom-custom">
                      <div className="footer-icon"><i className="bi bi-phone-fill"></i></div>
                      <div className="d-flex flex-column gap-3">
                       <Link to="tel:+919487744417">+91 94877 44417</Link>
                       <Link to="tel:+919442644463">+91 94426 44463</Link>
                       <Link to="tel:+919442644463">+91 94426 44417</Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-start gap-3 pb-4 mb-3 border-bottom-custom">
                      <div className="footer-icon"><i className="bi bi-telephone-fill"></i></div>
                      <div className="d-flex flex-column gap-3">
                       <Link to="tel:+914222362167">+91 422 2362167</Link>
                       <Link to="tel:+914222362169">+91 422 2362169</Link>
                       <Link to="tel:+914222362174">+91 422 2362174</Link>
                      </div>
                    </div>
                   
                    <div className="d-flex align-items-start gap-3">
                      <div className="footer-icon"><i className="bi bi-envelope-at-fill"></i></div>
                      <div className="d-flex flex-column gap-3">
                       <Link to="mailto:marketing@uniconinter.com">marketing@uniconinter.com</Link>
                       <Link to="mailto:info@uniconinter.com">info@uniconinter.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom pt-10 pb-10 d-md-flex align-items-center justify-content-between position-relative z-index-1">
            <p>© 2024 Unicon, All rights reserved.</p>
            <p>Designed by<Link to="https://capdigisoft.com/" target="_blank" rel="noopener noreferrer"> CAP Digisoft</Link></p>
          </div>
        </div>
      </div>
    </footer>
     
  );
};

export default Footer;
