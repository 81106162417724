import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { EspTable } from '../../../common/Components/TablesProduct';


const SectionFive = () => {
    const [activeKey, setActiveKey] = useState('home');

    return (
        <>
             
            <section className="product-specification ip-padding ps-large-device bg-light position-relative wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp"}}>
                <div className="container d-lg-block d-none">

                    <Tab.Container id="left-tabs-example " className="" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                        <div>
                            <div className=''>
                                <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="home" className='mt-0'>Product Specifications</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="profile">Unique Features</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                            <div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="home">
                                        <div className="feature-product postion-relative">
                                            <div className="row g-5">

                                                <div className="col-lg-5">
                                                    <div className="text-start aos-init aos-animate" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
                                                        <img className="img-fluid" width="100%" src="/images/products/electrostatic-percipitators/cutout1.webp" alt="Customer Data" />
                                                    </div>
                                                    <div className="key-note lh-base mt-4">
                                                        <small className="lh-base">
                                                            *Other Industry —  <br />
                                                            Cement: Kiln, Mills, Cooler;<br />
                                                            Iron &amp; Steel: Sintering, Pelletization, Sponge Iron Kiln, Cast House, Stock House;<br />
                                                            Biomass fired boilers; <br />
                                                            Waste to Energy plants;<br />
                                                            Pulp &amp; Paper: Recovery Boiler, Lime Kiln;<br />
                                                            Non-Ferrous- Smelter, Converter;<br />
                                                            Oil &amp; Gas: FCCU etc.</small>
                                                    </div>
                                                </div>

                                                <div className="col-lg-7">
                                                    <div className="h-100 border-left ps-lg-5">
                                                        <div className="table-responsive">
                                                            <EspTable />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="profile">
                                        <div className="feature-product position-relative">
                                            <div class="row g-5">
                                                <div class="col-lg-12 pe-5 pe-md-0">
                                                    <h4 class="section-sub_heading mb-3">Special features of our ESP(s)</h4>
                                                    <ul class="mb-0 list-unstyled features-ul" style={{ "display": "flex", "flexWrap": "wrap", "gap": "10px" }}>
                                                        <li>Proven Particulate Matter Removal <span><i class="bi bi-chevron-left"></i></span> 10mg/Nm<sup>3</sup></li>
                                                        <li>High Availability</li>
                                                        <li>Low Maintenance</li>
                                                        <li>Reduced breakdown</li>
                                                        <li>Most Economical Design</li>
                                                        <li>Lower Operating Cost</li>
                                                        <li>Optimum Performance under Varying Load Conditions</li>
                                                        <li>Optimum Power Consumption in Operation</li>
                                                        <li>Next Generation Electronic Controllers</li>
                                                        <li>Minimum Horizontal Space Occupation in the Project</li>
                                                    </ul>

                                                    <ul class="features-post_para" style={{ "paddingLeft": "30px", "marginTop": "20px" }}>
                                                        <li>Our ESP design will incorporate CFD for even distribution of gas inside ESP to ensure the complete utilization of collecting area.</li>
                                                        <li>Unicon is having Expertise in Both Tumbling Type and Top Rapping System.</li>
                                                        <li>We offer Spiral/Rigid Emitting Electrodes to ensure the proper Corona generation inside the ESP for better collection efficiency.</li>
                                                        <li>Individual Collecting electrodes are suspended from top to form a curtain. This also ensures that the Collecting system is not bulky and thus ensures good rapping characteristics.</li>
                                                        <li>Unicon have supplied more than 300 numbers of ESP for Boiler. By the years, Unicon had accrued expertise in installing ESP for Boiler which are using fuels like Coal, Bagasse, Spent wash, Slop, Dolochar, Pet Cock, Rice Husk, Rice Straw, Wood Chips, Saw Dust, Chilly Spent, etc,.</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </Tab.Pane>


                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>

                <div className='container d-lg-none d-block '>
                    <div className="feature-product postion-relative" style={{"padding":"30px 15px","background":"#fff","borderRadius":"8px","boxShadow":"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", border:'none'}}>
                        <div class="section-title text-center mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                            <h4 class="section-heading text-center">Product Specifications</h4>
                        </div>
                        <div className="row g-5">

                            <div className="col-lg-5">
                                <div className="text-start aos-init aos-animate" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
                                    <img className="img-fluid" src="/images/products/electrostatic-percipitators/cutout1.webp" alt="Customer Data" />
                                </div>
                                <div className="key-note lh-base mt-4">
                                    <small className="lh-base">
                                        *Other Industry —  <br />
                                        Cement: Kiln, Mills, Cooler;<br />
                                        Iron &amp; Steel: Sintering, Pelletization, Sponge Iron Kiln, Cast House, Stock House;<br />
                                        Biomass fired boilers; <br />
                                        Waste to Energy plants;<br />
                                        Pulp &amp; Paper: Recovery Boiler, Lime Kiln;<br />
                                        Non-Ferrous- Smelter, Converter;<br />
                                        Oil &amp; Gas: FCCU etc.</small>
                                </div>
                            </div>

                            <div className="col-lg-7">
                                <div className="h-100 border-left ps-lg-5">
                                    <div className="table-responsive">
                                        <EspTable />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="feature-product position-relative mt-4" style={{"padding":"30px 15px","background":"#fff","borderRadius":"8px","boxShadow":"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", border:'none'}}> 
                    <div class="section-title text-center mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                            <h4 class="section-heading text-center">Unique Features
                            </h4>
                        </div>
                        <div class="row g-4">
                            <div class="col-lg-12 pe-5 pe-md-0">
                                <h4 class="section-sub_heading mb-3">Special features of our ESP(s)</h4>
                                <ul class="mb-0 list-unstyled features-ul" style={{ "display": "flex", "flexWrap": "wrap", "gap": "10px" }}>
                                    <li>Proven Particulate Matter Removal <span><i class="bi bi-chevron-left"></i></span> 10mg/Nm<sup>3</sup></li>
                                    <li>High Availability</li>
                                    <li>Low Maintenance</li>
                                    <li>Reduced breakdown</li>
                                    <li>Most Economical Design</li>
                                    <li>Lower Operating Cost</li>
                                    <li>Optimum Performance under Varying Load Conditions</li>
                                    <li>Optimum Power Consumption in Operation</li>
                                    <li>Next Generation Electronic Controllers</li>
                                    <li>Minimum Horizontal Space Occupation in the Project</li>
                                </ul>

                                <ul class="features-post_para" style={{ "paddingLeft": "30px", "marginTop": "20px" }}>
                                    <li>Our ESP design will incorporate CFD for even distribution of gas inside ESP to ensure the complete utilization of collecting area</li>
                                    <li>Unicon is having Expertise in Both Tumbling Type and Top Rapping System</li>
                                    <li>We offer Spiral/Rigid Emitting Electrodes to ensure the proper Corona generation inside the ESP for better collection efficiency.</li>
                                    <li>Individual Collecting electrodes are suspended from top to form a curtain. This also ensures that the Collecting system is not bulky and thus ensures good rapping characteristics.</li>
                                    <li>Unicon have supplied more than 300 numbers of ESP for Boiler. By the years, Unicon had accrued expertise in installing ESP for Boiler which are using fuels like Coal, Bagasse, Spent wash, Slop, Dolochar, Pet Cock, Rice Husk, Rice Straw, Wood Chips, Saw Dust, Chilly Spent, etc,.</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            <style>
                {`
                    .nav-pills .nav-link {
                        font-size: 20px;
                        padding: 0px;
                        margin-right: 20px;
                        padding-bottom: 5px;
                        color:#101010;
                    }
                    .nav-link:hover {
                        border-bottom: 4px solid;
                        color: #0a2e80;
                        border-radius:0;
                     }
                    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
                        background-color: unset;
                        border-bottom: 4px solid;
                        color: #0a2e80;
                        border-radius: 0;
                    }
                    .nav{
                        border-bottom:1px solid #dee2e6;
                        }
                    .feature-product {
                    margin-top:20px;
                        background: #fff;
                        padding: 20px;
                        border-radius: 15px;
                        border: 1px solid #ddd;
                    }
                    .features-ul li {
                        font-size: 18px;
                        padding: 3px 8px;
                        background: #f8f9fa;
                        border-radius: 40px;
                        border: 1px solid #ddd;
                    }
                    ul.features-post_para li {
                        list-style: disc;
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                    @media only screen and (max-width: 575px) {
                        .features-ul li, ul.features-post_para li{
                        font-size:16px !important;
                           

                        }
                     }
        `}
            </style>
        </>
    )
}

export default SectionFive;