import React, { useEffect } from 'react';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import CtaDesign from './CtaDesign';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Design = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
    
    <SectionOne/>
    <SectionTwo/>
    <SectionThree/>
    <SectionFour/>
    <CtaDesign/>
   
    </>
  )
}

export default Design;