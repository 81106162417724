import React, { useEffect } from 'react';
import WOW from 'wowjs';


const SectionOne = () => {

  
    return (
        <>
            <section className="cust-padding3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0s" data-wow-offset="100" style={{ "visibility": "visible", "animationDelay": "1s", "animationName": "fadeInLeft" }}>
                            <div className="prof-sec1">
                                <div className="">
                                    <h6 className="mb-4 text-red">Our Profile</h6>
                                    <h4 className="mb-3 section-heading"><img src="/images/gear-leaf.webp" className="gear-leaf" alt="" style={{ width: "50px" }} /> Setting the Standard for environmental protection</h4>
                                </div>
                                <p className="mb-0">At UNICON we are constantly investing in resources for Product development with an objective to provide Market-leading products that reduce our customers Downtime as well as keep the environment safe.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 wow fadeInRight" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": "1s", "animationName": "fadeInLeft" }}>
                            <div className="text-center mt-lg-0 mt-4">
                                <img src="/images/green-tech.webp" alt="" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <style>
                {`

    @media (max-width: 991px) {
        .gear-leaf {
            width: 38px !important;
        }
    }
     @media (max-width: 575px) {
    .gear-leaf {
        width: 22px !important;
    }

    
    `}
            </style>
        </>
    )
}

export default SectionOne;