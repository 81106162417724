import React from "react";
import {  Link } from "react-router-dom";

const SectionOne = () => {
 

  
  return (
    <>
      <section className="cust-padding3 bg-light" id="productslist">
        <div className="container">
          <div className="text-center">
            <h4 className="section-heading text-dark serv-prod-head">
              Our Products
            </h4>
          </div>

          <div
            className="mb-5 wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
            id="air-pollution"
          >
            <h5 className="prods-head mb-4 pb-2">
              Air Pollution Control Systems{" "}
              <i className="bi bi-dash-lg text-red"></i>
            </h5>
            <div className="row g-4">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/low-nox-burners" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/apcs-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Low NOx Burners</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link
                  to="/electrostatic-precipitator"
                  className="prods-cards"
                >
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/apcs-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Electrostatic Precipitators (ESP)</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link
                  to="/bag-house"
                  className="prods-cards"
                >
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/apcs-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Bag Filter / Bag House</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link
                  to="/flue-gas-conditioning-system"
                  className="prods-cards"
                >
                  <div className="prods-img">
                    <img
                      src="/images/products/flue-gas-conditioning/img-6.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Flue Gas Conditioning System</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/afgc" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/apcs-4.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Ammonia Flue Gas Conditioning</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/mist-separators" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/mist-separators-candle-filters/sec4.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Mist Separators – Candle Filters</h6>
                </Link>
              </div>
            </div>
          </div>

          <hr
            className="my-hr wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
          />

          <div
            className="wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
            id="material-handling"
          >
            <h5 className="prods-head  mb-4 pb-2">
              Material Handling Systems{" "}
              <i className="bi bi-dash-lg text-red"></i>
            </h5>
            <div className="row g-4">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link
                  to="/pneumatic-conveying-system"
                  className="prods-cards"
                >
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Pneumatic Conveying System</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link
                  to="/rotary-airlock-valve"
                  className="prods-cards"
                >
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Rotary Airlock Valve</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/slide-gate" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Slide Gate</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/double-cone-valve" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-4.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Double Cone Valve</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/double-flap-valve" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-5.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Double Flap Valve</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/clam-shell-gate" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-6.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Clam Shell Gate</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/dome-valve" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-7.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Dome Valve</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link
                  to="/drag-chain-conveyor"
                  className="prods-cards"
                >
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-8.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Drag Chain Conveyor</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/belt-conveyor" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-9.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Belt Conveyor</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/screw-conveyor" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/mhs-10.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Screw Conveyor</h6>
                </Link>
              </div>
            </div>
          </div>

          <hr
            className="my-hr wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
          />

          <div
            className="wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
            id="process-equipments"
          >
            <h5 className="prods-head mb-4 pb-2">
              Process Equipments <i className="bi bi-dash-lg text-red"></i>
            </h5>
            <div className="row g-4">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/silencers" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/pe-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Silencers</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/defence" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/pe-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Defence Equipments</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/storage-tank" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/pe-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Storage Tank</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/agitators" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/pe-4.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Agitators</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/decomposers" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/pe-5.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Decomposers</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/pressure-vessels" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/pe-6.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Pressure Vessels</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/jacketed-vessels" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/products/products-list/pe-7.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Jacketed Vessels</h6>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionOne;
