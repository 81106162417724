import React, { useEffect } from 'react';
import { DecomposersHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { DecomposersAcc } from '../../../common/Components/AccordionSection';
import { DecomposersCta } from '../../../common/Components/CtaSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Decomposers = () => {
    useEffect(() => {
        // Force scroll to top when the Profile component mounts
        window.scrollTo(0, 0);
    
        setTimeout(() => {
          new WOW.WOW().init();
        }, 100); // 100ms delay
      }, []);
    return (
        <>
            
            <DecomposersHero/>
            <SectionTwoThree/>
            <SectionFour/>
            <DecomposersAcc />
            <DecomposersCta/>
        </>
    )
}

export default Decomposers;