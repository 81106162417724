import React, { useEffect } from 'react';
import { CPSHero } from '../../../common/Components/HeroSection';
import { CementSpareCta } from '../../../common/Components/CtaSection';
import { CementSparesQUickLinks } from '../../../common/Components/QuickLinksSection';
import { CementPlantTable } from '../../../common/Components/TablesProduct';
import { CementPlantSS } from '../../../common/Components/ServiceSecondSec';
import { CementPlantTabs } from '../../../common/Components/TabsSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Cement = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <CPSHero/>
        <CementPlantSS/>
        <CementPlantTabs/>
        <CementPlantTable/>
        <CementSpareCta/>
        <CementSparesQUickLinks/>
    </>
  )
}

export default Cement;