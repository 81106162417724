import React from 'react'

const ErrorPage = () => {
  return (
    <>
      <section className='error-wrapper' style={{ padding: '200px 0 100px 0' }}>
        <div className="container">
          <div className='d-flex flex-column align-items-center'>
            <img src="/images/404.webp" alt="404-error" className='img-fluid' style={{borderRadius:'8px', width: '460px', paddingBottom: '24px'}}/>
            <h3 className="mb-2" style={{ textAlign: 'center' }}>Page Not Found!</h3>
            <p style={{ fontSize: '18px', textAlign: 'center', width: '600px' }}>Sorry, the requested page cannot be located. It may have been moved or removed. Please navigate back to the homepage.</p>
            <a class="primary-btn-1 btn-hover mt-3" href="/">Bact to Home</a>
          </div>
        </div>
      </section>
      <style>
        {`
          @media(max-width:767px){
          .error-wrapper{
          padding :150px 0 50px 0 !important ;
          }
          .error-wrapper img{
          width:360px !important;
          }
            .error-wrapper h3{
            font-size:24px !important;
            }
            .error-wrapper p{
            width:auto !important;
            margin-bottom:0px !important;
            }
          }
        `}
      </style>
    </>
  )
  
}


export default ErrorPage;