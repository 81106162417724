import React from 'react';
import { Link } from 'react-router-dom';


const SectionTwoThree = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ boxShadow:'none', "borderRadius": "8px", backgroundColor: '#f4f4f4', padding:'14px  0 0' }} className="w-100 img-fluid" src="/images/products/double-flap-valve/dfp-main.webp" alt="" />

                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2">Durable Double Flap Valves</h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span> Double Flap Valves are engineered for high-pressure applications, delivering high performance with minimal maintenance. Designed to minimize friction and transmission losses, they ensure reliable operation and durability in industrial environments.</p>
                                <div className="d-flex gap-3  mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Reliable Sealing</li>
                                        <li><i className="bi bi-check-circle"></i> Optimized Performance</li>
                                        <li><i className="bi bi-check-circle"></i> Durable Construction</li>

                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none ps-0">
                                        <li><i className="bi bi-check-circle"></i> Low Maintenance</li>
                                        <li><i className="bi bi-check-circle"></i> High Pressure Capability</li>
                                        <li><i className="bi bi-check-circle"></i> Versatile Use</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-xl-6 col-md-6  p-relative">
                            <div className="bn-content-wrapper wow fadeInRight" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>

                                <h4 className="section-heading mb-2 pe-5 pe-md-0 w-md-75 w-100 mx-auto "> Why Choose UNICON</h4>
                                <p className="section-paragraph">UNICON stands out for its commitment to excellence in every aspect of valve design and manufacturing. Our Double Flap Valves are crafted with precision and reliability to meet the highest industry standards.</p>
                                <div className="d-flex gap-3  mt-4 ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Quality Assurance</li>
                                        <li><i className="bi bi-check-circle"></i> Industry Expertise</li>
                                        <li><i className="bi bi-check-circle"></i> Innovation</li>
                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none ps-0">
                                        <li><i className="bi bi-check-circle"></i> Global Reach</li>
                                        <li><i className="bi bi-check-circle"></i> Customer Satisfaction</li>
                                        <li><i className="bi bi-check-circle"></i> Custom Solutions</li>
                                    </ul>
                                </div>
                                <div className="mt-4">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center mt-lg-0 mt-3">

                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/double-flap-valve/dfp-main2.webp" alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`

                .ar, .ad{
                    font-size:30px;
                }
                    .adm{
                     font-size: 18px;
                      margin-top: -18px;
                    }
                .flow-diagram {
                    display: grid;
                    grid-template-columns: 1fr 50px 1fr 50px 1fr;
                    grid-template-rows: repeat(5, auto);
                    gap: 10px;
                    align-items: center;
                    text-align: center;
                }
                .box {
                    padding: 10px;
                    border-radius:8px;
                    font-weight: 600;
                    color: #1a1a1a;
                    border: 1px solid;
                    background-color: #fff;
                    position: relative;
                }
        
        @media only screen and (max-width: 991px) {
            .icon-list.style-three {
                display: flex;
            }
            ul.icon-list.style-three li {
                align-items: center;
                margin-bottom: 0;
                text-align: center;
            }
        }
        @media only screen and (max-width: 575px) {
            .flex-sm-d-wrap {
                flex-wrap: wrap;
            }
        }
        `}
            </style>
        </>
    )
}

export default SectionTwoThree;