import React, { useEffect } from 'react';
import IntroSection from './IntroSection';
import OpeningsSection from './OpeningsSection';
import ContactSection from './ContactSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Job = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <IntroSection/>
        <OpeningsSection/>
        <ContactSection/>
    </>
  )
}

export default Job;