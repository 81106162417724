import React, { useState } from 'react';
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ServcieSection = () => {
  const [activeKey, setActiveKey] = useState('home');

  return (

    <>

      <section className="service-tab-section bg-color-1 p-relative">
        <div className="bg-image  cust-padding" style={{ backgroundImage: `url(/images/shapes/bg-1.webp)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

          <div className="container">
            <div className="service-tab-title-area mb-70 wow fadeInUpBig" data-wow-delay=".1s">
              <div className="title-box wow fadeInLeft" data-wow-delay=".5s">
                <h4 className="section-heading text-white p-relative">Our Top Services</h4>
              </div>
              <Link to="/services" className="primary-btn-4 btn-hover ouline-btn-white mt-lg-4 mt-4">all services <i className="bi bi-arrow-right ps-1" style={{ fontSize: '20px' }}></i></Link>

            </div>

            <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
              <Row>
                <Col lg={4} className=''>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="home" className='mt-0'>Up-gradation of APC Equipment</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="profile">Retrofits & Shutdown Maintenance</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="contact">Spares for ESP, Bag Filters & AHS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="gdTest">Computational Fluid Dynamics & GD Test</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg={8}>
                  <Tab.Content>
                    <Tab.Pane eventKey="home">
                      <div class="service-tab-content p-relative">
                        <div class="tab-bg-shape" style={{ backgroundImage: `url(/images/shapes/shape-32.webp)`, backgroundSize: 'cover'}}></div>
                        <figure class="image p-relative mb-0">
                          <img src="/images/apc-equipment-1.webp" alt="" className='' />
                        </figure>
                        <div class="content p-relative mt-xl-0 mt-lg-3 mt-4 ps-xl-5">
                          <h5 class="">Up-gradation of APC Equipment</h5>
                          <p class="pt-15 pb-10 pt-3">UNICON offers expert up-gradation services for APC equipment, ensuring enhanced performance and reliability with the latest technology and skilled support.</p>
                          <ul class="tab-list-content ps-0">
                            <li>Improved System Efficiency and Performance</li>
                            <li>Advanced Technology Integration</li>
                            <li>Skilled Support and Maintenance</li>
                          </ul>
                          <Link to="/services" className='primary-btn-1 btn-hover mt-3'>View Service <i className="bi bi-arrow-right ps-2"></i></Link>

                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="profile">
                    <div class="service-tab-content p-relative">
                        <div class="tab-bg-shape" style={{ backgroundImage: `url(/images/shapes/shape-32.webp)`, backgroundSize: 'cover'}}></div>
                        <figure class="image p-relative mb-0">
                          <img src="/images/retrofits.webp" alt="" className='' />
                        </figure>
                        <div class="content p-relative mt-xl-0 mt-lg-3 mt-4 ps-xl-5">
                          <h5 class="">Retrofits & Shutdown Maintenance</h5>
                          <p class="pt-15 pb-10 pt-3">UNICON, with spares, tools, and skilled manpower, ensures quick delivery and immediate attention for retrofits and shutdown maintenance, including ESP revamping, project execution, and preventive maintenance.</p>
                          <ul class="tab-list-content ps-0">
                            <li>Comprehensive ESP Revamping and Retrofits</li>
                            <li>Diagnostic Study and Preventive Maintenance Design</li>
                            <li>Continuous System Updates for Efficiency</li>
                          </ul>
                          <Link to="/services" className='primary-btn-1 btn-hover mt-3'>View Service <i className="bi bi-arrow-right ps-2"></i></Link>

                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="contact">
                    <div class="service-tab-content p-relative">
                        <div class="tab-bg-shape" style={{ backgroundImage: `url(/images/shapes/shape-32.webp)`, backgroundSize: 'cover'}}></div>
                        <figure class="image p-relative mb-0">
                          <img src="/images/spares.webp" alt="" className='' />
                        </figure>
                        <div class="content p-relative mt-xl-0 mt-lg-3 mt-4 ps-xl-5">
                          <h5 class="">Spares for ESP, Bag Filters & AHS</h5>
                          <p class="pt-15 pb-10 pt-3">Unicon supplies top-quality spare parts for ESPs, bag filters, and AHS. Our components improve reliability, reduce downtime, and extend the lifespan of your equipment.</p>
                          <ul class="tab-list-content ps-0">
                            <li>Precision-engineered for optimal compatibility</li>
                            <li>Reduces downtime and enhances operational reliability</li>
                            <li>Prolongs equipment lifespan for long-term efficiency</li>
                          </ul>
                          <Link to="/services" className='primary-btn-1 btn-hover mt-3'>View Service <i className="bi bi-arrow-right ps-2"></i></Link>

                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="gdTest">
                    <div class="service-tab-content p-relative">
                        <div class="tab-bg-shape" style={{ backgroundImage: `url(/images/shapes/shape-32.webp)`, backgroundSize: 'cover'}}></div>
                        <figure class="image p-relative mb-0">
                          <img src="/images/cfd.webp" alt="" className='' />
                        </figure>
                        <div class="content p-relative mt-xl-0 mt-lg-3 mt-4 ps-xl-5">
                          <h5 class="">Computational Fluid Dynamics & GD Test</h5>
                          <p class="pt-15 pb-10 pt-3">Unicon leverages advanced CFD software and 35 years of expertise to analyze ESP properties, offering precise GD testing to optimize system performance and reliability.</p>
                          <ul class="tab-list-content ps-0">
                            <li>Computerized Fluid Dynamics (CFD) Analysis</li>
                            <li>Gas Distribution (GD) Testing and Optimization</li>
                            <li>Post-Installation Verification and Reporting</li>
                          </ul>
                          <Link to="/services" className='primary-btn-1 btn-hover mt-3'>View Service <i className="bi bi-arrow-right ps-2"></i></Link>

                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>

      <style>
        {`
        .service-tab-title-area {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom:40px;
            }
        .service-tab-section .nav-pills .nav-link.active{
        background-color:#cc0017;
        color:#fff;
        font-size:18px;
        font-weight:800;
        line-height:1.4;
        padding:24px;
        border-radius:0px;
        text-align:center;
        }

        .service-tab-section .nav-link{
            margin-top:14px;
            background-color:#2F3B4E;
             color:#fff;
            font-size:18px;
            font-weight:800;
            line-height:1.4;
            padding:24px;
            border-radius:0px;
            text-align:center;
         }

        .service-tab-section .tab-content{
                background-color:#fff;
                border-radius:4px;
                height:100%;
        }
        .service-tab-content .tab-bg-shape {
            position: absolute;
            top: -18px;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            z-index:-1;
        }
        .service-tab-content {
            padding: 28px;
            display: flex;
            position:relative;
            z-index:11;
        }
        .service-tab-content ul.tab-list-content li {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            margin-bottom: 15px;
            margin-left: 10px;
            padding-left: 24px;
           position: relative;
        }
          
        @media (max-width: 1200px) {
            .service-tab-content {
                display: block;
            }
        }
         @media (max-width: 991px) {
            .service-tab-section  .nav-pills .nav-link{
                text-align:left !important;
            }
             .service-tab-section  .nav{
                margin-bottom:34px !important;
            }
            .service-tab-title-area{
           display:block !important;
            }
        }
        
        @media (max-width:575px) {
            .service-tab-section .nav-link{
            font-size:16px !important;
            font-weight:700 !important;
            padding:16px !important;
         } 
        .service-tab-content {
          padding:14px !important;
        }
         .service-tab-content .tab-bg-shape {
            top: -23px !important;
        }
        }
         @media (max-width:420px) {
            .service-tab-content img{
            max-width:100% !important;
            height:auto !important;
         } 
        }
        
       
                        
        `}
      </style>
    </>



  );
}

export default ServcieSection;
