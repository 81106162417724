import React from 'react';



const SectionTwoThree = () => {
    return (
        <>
             
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-xl-6 col-lg-5 col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="img-fluid" src="/images/products/electrostatic-percipitators/esp-main.webp" alt="" />

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-6 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-lg-4 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2">The <span className="text-primary-red">Unicon</span> Edge <br /> in Electrostatic Precipitation</h4>

                                <div className="d-flex gap-3 flex-wrap mt-4  ">
                                    <ul className="icon-list style-two me-5 ps-0">
                                        <li><i className="bi bi-check-circle"></i> Ionization</li>
                                        <li><i className="bi bi-check-circle"></i> Migration &amp; Collection</li>
                                        <li><i className="bi bi-check-circle"></i> Robust Construction</li>

                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none ps-0">
                                        <li><i className="bi bi-check-circle"></i> Particle Discharge</li>
                                        <li><i className="bi bi-check-circle"></i> Customizable Designs</li>
                                        <li><i className="bi bi-check-circle"></i> Technical Support</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <a href="" className="bg-less-btn"  data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
        <div className="container">
            <div className="row g-lg-5 g-3 align-items-center">
                <div className="col-xl-6 col-lg-7 col-md-12 p-relative">
                    <div className="bn-content-wrapper wow fadeInLeft" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft"}}>

                        <h4 className="section-heading mb-5 pe-5 pe-md-0 w-md-75 w-100 mx-auto text-lg-start text-center"><span className="text-primary-red">Unicon's</span> Electrostatic Precipitators excel with unique performance features</h4>
                        <div className="d-flex gap-5 flex-sm-d-wrap justify-content-lg-start justify-content-center">
                            <ul className="icon-list style-three me-lg-5 gap-5 ps-0">
                                <li className="flex-column align-items-center">
                                    <span className="image-holder"><img width="36" src="/images/products/electrostatic-percipitators/icons/icon1.webp" className='text-center' alt=""/></span>
                                    Lower emission
                                </li>
                                <li className="flex-column align-items-center">
                                    <span className="image-holder"><img width="36" src="/images/products/electrostatic-percipitators/icons/icon2.webp" alt=""/></span>
                                    Energy Efficiency
                                </li>
                            </ul>
                            <ul className="icon-list style-three gap-5 ps-0">
                                <li className="flex-column align-items-center">
                                    <span className="image-holder"><img width="36" src="/images/products/electrostatic-percipitators/icons/icon3.webp" alt=""/></span>
                                    Ease of Maintenance
                                </li>
                                <li className="flex-column align-items-center">
                                    <span className="image-holder"><img width="36" src="/images/products/electrostatic-percipitators/icons/icon4.webp" alt=""/></span>
                                    Advanced Controls
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>
                <div className="col-xl-6 col-lg-5 col-md-12 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft"}}>
                    <figure className="image m-img text-center vertical-align-center mb-0 ps-lg-5">
                        <img className="w-md-100 img-fluid" src="/images/products/electrostatic-percipitators/cutout2.gif" alt="" style={{borderRadius:"8px"}}/>
                    </figure>
                </div>
            </div>
        </div>
    </section>
    <style>
        {`
        
        @media only screen and (max-width: 991px) {
            .icon-list.style-three {
                display: flex;
            }
            ul.icon-list.style-three li {
                align-items: center;
                margin-bottom: 0;
                text-align: center;
            }
        }
        @media only screen and (max-width: 575px) {
            .flex-sm-d-wrap {
                flex-wrap: wrap;
            }
        }
        `}
    </style>
        </>
    )
}

export default SectionTwoThree;