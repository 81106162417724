import React from 'react'
import { SlideGateHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { SlideGateAcc } from '../../../common/Components/AccordionSection';
import { SlideGateCta } from '../../../common/Components/CtaSection';
import { SlideGateQUickLinks } from '../../../common/Components/QuickLinksSection';

const SlideGate = () => {
  return (
    <>
        <SlideGateHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <SlideGateAcc/>
        <SlideGateCta/>
        <SlideGateQUickLinks/>
    </>
  )
}

export default SlideGate;