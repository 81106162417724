import React from 'react';

const SectionOne = () => {
    const scrollToSection = () => {
        const section = document.getElementById('rop');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <>
       <section className="cust-padding3">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"1s","animationName":"fadeInLeft"}}>
                    <div className="prof-sec1">
                        <div className="">
                            <h4 className="mb-2 section-heading">Design & Engineering</h4>
                        </div>

                        <p className="mb-4 pe-lg-5 pe-0">With in-depth knowledge on all streams of designing.
                            Equipped with latest design, simulation and planning software.
                        </p>
                        
                        <a className="primary-btn-1 btn-hover mt-24 cursor-pointer"  onClick={scrollToSection}>
                            Range of Products <i className="bi bi-arrow-down-short"></i>
                        </a>
                    </div>
                </div>

                <div className="col-lg-6 wow fadeInRight" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"1s","animationName":"fadeInLeft"}}>
                    <div className="text-center mt-lg-0 mt-5">
                        <img src="/images/factory.webp" alt="" className='img-fluid'/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default SectionOne;