import React, { useEffect } from 'react';
import { EmissionTestingHero } from '../../../common/Components/HeroSection';
import { EmissionSS } from '../../../common/Components/ServiceSecondSec';
import { EmissionCta } from '../../../common/Components/CtaSection';
import { EmissionQUickLinks } from '../../../common/Components/QuickLinksSection';
import { EmissionTestingScroll } from '../../../common/Components/ScrollSec';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Emission = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <EmissionTestingHero/>
        <EmissionSS/>
        <EmissionTestingScroll/>
        <EmissionCta/>
        <EmissionQUickLinks/>
    </>
  )
}

export default Emission;