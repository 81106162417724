import React, { useEffect } from 'react';
import { DrgaChainHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { DragChainAcc } from '../../../common/Components/AccordionSection';
import { DragChainCta } from '../../../common/Components/CtaSection';
import { DragChainQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Index = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <DrgaChainHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <DragChainAcc/>
        <DragChainCta/>
        <DragChainQUickLinks/>
    </>
  )
}

export default Index;