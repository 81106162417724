import React, { useEffect } from 'react';
import { SupervisoryServiceHero } from '../../../common/Components/HeroSection';
import { SupervisorySS } from '../../../common/Components/ServiceSecondSec';
import { SupervisoryServiceScroll } from '../../../common/Components/ScrollSec';
import { SupervisoryCta } from '../../../common/Components/CtaSection';
import { SupervisoryQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Supervisory = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <SupervisoryServiceHero/>
        <SupervisorySS/>
        <SupervisoryServiceScroll/>
        <SupervisoryCta/>
        <SupervisoryQUickLinks/>
    </>
  )
}

export default Supervisory;