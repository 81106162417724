import React from 'react';
import { Link } from 'react-router-dom';

const SectionTwo = () => {
    return (
        <>
            <section className="section-unicon_inerco " style={{ padding: "150px 0" }}>
                <div className="container">
                    <div className="d-md-flex ">
                        <div className="w-md-50 pe-lg-4 pe-2 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery" style={{ "position": "relative", "textAlign": "center" }}>
                                <img className="gallery-parent img-fluid" src="/images/products/low-nox-burners/burner2.webp" alt="" />
                                <img className="gallery-child_left img-fluid" src="/images/products/low-nox-burners/burner1.webp" alt="" />
                                <img className="gallery-child_right img-fluid" src="/images/products/low-nox-burners/burner3.webp" alt="" />
                            </div>
                        </div>
                        <div className="w-md-50 ps-md-4 mt-md-0 mt-4 ot-md-0 pt-2 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper">
                                <h4 className="section-heading mb-2"><span>UNICON</span> & <span>INERCO</span> Alliance</h4>
                                <h6 className="section-sub_heading  mb-4">Cleaner Combustion, Greener Industry</h6>
                                <div className="d-lg-flex d-none gap-5 align-items-start mb-4 ">
                                    <img className="img-fluid" src="/images/products/low-nox-burners/logo-sm.webp" alt="" style={{ width: '53px' }} />
                                    <img className="img-fluid mt-2" src="/images/products/low-nox-burners/inerco.webp" alt="" style={{ width: '128px' }} />
                                </div>
                                <p className="section-paragraph "><b>Unlock Efficiency:</b> UNICON partners with INERCO to tackle NOx emissions head-on. Our tailored solutions enhance boiler performance while reducing environmental impact. From burner upgrades to OFA systems, we’re your path to cleaner air and operational flexibility. Choose UNICON and embrace sustainability! </p>
                                <div className="mt-4">
                                    <a className="bg-less-btn" data-bs-toggle="modal"
                                        data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <style>
                {`
        .banner-gallery img {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            object-fit: unset !important;
            border-radius: 0.75rem;
        }
        .gallery-child_left {
            position: absolute;
            left: 0px;
            top: -70px;
            width: 220px;
            -webkit-animation: bounce 2s ease infinite alternate;
        }
        .gallery-child_right {
            position: absolute;
            right: 0px;
            bottom: -50px;
            width: 220px;
            height:150px;
            -webkit-animation: bounce2 2s ease infinite alternate;
        }
        .gallery-parent {
            width: 55rem;
            margin: auto;
        }

        @keyframes bounce {
            0% {
                text-shadow: 0 5px 0 #ccc, 0 2px 3px rgba(0, 0, 0, 1);
            }

            100% {
                transform: translateY(-20px);
                text-shadow: 0 50px 0 black, 0 0px 20px rgba(0, 0, 0, 0.8);
            }
        }

        @keyframes bounce2 {
            100% {
                text-shadow: 0 5px 0 #ccc, 0 2px 3px rgba(0, 0, 0, 1);
            }

            0% {
                transform: translateY(-20px);
                text-shadow: 0 50px 0 black, 0 0px 20px rgba(0, 0, 0, 0.8);
            }
        }

        @media only screen and (max-width: 991px) {
            .gallery-child_left, .gallery-child_right {
                display: none;
            }
            .section-unicon_inerco{
            padding:50px 0 !important;
            }
        }
        `}
            </style>

        </>
    )
}

export default SectionTwo;