import React from "react";

const SectionThree = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row g-lg-5 g-3 align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 p-relative">
              <div
                className="bn-content-wrapper wow fadeInLeft"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h4 className="section-heading mb-4 ">Our Expertise</h4>
              </div>

              <div className="d-lg-block d-sm-block d-md-flex gap-5 gap-md-2 align-items-start">
                <div
                  className="choose-area-icon-box mb-0 mb-md-5 align-items-md-start align-items-center wow fadeInRight mt-lg-5 mt-0"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="icon-box p-relative">
                    <img
                      src="/images/icons/gear.webp"
                      alt=""
                      className="roof"
                      style={{ width: "32px" }}
                    />
                    <img
                      src="/images/icons/gear-white.webp"
                      alt=""
                      className="r-light"
                      style={{ width: "32px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Customized Solutions
                    </h6>
                    <p className="section-paragraph">
                      Each silencer is designed and manufactured to address
                      specific noise problems, ensuring optimal performance for
                      your unique requirements.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="choose-area-icon-box align-items-md-start align-items-center mt-0 mt-lg-5 wow fadeInRight"
                  data-wow-delay=".7s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="icon-box p-relative">
                    <img
                      src="/images/icons/security.webp"
                      alt=""
                      className="safe"
                      style={{ width: "32px" }}
                    />
                    <img
                      src="/images/icons/security-white.webp"
                      alt=""
                      className="s-light"
                      style={{ width: "32px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Guaranteed Performance
                    </h6>
                    <p className="section-paragraph">
                      We guarantee acoustic, structural, and fluid dynamic
                      performance, adhering to the highest quality standards.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="choose-area-icon-box align-items-md-start align-items-center mt-0 mt-lg-5 wow fadeInRight"
                  data-wow-delay=".7s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="icon-box p-relative">
                    <img
                      src="/images/icons/trophy.webp"
                      alt=""
                      className="safe"
                      style={{ width: "32px" }}
                    />
                    <img
                      src="/images/icons/trophy-white.webp"
                      alt=""
                      className="s-light"
                      style={{ width: "32px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Extensive Experience
                    </h6>
                    <p className="section-paragraph">
                      With over 30 years of experience and more than 1,000
                      silencers installed globally, our expertise in noise
                      reduction technology is unmatched.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 wow fadeInLeft"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image m-img text-center vertical-align-center mt-5">
                <img
                  src="/images/products/silencers/list-img.webp"
                  alt=""
                  className="img-fluid rounded-2"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <style>
        {`
                .choose-area-icon-box .icon-box {
                    background: #fff;
                    padding: 20px;
                    display: inline-block;
                    transition: all 1000ms ease;
                    border-radius: 4px;
                }
                .choose-area-icon-box {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    gap: 20px;
                }
                .choose-area-icon-box:hover .icon-box:before {
                    transform: scale(1, 1);
                }
                .choose-area-icon-box:hover .icon-box{
                    color: #fff;
                     background: #cc0017;
                }
                .s-light, .r-light{
                display:none;
                }
                .choose-area-icon-box:hover .roof, .choose-area-icon-box:hover .safe{
                   display:none;
                }
                .choose-area-icon-box:hover .r-light, .choose-area-icon-box:hover .s-light{
                   display:block;
                       position: relative;
                        z-index: 111;
                    }
                
                  @media(max-width:991px){
                     .choose-area-icon-box {
                        display: block;

                      } 
                      .choose-area-icon-box .icon-box {
                        margin-bottom:18px;
                      
                      }
                  }
                  @media(max-width:767px){
                     .choose-area-icon-box {
                        display: flex;

                      } 
                      .choose-area-icon-box .icon-box {
                        margin-bottom:0px;
                      
                      }
                  }
                @media(max-width:575px){
                
                    .choose-area-icon-box {
                        display: block;
                        
                    }
                    .choose-area-icon-box .icon-box {
                        padding:18px;
                        margin-bottom:12px;
                }
                }
                `}
      </style>
    </>
  );
};

export default SectionThree;
