import React, { useEffect } from 'react';
import { AfgcHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { AmmoniaAcc } from '../../../common/Components/AccordionSection';
import { AfgcCta } from '../../../common/Components/CtaSection';
import { AfgcQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const AmmoniaFluGas = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <AfgcHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <AmmoniaAcc/>
        <AfgcCta/>
        <AfgcQUickLinks/>
    </>
  )
}

export default AmmoniaFluGas;