import React from 'react';
import { LowNoxTable } from '../../../common/Components/TablesProduct';

const SectionFour = () => {
    return (
        <>
            <section class="product-specification ip-padding" id="prodSpecs">
                <div class="container">
                    <div class="spec-wrapper">
                        <div class="row g-lg-5 g-3">
                            <div class="col-xxl-6 col-xl-5 col-lg-4 col-md-12 wow fadeInLeft" data-wow-delay=".4s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                                <div class="bn-content-wrapper wow fadeInLeft" data-wow-delay=".5s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                                    <span class="section-sub-title  d-block">Low-NOx Burners</span>
                                    <h4 class="section-heading mb-4">Product Specification</h4>


                                </div>
                                <figure class="image m-img pt-0">
                                    <img src="/images/products/low-nox-burners/burner3.webp" alt="" className='img-fluid' style={{"borderRadius":"8px"}}/>
                                </figure>


                            </div>
                            <div class="col-xxl-6 col-xl-7 col-lg-8 col-md-12 wow fadeInRight" data-wow-delay=".4s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                                <div class="specc-wrapper ps-md-0 ps-lg-2">
                                    <div class="table-responsive">
                                      <LowNoxTable/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SectionFour;