import React from 'react';
import { Link } from 'react-router-dom';


const HeroSection = () => {

    

    return (
        <>
            <section className='banner-section-2 position-relative overflow-hidden'>
                <div className="video-background" style={{ position: 'relative', top: 0, left: 0, width: '100%', height: '750px', overflow: 'hidden', zIndex: -1 }}>
                    <video autoPlay muted loop style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                        <source src="/videos/factory-bg.webm" type="video/mp4" />
                    </video>
                </div >
                <div className="hero-content" style={{
                        position: "absolute",
                        top: "54%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        color: "white",
                        zIndex: "11"
                    }}>

                        <div data-wow-delay="0s" className="hero-pills justify-content-center mb-4 wow fadeInUpBig " style={{ animationDelay: '0s', animationName: 'fadeInUpBig', display: "flex", gap: "10px" }}>
                            <h6>Custom Design & Engineering</h6>
                            <h6>Inspection & Testing</h6>
                            <h6>Customer Support</h6>
                        </div>

                        <div
                            data-wow-delay="0s"
                            className="hero-title wow fadeInUpBig position-relative"
                            style={{ animationDelay: '0s', animationName: 'fadeInUpBig' }}
                        >
                            <h1 className="text-white">Pollution-Free Air Solutions</h1>
                        </div>

                        <p
                            className="text-white wow fadeInUpBig"
                            data-wow-delay="0s"
                            style={{ animationDelay: '0s', animationName: 'fadeInUpBig' }}
                        >
                            Advanced air pollution control solutions create a cleaner, healthier society by reducing pollutants and improving air quality.
                        </p>

                        <div
                            className="wow fadeInUpBig"
                            data-wow-delay="0s"
                            style={{ animationDelay: '0s', animationName: 'fadeInUpBig' }}
                        >
                            <Link to="/products" className="main-btn"> Explore Service & Products</Link>
                        </div>

                    </div>
            </section>

            <style>
                {`
                    .video-background::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0px;
                        left: 0px;
                        background-color: #00000099;
                    }
                    .hero-pills h6 {
                        padding: 5px 15px;
                        border-radius: 50px;
                        border: 1px solid #ffffff7a;
                        font-size: 14px;
                        color: #ffffffcf;
                        font-weight: 700 !important;
                    }
                    .hero-title h1 {
                        font-size: 68px;
                        padding-bottom: 30px;
                        margin-bottom: 40px;
                        font-weight: 800;
                    }
                    .hero-title:after {
                        content: '';
                        position: absolute;
                        width: 410px;
                        border-bottom: 2px solid rgb(201 201 201 / 67%);
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    .hero-content p {
                        font-size: 24px;
                        line-height: 1.5;
                        opacity: 0.9;
                        margin-top:40px;
                    } 
                        
                    
                    @media (max-width: 991px) {
                        .hero-content {
                            width: 610px;
                        }
                        .hero-title h1 {
                            font-size: 52px;
                        }
                        .hero-content p {
                            font-size: 20px;
                        }
                    }
                    @media (max-width: 575px) {
                        .hero-content {
                            width: 100%;
                            padding: 0px 26px;
                        }
                            .hero-pills {
                            flex-wrap: wrap;
                        }
                        .hero-pills h6 {
                        padding: 5px 10px;
                        }
                  
                    .hero-title h1 {
                        font-size: 36px;
                        padding-bottom: 32px;
                        margin-bottom: 26px;
                    }
                    .hero-title:after {
                        width: 250px;
                    }
                    .hero-content p {
                        font-size: 16px;
                    }
                 }
            `}
            </style>
        </>
    )
}

export default HeroSection;