import React from 'react';

const LowNoxCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding pt-0 wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Join the Green Revolution</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Contact Unicon International today to explore personalized solutions for cleaner combustion. Let’s create a greener future together!</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const EspCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Clear Skies Ahead</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Embark on the journey to superior air quality with Unicon ESPs. Our advanced technology ensures compliance and peace of mind.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const BagHouseCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding pt-0 wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Clear Skies Ahead</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Our advanced baghouse technology ensures compliance and a healthier environment. Trust Unicon for superior air quality.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const FgcsCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Breathe Easy with Unicon</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Embrace Unicon’s advanced pollution control solutions. Our cutting-edge technology guarantees compliance and a healthier environment for all.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const AfgcCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Ammonia Emission Control</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Explore UNICON's specialized Ammonia Flue Gas Conditioning systems designed to enhance particulate capture efficiency and ensure regulatory compliance.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const MistCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Step Into Superior Air Quality
                            </h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Elevate Air Quality with Unicon’s Mist Separator - Candle Filter. Our technology ensures optimal performance and equipment protection.

                            </p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const PscCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Ready to Optimize Your Material Handling?</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Discover how our Pneumatic Conveying Systems can revolutionize your operations. Contact us today to get a personalized consultation and see how we can tailor our solutions to meet your specific needs.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const RoatryCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Enhance Your Operations</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Discover how our advanced Rotary Airlock Valves can improve your operations. Get in touch with us for a personalized consultation.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const SlideGateCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Efficient Slide Gate Installation</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Slide Gate or Knife Edge Gate Valves for your factory or plant?</b> Our expert installation services are available worldwide, ensuring seamless integration and optimal performance.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const DoubleConeCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Enhance Your Efficiency</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Experience unparalleled performance and reliability in your industrial applications. Contact us today to learn more about our top-quality Double Cone Valves and how they can benefit your operations.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const DoubleFlapCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Maximize Performance</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Discover the reliability of UNICON's Double Flap Valves. Contact us today to optimize your industrial processes with our high-performance solutions.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const ClamShellCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Maximize Performance</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Discover the reliability of UNICON's Clam Shell Gate / Sector Gate System. Contact us today to optimize your industrial processes with our high-performance solutions.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const DomeCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Explore Solutions</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Discover UNICON's advanced Dome Valve solutions for reliable performance and durability. Contact us today to enhance your industrial operations with our proven expertise and support.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const DragChainCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Upgrade Your Material Handling</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Experience the benefits of our advanced Drag Chain Conveyors. Contact us today to optimize your bulk material transport with reliable and efficient solutions.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}



const BeltCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Enhance Your Industrial Workflow</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Discover the efficiency and versatility of our premium Belt Conveyor Systems. Contact us today to customize your conveyor solution for seamless material transport and optimized operations.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const ScrewCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Optimize Your Material Handling</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Explore UNICON's advanced Screw Conveyor solutions today to enhance your operational efficiency and streamline material handling processes. Contact us to discover how we can optimize your conveyor needs with reliability and precision.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}





const SilencersCta = () => {
    return (
        <div>
            <section
                className="inner-cta ip-padding pt-0 wow fadeInUpBig"
                data-wow-delay="0s"
                style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInUpBig",
                }}
            >
                <div className="container">
                    <div
                        className="cta-cover d-lg-flex justify-content-between align-items-center gap-5"
                        style={{
                            backgroundImage: `url("/images/products/burner-vector.webp")`,
                            backgroundPosition: "bottom right",
                            backgroundSize: "26%",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">
                                Premium Industrial Silencers
                            </h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start">
                                Discover our high-quality silencers designed to reduce noise and
                                improve operational efficiency. Ensure a quieter, safer, and
                                more productive environment with our reliable solutions.
                            </p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                Enquire Now<i className="bi bi-arrow-right ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const DefenceEquipmentsCta = () => {
    return (
        <div>
            <section
                className="inner-cta ip-padding  wow fadeInUpBig"
                data-wow-delay="0s"
                style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInUpBig",
                }}
            >
                <div className="container">
                    <div
                        className="cta-cover d-lg-flex justify-content-between align-items-center gap-5"
                        style={{
                            backgroundImage: `url("/images/products/burner-vector.webp")`,
                            backgroundPosition: "bottom right",
                            backgroundSize: "26%",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">
                                Get Started with UNICON
                            </h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start">
                                Unlock the potential of cutting-edge defence equipment tailored
                                to your needs. Contact us today to discuss how we can support
                                your mission with innovative, reliable solutions!
                            </p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                Enquire Now<i className="bi bi-arrow-right ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const StorageTankCta = () => {
    return (
        <div>
            <section
                className="inner-cta ip-padding  wow fadeInUpBig"
                data-wow-delay="0s"
                style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInUpBig",
                }}
            >
                <div className="container">
                    <div
                        className="cta-cover d-lg-flex justify-content-between align-items-center gap-5"
                        style={{
                            backgroundImage: `url("/images/products/burner-vector.webp")`,
                            backgroundPosition: "bottom right",
                            backgroundSize: "26%",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">
                                Elevate Your Storage Solutions
                            </h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start">
                                Contact UNICON today to discover how our expertly designed
                                storage tanks can meet your unique needs. Let’s work together to
                                ensure your operations run smoothly and efficiently!
                            </p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                Enquire Now<i className="bi bi-arrow-right ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const AgitatorsCta = () => {
    return (
        <div>
            <section
                className="inner-cta ip-padding  wow fadeInUpBig"
                data-wow-delay="0s"
                style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInUpBig",
                }}
            >
                <div className="container">
                    <div
                        className="cta-cover d-lg-flex justify-content-between align-items-center gap-5"
                        style={{
                            backgroundImage: `url("/images/products/burner-vector.webp")`,
                            backgroundPosition: "bottom right",
                            backgroundSize: "26%",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">
                                Elevate Your Mixing Solutions!
                            </h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start">
                                Contact UNICON today to explore our high-quality agitators
                                designed for optimal performance. Let us help you enhance
                                efficiency and quality in your operations!
                            </p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                Enquire Now<i className="bi bi-arrow-right ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};


const JacketedCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Custom Jacketed Vessel</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Contact UNICON to explore our durable and versatile jacketed vessels tailored to your specific needs. Let us help enhance your operations with our innovative solutions!</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}



const PressureCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Secure Your Pressure Vessel Today!</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Contact UNICON to learn more about our high-quality, approved pressure vessels designed for safety and efficiency. Let us provide the perfect solution for your project needs!</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const DecomposersCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Discover UNICON Decomposers Today!</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" >Unlock the potential of high-quality decomposers designed for efficiency and reliability. Contact us now to find the perfect solution for your needs!</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const CfdCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need CFD for your factory, industry, or plant?</b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const GdTestingCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need GD Testing for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const EmissionCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Emission Testing for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const SupervisoryCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Supervisory Service for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const ErectionCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Erection Services for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const RetrofitsCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Retrofits for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const UpgradesCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Upgrades for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}



const ResidualCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Residual Life Assessment for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}



const ShutdownCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Shutdown Maintenance for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const AnnualCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Annual Maintenance for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}



const EspSpareCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding pt-0 wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need ESP Spares for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const BaghouseSpareCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding pt-0  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Baghouse Spares for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


const CementSpareCta = () => {
    return (
        <div>
            <section className="inner-cta ip-padding pt-0  wow fadeInUpBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className="cta-cover d-lg-flex justify-content-between align-items-center gap-5" style={{
                        backgroundImage: `url("/images/products/burner-vector.webp")`,
                        backgroundPosition: 'bottom right',
                        backgroundSize: '26%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div>
                            <h4 className="section-heading text-white text-center text-lg-start">Unicon Services</h4>
                            <p className="section-paragraph mb-0  w-lg-75 w-md-100 mt-3 text-white text-center text-lg-start" ><b>Need Cement Plant Spares for your factory, industry or plant? </b> Our installation solutions extend globally, offering tailored services to optimize operations across the world.</p>
                        </div>
                        <div className="mt-lg-0 mt-4 flex-shrink-0 text-center">
                            <button type="submit" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export {
    LowNoxCta, EspCta, BagHouseCta, FgcsCta, AfgcCta, MistCta, PscCta, RoatryCta, SlideGateCta, DoubleConeCta, DoubleFlapCta, ClamShellCta, DomeCta, DragChainCta, BeltCta, ScrewCta,
    SilencersCta, DefenceEquipmentsCta, StorageTankCta, AgitatorsCta, JacketedCta, PressureCta, DecomposersCta, CfdCta, GdTestingCta, EmissionCta, SupervisoryCta, ErectionCta, RetrofitsCta, UpgradesCta, ResidualCta, ShutdownCta, AnnualCta, EspSpareCta, BaghouseSpareCta, CementSpareCta
};    