import React, { useEffect } from 'react';
import { RetrofitsHero } from '../../../common/Components/HeroSection';
import { RetrofitsSS } from '../../../common/Components/ServiceSecondSec';
import { RetrofitsScroll } from '../../../common/Components/ScrollSec';
import { RetrofitsCta } from '../../../common/Components/CtaSection';
import { RetrofitsQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Retrofit = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <RetrofitsHero/>
        <RetrofitsSS/>
        <RetrofitsScroll/>
        <RetrofitsCta/>
        <RetrofitsQUickLinks/>
    </>
  )
}

export default Retrofit;