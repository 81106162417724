import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';




const Industry = () => {
  return (
    <>
    <section
      id="sec2"
      data-wow-delay=".1s"
      style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUpBig' }}
      className="cust-padding2 project-slider-two-section section-space-bottom p-relative fix wow fadeInUpBig"
    >
      <div className="container">
        <h4 className="mb-4 section-heading text-center">Industries</h4>

        <Swiper
         spaceBetween={20}
         slidesPerView={1}
         loop={true}
         pagination={{ clickable: true }}
         autoplay={{
           delay: 3000, // 1 second delay between slides
           disableOnInteraction: false, // continue autoplay after user interactions
         }}
         modules={[Autoplay]} 
         breakpoints={{
            1440: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView:2,
            },
          }}
        >
          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/thermal-power.webp" alt="Thermal Power Plants" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Thermal Power Plants</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/sugar-industry.webp" alt="Sugar Plants" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Sugar Plants</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/steel-industry.webp" alt="Steel and Metal Industries" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Steel and Metal Industries</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/cement-industry.webp" alt="Cement Industry" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Cement Industry</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/chemical-industry.webp" alt="Chemical Industry" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Chemical Industry</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/paper-plants.webp" alt="Paper Plants" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Paper Plants</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/process-industry.webp" alt="Process Industry" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Process Industry</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/textile-industry.webp" alt="Textile Industry" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Textile Industry</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/pharma-industry.webp" alt="Pharma Industry" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Pharma Industry</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>


          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/sponge-iron-plants.webp" alt="Sponge Iron Plants & Mining Industry" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Sponge Iron Plants & Mining Industry</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-slider-two-box p-relative">
              <figure className="image w-img">
                <img src="/images/industries/palm-industry.webp" alt="Palm Industries" />
              </figure>
              <div className="content">
                <div className="inner-box">
                  <h5>Palm Industries</h5>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
    
    <style>
        {`
        .w-img{
        margin-bottom:0px;
        }
        .w-img img {
            width: 100%;
            height: 100%;
            object-fit: cover;
           
        }
        .project-slider-two-box:hover .content {
            opacity: 1;
            transform: scale(1, 1);
        }

        .project-slider-two-box .content {
            padding: 0px;
            background-color: transparent;
        }
        .project-slider-two-box .content {
            padding: 16px;
           color:#fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 0px;
            left: 14px;
            right: 30px;
            opacity: 1;
            transform: scale(1);
            transform-origin: left;
            transition: all 700ms ease;
        }
        .project-slider-two-box::before {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(16, 32, 57, 0.00) 30%, rgba(16, 32, 57, 0.80) 92.89%);
            transition: all 500ms ease;
        }

            .project-slider-two-box .inner-box h5 {
                color: #fff;
                font-size: 18px !important;
            }

            .project-slider-two-box .inner-box h5 {
                color: #fff;
            }
            .project-slider-two-box .inner-box h5 {
                margin-top: 5px;
            }
                                    
        `}
    </style>
    </>
  );
};

export default Industry;
