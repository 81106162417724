import React, { useEffect } from 'react';
import { ECHero } from '../../../common/Components/HeroSection';
import { ErectionSS } from '../../../common/Components/ServiceSecondSec';
import { ECScroll } from '../../../common/Components/ScrollSec';
import { ErectionCta } from '../../../common/Components/CtaSection';
import { ErectionQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Erection = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  
  return (
    <>
       
       <ECHero/>
       <ErectionSS/>
       <ECScroll/>
       <ErectionCta/>
       <ErectionQUickLinks/>
    </>
  )
}

export default Erection;