import React from 'react';

const DecadesSection = () => {
    return (
        <>
            <section className="cust-padding2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 wow fadeInLeftBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": "0s", "animationName": "fadeInLeftBig" }}>
                            <h4 className="section-heading">Decades of Excellence</h4>

                            <div className="mission-list">
                                <ul className='ps-0'>
                                    <li className="mb-sm-4 mb-3">
                                        <div className="d-flex gap-4">
                                            <div className="mission-sec flex-shrink-0">
                                                <img src="/images/mission.webp" alt="" style={{ width: '40px' }} />

                                            </div>
                                            <div>
                                                <h5 className="mb-2">Mission</h5>
                                                <p>Passionate commitment to provide cost- effective & world className manufacturing process
                                                    and Services.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="mb-sm-4 mb-3">
                                        <div className="d-flex gap-4">
                                            <div className="mission-sec flex-shrink-0">
                                                <img src="/images/vision.webp" alt="" style={{ width: '40px' }} />
                                            </div>
                                            <div>
                                                <h5 className="mb-2">Vision</h5>
                                                <p>To become the world-className Environmental protection industry by delivering Technology
                                                    and Services.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="mb-4">
                                        <div className="d-flex gap-4">
                                            <div className="mission-sec flex-shrink-0">
                                                <img src="/images/quality.webp" alt="" style={{ width: '40px' }} />

                                            </div>
                                            <div>
                                                <h5 className="mb-2">Quality Policy</h5>
                                                <p className="mb-0">To achieve enhanced customer satisfaction by providing quality products
                                                    and services, on time, each time, continually.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6 wow fadeInRightBig" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": "0s", "animationName": "fadeInLeftBig" }}>
                            <div className="position-relative">

                                <div className="exp-box" style={{ "textAlign": "left", "position": "absolute", "left": "50px", "bottom": "75px", "background": "#052d83", "padding": "50px 30px", "border": "5px solid #ffffff", "maxWidth": "260px" }}>
                                    <div className="">
                                        <h2 className='' >35+ Years</h2>
                                        <h5 className='text-white' >Delivering cutting-edge technology and exceptional services, ensuring innovative and
                                            reliable solutions for all your air pollution control needs.</h5>
                                    </div>
                                </div>

                                <div className="exp-img">
                                    <img src="/images/abt-img.webp" className="img-fluid w-100" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`
                 
                .mission-list {
                    margin-top: 45px;
                }
                .mission-list ul li p {
                      font-size: 20px;
                      font-weight:500;
                }
                .exp-img {
                    padding-left: 100px;
                } 
                .exp-box h5 {
                    color: #ffffff;
                    font-size: 17px !important;
                    line-height: 1.4 !important;
                    font-weight: 500 !important;
                } 
                .exp-box h2 {
                    color: #fff;
                    line-height: 1.2;
                    margin-bottom: 20px !important;
                }  
                @media (max-width: 767px) {
                    .exp-box h2 {
                        font-size: 24px !important;
                    }
                    .exp-box {
                            left: 30px!important;
                            bottom: 60px!important;
                            padding: 24px 24px!important;
                    }
                  
                    
                }

                @media (max-width: 575px) {
                    .exp-box h2 {
                        font-size: 22px !important;
                    }
                    .exp-box {
                         position: static !important;
                        padding: 26px 26px !important;
                        margin:0 auto !important;
                    }
                    .mission-list ul li p {
                        font-size: 16px;
                    }
                    .exp-img {
                        margin-top: 20px !important;
                        padding-left: 0px !important;
                    }
                }
                
                // @media (max-width: 450px) {
                //     .exp-box {
                //         left: 14px !important;
                //         bottom: auto !important;
                //         margin-bottom: 0px !important;
                //         z-index: 10;
                //         margin: 15px !important;
                //         border: none !important;
                //     }
                //     .exp-img {
                //         margin-top: -160px !important;
                //         padding-left: 0px !important;
                //     }
                // }
                                    
                    
                                `}
            </style>
        </>
    )
}

export default DecadesSection;