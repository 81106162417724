import React, { useEffect } from 'react';
import { RoatryCta } from '../../../common/Components/CtaSection';
import { RotaryQUickLinks } from '../../../common/Components/QuickLinksSection';
import { RotaryHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { RotaryAcc } from '../../../common/Components/AccordionSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Rotary = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <RotaryHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <RotaryAcc/>
        <RoatryCta/>
        <RotaryQUickLinks/>
    </>
  )
}

export default Rotary;