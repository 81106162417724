import React, { useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';


am4core.useTheme(am4themes_animated);

const MapGlobe = ({ baseUrl }) => {
    const chartRef = useRef(null);

    useEffect(() => {
      // Create map instance
      let chart = am4core.create('chartdiv', am4maps.MapChart);
      chartRef.current = chart;
  
      // Set map definition
      chart.geodata = am4geodata_worldLow;
  
      // Set projection
      chart.projection = new am4maps.projections.NaturalEarth1();
  
      // Create map polygon series
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.exclude = ['AQ'];
      polygonSeries.useGeodata = true;
  
      // Create image series
      let imageSeries = chart.series.push(new am4maps.MapImageSeries());
  
      // Create image
      let imageSeriesTemplate = imageSeries.mapImages.template;
      let marker = imageSeriesTemplate.createChild(am4core.Image);
      marker.href = `/images/dot.webp`;
      marker.width = 15;
      marker.height = 15;
      marker.nonScaling = true;
      marker.tooltipText = '{title}';
      marker.horizontalCenter = 'middle';
      marker.verticalCenter = 'bottom';
  
      // Set property fields
      imageSeriesTemplate.propertyFields.latitude = 'latitude';
      imageSeriesTemplate.propertyFields.longitude = 'longitude';
  
      // Add data for the locations
      imageSeries.data = [
        { latitude: 20.5937, longitude: 78.9629, title: 'India' },
        { latitude: 3.139, longitude: 101.6869, title: 'Malaysia' },
        { latitude: 15.8700, longitude: 100.9925, title: 'Thailand' },
        { latitude: 14.0583, longitude: 108.2772, title: 'Vietnam' },
        { latitude: 12.8797, longitude: 121.7740, title: 'Philippines' },
        { latitude: -0.7893, longitude: 113.9213, title: 'Indonesia' },
        { latitude: 33.2232, longitude: 43.6793, title: 'Iraq' },
        { latitude: 23.4241, longitude: 53.8478, title: 'UAE' },
        { latitude: 25.276987, longitude: 55.296249, title: 'Dubai' },
        { latitude: 7.8731, longitude: 80.7718, title: 'Sri Lanka' },
        { latitude: 37.0902, longitude: -95.7129, title: 'USA' },
        { latitude: 51.1657, longitude: 10.4515, title: 'Germany' },
        { latitude: 53.4129, longitude: -8.2439, title: 'Ireland' },
        { latitude: 4.5709, longitude: -74.2973, title: 'Colombia' },
        { latitude: 38.9637, longitude: 35.2433, title: 'Turkey' },
        { latitude: -35.6751, longitude: -71.5430, title: 'Chile' },
        { latitude: -1.2864, longitude: 36.8172, title: 'Kenya' },
        { latitude: 28.3949, longitude: 84.1240, title: 'Nepal' },
        { latitude: 26.8205, longitude: 30.8025, title: 'Egypt' },
        { latitude: -4.0383, longitude: 21.7587, title: 'Congo' },
        { latitude: 40.4637, longitude: -3.7492, title: 'Spain' },
        { latitude: 9.9285, longitude: -84.0907, title: 'Costa Rica' },
        { latitude: 9.9456, longitude: -9.6966, title: 'Guinea' },
        { latitude: 30.5852, longitude: 36.2384, title: 'Jordan' },
        { latitude: 41.8719, longitude: 12.5674, title: 'Italy' }
      ];
  
      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = '{name}';
      polygonTemplate.fill = am4core.color('#353860');
      polygonTemplate.stroke = am4core.color('#353860');
      polygonTemplate.propertyFields.fill = 'color';
      polygonTemplate.events.on('hit', function(ev) {
        ev.target.series.chart.zoomToMapObject(ev.target);
        chart.closeAllPopups();
        let data = ev.target.dataItem.dataContext;
        if (data.description) {
          chart.openPopup(
            `<img src=${data.img}>` +
            `<h3 style='margin-top:10px;margin-bottom:0px;'>${data.name}</h3>` +
            `</br>` +
            `<h4 style='margin:0px;'>${data.description}</h4>` +
            `</br>` +
            `<h5><a href='tel:${data.phone}'>${data.phone}</a></h5>`
          );
        }
      });
  
      chart.zoomControl = new am4maps.ZoomControl();
  
      // Cleanup on unmount
      return () => {
        chart.dispose();
      };
    }, [baseUrl]);
  
    return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
  };

export default MapGlobe;