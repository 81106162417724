import React, { useEffect } from 'react';
import { DomeHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { DomeAcc } from '../../../common/Components/AccordionSection';
import { DomeCta } from '../../../common/Components/CtaSection';
import { DomeQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Index = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
      
      <DomeHero/>
      <SectionTwoThree/>
      <SectionFour/>
      <DomeAcc/>
      <DomeCta/>
      <DomeQUickLinks/>
    </>
  )
}

export default Index;