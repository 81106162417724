import React from 'react';
import { Link } from 'react-router-dom';

const EspQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const BagHouseQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/pneumatic-conveying-system">Pneumatic Conveying System<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const FgcsQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/bag-house"> BagHouse<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/pneumatic-conveying-system">Pneumatic Conveying System<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const AfgcQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/bag-house"> Bag House or Bag Filter<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/pneumatic-conveying-system">Pneumatic Conveying System<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const MistQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/bag-house"> Bag House or Bag Filter<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/pneumatic-conveying-system">Pneumatic Conveying System<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const PcsQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor">Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/drag-chain-conveyor">Drag Chain Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const RotaryQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>                                
                            </div>
                        </div>
                        <div>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-flap-valve">Double flap valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/emission-testing">Emission Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor">Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/clam-shell-gate">Clam Shell Gate<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/dome-valve">Dome Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/drag-chain-conveyor">Drag Chain Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const SlideGateQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-flap-valve">Double flap valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/cfd">CFD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor">Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/clam-shell-gate">Clam Shell Gate<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/dome-valve">Dome Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/drag-chain-conveyor">Drag Chain Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const DoubleConeQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/rotary-airlock-valve">Rotary Airlock Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-flap-valve">Double flap valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor">Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/clam-shell-gate">Clam Shell Gate<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/dome-valve">Dome Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/drag-chain-conveyor">Drag Chain Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const DoubleFapQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/rotary-airlock-valve">Rotary Airlock Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor">Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/clam-shell-gate">Clam Shell Gate<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/dome-valve">Dome Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/drag-chain-conveyor">Drag Chain Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const ClamShellQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/rotary-airlock-valve">Rotary Airlock Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/dome-valve">Dome Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor">Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-flap-valve">Double flap valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/drag-chain-conveyor">Drag Chain Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const DomeQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/rotary-airlock-valve">Rotary Airlock Valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/clam-shell-gate">Clam Shell Gate<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor">Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-flap-valve">Double flap valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/drag-chain-conveyor">Drag Chain Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const DragChainQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                               
                            </div>
                        </div>
                        <div>
                            <Link to="/pneumatic-conveying-system">Pneumatic Conveying System<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/clam-shell-gate">Clam Shell Gate<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/belt-conveyor">Belt Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-flap-valve">Double flap valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-of-cement-plant">Spares for Cement Plants<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const BeltQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/pneumatic-conveying-system">Pneumatic Conveying System<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/clam-shell-gate">Clam Shell Gate<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor"> Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-flap-valve">Double flap valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-of-cement-plant">Spares for Cement Plants<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const ScrewQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/pneumatic-conveying-system">Pneumatic Conveying System<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/clam-shell-gate">Clam Shell Gate<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/belt-conveyor"> Belt Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-flap-valve">Double flap valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitator (ESP)<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-of-cement-plant">Spares for Cement Plants<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const CfdQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/emission-testing">Emission Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance"> Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/erection-commissioning">Erection and Commissioning<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const GdTestingQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/emission-testing">Emission Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance"> Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/erection-commissioning">Erection and Commissioning<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const EmissionQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits">Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance"> Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/erection-commissioning">Erection and Commissioning<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const SupervisoryQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance"> Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/erection-commissioning">Erection and Commissioning<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const ErectionQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance"> Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/bag-house">Bag Filter / Bag House<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const RetrofitsQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades">Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance"> Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/bag-house">Bag Filter / Bag House<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const UpgradesQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance"> Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/bag-house">Bag Filter / Bag House<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const ResidualQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades"> Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance"> Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/bag-house">Bag Filter / Bag House<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const ShutdownQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance">Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment"> Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/supervisory-services">Supervisory Services<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades"> Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const AnnualQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment"> Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/supervisory-services">Supervisory Services<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades"> Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const EspSparesQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance"> Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades"> Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/bag-house">Bag Filter / Bag House<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const BaghouseSparesQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                        </div>
                        <div>
                            <Link to="/bag-house">Bag Filter / Bag House<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/cfd">Computational Fluid Dynamics<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/retrofits"> Retrofits<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/annual-maintenance"> Annual Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/shut-down-maintenance">Shutdown Maintenance<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator">Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/residual-life-assessment">Residual Life Assessment<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades"> Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/afgc">Ammonia Flue Gas Conditioning<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const CementSparesQUickLinks = () => {
    return (
        <>
            <section id="quick-links" className="quick-links pt-0 ip-padding">
                <div className="container">
                    <div className="q-links-list_wrapper">
                        <div className="section-title mb-4 d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="section-heading mb-sm-0 mb-3">Quick Links</h4>
                            <div className="q-links-btn">
                                <Link to="/catalogues" className="btn btn-light custom-download_btn">Catalogues</Link>
                                <Link to="/questionnaire" className="btn btn-light custom-download_btn">Questionnaires</Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/pneumatic-conveying-system">Pneumatic Conveying System<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/double-cone-valve">Double cone valve<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/belt-conveyor">Belt Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/screw-conveyor"> Screw Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/electrostatic-precipitator"> Electrostatic Precipitators<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/drag-chain-conveyor">Drag Chain Conveyor<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/gd-testing">GD Testing<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/spares-for-esp">Spares for ESP<i className="bi bi-chevron-right ms-3"></i></Link>
                            <Link to="/upgrades"> Upgrades<i className="bi bi-chevron-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



export { EspQUickLinks, BagHouseQUickLinks, FgcsQUickLinks, AfgcQUickLinks, MistQUickLinks, PcsQUickLinks, RotaryQUickLinks, SlideGateQUickLinks, DoubleConeQUickLinks, DoubleFapQUickLinks, ClamShellQUickLinks, DomeQUickLinks, DragChainQUickLinks, BeltQUickLinks, ScrewQUickLinks, CfdQUickLinks, GdTestingQUickLinks, EmissionQUickLinks, SupervisoryQUickLinks, ErectionQUickLinks, RetrofitsQUickLinks, UpgradesQUickLinks, ResidualQUickLinks, ShutdownQUickLinks, AnnualQUickLinks, EspSparesQUickLinks, BaghouseSparesQUickLinks, CementSparesQUickLinks };