import React from 'react';

const Partners = () => {
    return (
        <div className="brand-section section-space-bottom cust-padding wow fadeInUpBig" data-wow-delay=".1s"  style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUpBig" }}>
            <div className="container">
                <div>
                    <h4 className="section-heading mb-md-4 mb-3 text-center">Reliable Partners</h4>
                </div>

                <div className="slider" style={{"background": "white", "height": "auto", "margin": "auto", "overflow": "hidden", "position": "relative"}}>
                    <div className="slide-track">
                        <div className="slide">
                            <img src="/images/partners/1.webp" alt="Partner 1" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/2.webp" alt="Partner 2" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/3.webp" alt="Partner 3" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/4.webp" alt="Partner 4" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/5.webp" alt="Partner 5" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/6.webp" alt="Partner 6" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/7.webp" alt="Partner 7" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/8.webp" alt="Partner 8" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/9.webp" alt="Partner 9" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/10.webp" alt="Partner 10" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/11.webp" alt="Partner 11" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/12.webp" alt="Partner 12" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/13.webp" alt="Partner 13" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/14.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/15.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/16.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/17.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/18.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/19.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/20.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/21.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/22.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/23.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/24.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/25.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/26.webp" alt="Partner 14" className='img-fluid'/>
                        </div>


                        {/* ---duplicate--- */}

                        
                        <div className="slide">
                            <img src="/images/partners/1.webp" alt="Partner 1" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/2.webp" alt="Partner 2" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/3.webp" alt="Partner 3" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/4.webp" alt="Partner 4" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/5.webp" alt="Partner 5" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/6.webp" alt="Partner 6" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/7.webp" alt="Partner 7" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/8.webp" alt="Partner 8" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/9.webp" alt="Partner 9" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/10.webp" alt="Partner 10" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/11.webp" alt="Partner 11" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/12.webp" alt="Partner 12" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/13.webp" alt="Partner 13" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/14.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/15.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/16.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/17.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/18.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/19.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/20.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/21.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/22.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/23.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/24.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/25.webp" alt="Partner 14" className='img-fluid'/>
                        </div>
                        <div className="slide">
                            <img src="/images/partners/26.webp" alt="Partner 14" className='img-fluid'/>
                        </div>



                       
                    </div>
                </div>
            </div>

            <style>
                {`
                .slider::before, .slider::after {
                    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
                    content: "";
                    height: 100%;
                    position: absolute;
                    width: 200px;
                    z-index: 2;
                }
                .slider::before {
                    left: 0;
                    top: 0;
                }
                .slider::after {
                    right: 0;
                    top: 0;
                    transform: rotateZ(180deg);
                }
                .slider .slide-track {
                    animation: scroll 360s linear infinite;
                    display: flex;
                    width: calc(100px * 100);
                    align-items: center;
                    gap: 75px;
                    justify-content: center;
                    height: 130px;
                }
                .slider .slide {
                    height: auto;
                    width: auto;
                    text-align: center;
                }
                                    
                @keyframes scroll {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(calc(-100px * 100));
                    }
                }
                
                  @media (max-width: 575px) {

                    .slider::before, .slider::after {
                        width: 50px !important;
                }
                }

                `}
            </style>
        </div>
    );
};

export default Partners;
