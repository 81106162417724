import React, { useRef, useState, useEffect } from 'react';

const VdoMain = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    videoRef.current.play(); // Play the video
    setIsPlaying(true); // Hide the button
  };

  const handlePauseVideo = () => {
    setIsPlaying(false); // Show the button when video is paused or ends
  };

  const handleVideoPlayPause = () => {
    if (videoRef.current.paused) {
      setIsPlaying(false); // Show the button if video is paused
    } else {
      setIsPlaying(true); // Hide the button if video is playing
    }
  };

  useEffect(() => {
    const video = videoRef.current;

    // Add event listeners for play, pause, and ended events
    video.addEventListener('play', handleVideoPlayPause);
    video.addEventListener('pause', handleVideoPlayPause);
    video.addEventListener('ended', handlePauseVideo);

    // Cleanup the event listeners when the component unmounts
    return () => {
      video.removeEventListener('play', handleVideoPlayPause);
      video.removeEventListener('pause', handleVideoPlayPause);
      video.removeEventListener('ended', handlePauseVideo);
    };
  }, []);

  const buttonStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70px',
    height: '70px',
    background: `url(/images/play.webp) center center / contain no-repeat`,
    cursor: 'pointer',
    zIndex: 10,
    display: isPlaying ? 'none' : 'block', // Hide the button when video is playing
  };

  return (
    <div
      className="video-container"
      style={{
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: '#fff',
        padding: '15px',
      }}
    >
      <video
        id="myVideo"
        className="video"
        controls
        poster="/images/thumb.webp"
        style={{ width: '100%', height: 'auto', display: 'block' }}
        ref={videoRef}
      >
        <source src="/videos/uni-vdo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div style={buttonStyle} onClick={handlePlayVideo}></div>
    </div>
  );
};

export { VdoMain };


const SolutionSection = () => {
  return (
    <>
      <section className="cust-padding half-container position-relative">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-7 wow fadeInLeftBig"
              data-wow-delay="0s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUpBig",
              }}
            >
              <VdoMain />
            </div>

            <div
              className="col-lg-5 wow fadeInRightBig z-11"
              data-wow-delay="0s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUpBig",
              }}
            >
              <div className="range-sec">
                <h2 className="section-heading text-lg-start text-center">
                  Complete range of solutions
                </h2>
                <ul className="solution-sec ps-0">
                  <li>
                    <h4 className="mb-sm-3 mb-4">
                      <i className="bi bi-check-lg"></i> Concept to
                      Commissioning
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-sm-3 mb-4">
                      <i className="bi bi-check-lg"></i> Design & Engineering
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-sm-3 mb-4">
                      <i className="bi bi-check-lg"></i> Product Portfolio
                    </h4>
                  </li>
                  <li>
                    <h4>
                      <i className="bi bi-check-lg"></i> Customer Service
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <style>
        {`
                .range-sec {
                    position: relative;
                    padding-left: 50px;
                }
                .range-sec ul li h4 {
                    color: #052d83;
                    font-weight: 600 !important;
                }
                .half-container:before {
                    content: '';
                    position: absolute;
                    width: 70%;
                    height: 100%;
                    right: 0px;
                    top: 0px;
                    background-color: #ededff;
                }
                .range-sec .section-heading {
                     margin-bottom: 30px !important;
                }
                @media (max-width: 991px) {
                    .half-container:before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 70%;
                        right: 0px;
                        top: 30%;
                        background-color: #ededff;
                    }
                    .video-container {
                        margin-bottom: 24px;
                    }
                    .solution-sec {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 0px 30px;
                        justify-content: center;
                    }
                    .solution-sec li h4 {
                        font-size: 20px !important;
                    }
                }
                @media (max-width: 575px) {
                    .range-sec {
                        padding-left: 0px !important;
                    }
                    .range-sec ul li h4 {
                        font-size: 16px !important;
                    }

                }
                @media (max-width: 450px) {
                    .solution-sec {
                        display: block !important;
                    }
                    .range-sec {
                        padding-left: 0px !important;
                    }
                    .range-sec .section-heading {
                        text-align: left !important;
                    }
                    .range-sec ul li h4 {
                        margin-bottom: 16px !important;
                    }
                }
                                
            `}
      </style>
    </>
  );
};

export default SolutionSection;
