import React, {useState}from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import ReactPlayer from 'react-player/youtube';


const VideoPopup = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <Link to="#" onClick={openModal} className="popup-video">
                <i className="bi bi-play-fill" style={{"color":"#fff","position":"relative","zIndex":"11111","fontSize":"30px"}}></i>
            </Link>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Video Modal"
                className="video-modal"
                overlayClassName="video-modal-overlay"
            >
                <button onClick={closeModal} className="close-modal">X</button>
                <ReactPlayer 
                    url="https://www.youtube.com/watch?v=C75C-cHYHZ0"
                    playing={modalIsOpen}
                    controls={true}
                    width="100%"
                    height="100%"
                />
            </Modal>
        </div>
    );
};

export  {VideoPopup};

const SectionTwo = () => {
    return (
        <>
            <section className="cust-padding">
                <div className="container">
                    <div className="row align-items-xl-center">
                        <div className="col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUpBig"}}>
                            <div className="prof-sec1 mb-lg-0 mb-5">
                                <div className="">
                                    <h4 className="section-heading mb-3">Explore Our Products</h4>
                                </div>

                                <p className="mb-0">Discover our comprehensive catalogue to find detailed information on our wide range of products, including specifications and applications. Additionally, visit our Questionnaire section to help us understand your specific needs and provide tailored solutions for your industry requirements.
                                </p>

                                <div className="d-flex flex-sm-no-wrap flex-wrap gap-3 mt-4">
                                    <Link to="/catalogues" className="primary-btn-1 bg-red outlined outlined btn-hover" >
                                        Catalogues  
                                    </Link>
                                    <Link to="/questionnaire" className="primary-btn-1 bg-red outlined outlined btn-hover" >
                                        Questionnaires 
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 wow fadeInRight" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUpBig"}}>

                            <div className="about-us-image-area position-relative wow fadeInRight" data-wow-delay=".5s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUpBig"}}>
                                <div className="image-2-area">
                                    <div className="image-2 position-relative" style={{"display":"inline-block","border":"10px solid #fff","boxShadow":"0px 4px 25px 0px rgba(0, 0, 0, 0.08)","borderRadius":"8px"}}>
                                        <img src="/images/products/products-list/video-thumb.webp" alt="" className='img-fluid'/>
                                            <div className="play-btn">
                                                <div className="video_player_btn">
                                                   <VideoPopup/>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`
                .video-modal {
                    position: relative;
                    width: 70%;
                    max-width: 800px;
                    height: 50%;
                    margin: auto;
                    background: #000;
                    padding: 20px;
                    outline: none;
                    border-radius: 10px;
                    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.2);
                    
                }

                .video-modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.75);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index:111;
                }

                .close-modal {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: #fff;
                    border: none;
                    padding: 5px 10px;
                    cursor: pointer;
                    border-radius: 5px;
                }
                .about-us-image-area .play-btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .video_player_btn {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 57px;
                        width: 57px;
                        border-radius: 50%;
                        transition: all 500ms ease;
                    }
                    .about-us-image-area .video_player_btn:before, .about-us-image-area .video_player_btn:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translateX(-50%) translateY(-50%);
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #cc0017;
                    opacity: 1;
                    border-radius: 50%;
                }

                .video_player_btn:before {
                    z-index: 0;
                    -webkit-animation: pulse-border 1500ms ease-out infinite;
                    animation: pulse-border 1500ms ease-out infinite;
                }
                @keyframes pulse-border{
                    0% {
                        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                        opacity: 1;
                    }
                    100% {
                        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                        opacity: 0;
                    }
                }
                 @media(max-width:767px){
                .video-modal {
                    width: 85%;
                    height: 40%;
                }
                }
                
                @media(max-width:575px){
                .video-modal {
                    width: 85%;
                    height: 60%;
                }
                
                }
                 @media(max-width:400px){
                .video-modal {
                    width: 90%;
                }
                
                }
                `}
            </style>

        </>
    )
}

export default SectionTwo;