import React, { useEffect } from 'react';
import { SMHero } from '../../../common/Components/HeroSection';
import { ShutdownSS } from '../../../common/Components/ServiceSecondSec';
import { SMScroll } from '../../../common/Components/ScrollSec';
import { ShutdownCta } from '../../../common/Components/CtaSection';
import { ShutdownQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Shutdown = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <SMHero/>
        <ShutdownSS/>
        <SMScroll/>
        <ShutdownCta/>
        <ShutdownQUickLinks/>
    </>
  )
}

export default Shutdown;