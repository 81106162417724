import React from 'react'
import ProductSelect from './ProductSelect';

const index = () => {
  return (
    <>
        <ProductSelect/>
    </>
  )
}

export default index;