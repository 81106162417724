import React from 'react';
import { ClamShellTable } from '../../../common/Components/TablesProduct';

const SectionFour = () => {
    return (
        <>
            <section className="product-specification  position-relative ip-padding" id="prodSpecs">
                <div className="container">
                    <div className="spec-wrapper">
                        <div className="row g-lg-4 g-3 align-items-center">
                            <div className="col-xxl-6  col-lg-5 col-md-12 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                                <div className="bn-content-wrapper wow fadeInLeft ps-xl-4 ps-0" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                                    <span className="section-sub-title  d-block">Clam Shell Gate / Sector Gate</span>
                                    <h4 className="section-heading mb-4">Product Specification</h4>
                                    <figure className="image m-img pt-0">
                                        <img src="/images/products/clam-shell-gate/d2.webp" alt="" className='img-fluid w-100 ' style={{ borderRadius: '8px', backgroundColor: '#f4f4f4' }} />
                                    </figure>
                                    <div className="mt-3">
                                        <small className="lh-base">* UNICON has been manufacturing clamshell gates for bulk material handling systems for more than decade. Our clam shell gates can be used any type of bulk material handling whether it is high temperature, adhesive or abrasive. We are providing Hardox 400, Hardox 200 or Hardox 500 lining for all material contact surfaces according to the application. Our state-of-the-art clam shell and testing facilities is the key of achieving optimal performance.</small>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-6 col-lg-7 col-md-12 ps-lg-5 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                                <div className="specc-wrapper">
                                    <div className="table-responsive">

                                        <ClamShellTable />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionFour;