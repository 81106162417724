import React, { useEffect } from 'react';
import { AgitatorsHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { AgitatorsAcc } from '../../../common/Components/AccordionSection';
import { AgitatorsCta } from '../../../common/Components/CtaSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";


const Agitators = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>  
        
        <AgitatorsHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <AgitatorsAcc/>
        <AgitatorsCta/>
    </>
  )
}

export default Agitators;