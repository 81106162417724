import React, { useEffect } from 'react';
import { MistHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import { MistCta } from '../../../common/Components/CtaSection';
import { MistQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const MistSeparator = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <MistHero/>
        <SectionTwoThree/>
        <MistCta/>
        <MistQUickLinks/>
    </>
  )
}

export default MistSeparator;