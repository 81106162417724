import React from "react";
import "./tabs-sec.css";

// Services Section Tabs Content

const ESPSpareTabs = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div class="row g-0 wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
            <div className="col-lg-3">
              <div
                class="nav flex-column service-tab-wrapper"
                id="service-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  class="nav-link active"
                  id="ce-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#ce-cnt"
                  role="tab"
                  aria-controls="ce-cnt"
                  aria-selected="true"
                >
                  Collecting Electrodes
                </a>

                <a
                  class="nav-link"
                  id="ede-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#ede-cnt"
                  role="tab"
                  aria-controls="ede-cnt"
                  aria-selected="false"
                >
                  Emitting / Discharge Electrodes
                </a>

                <a
                  class="nav-link"
                  id="hoa-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#hoa-cnt"
                  role="tab"
                  aria-controls="hoa-cnt"
                  aria-selected="false"
                >
                  Hammers / Outer Arm
                </a>

                <a
                  class="nav-link"
                  id="inner-arm-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#inner-arm-cnt"
                  role="tab"
                  aria-controls="inner-arm-cnt"
                  aria-selected="false"
                >
                  Inner Arm
                </a>

                <a
                  class="nav-link"
                  id="insulators-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#insulators-cnt"
                  role="tab"
                  aria-controls="insulators-cnt"
                  aria-selected="false"
                >
                  Insulators
                </a>

                <a
                  class="nav-link"
                  id="heaters-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#heaters-cnt"
                  role="tab"
                  aria-controls="heaters-cnt"
                  aria-selected="false"
                >
                  Heaters
                </a>

                <a
                  class="nav-link"
                  id="plain-b-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#plain-b-cnt"
                  role="tab"
                  aria-controls="plain-b-cnt"
                  aria-selected="false"
                >
                  Plain Bearing
                </a>

                <a
                  class="nav-link"
                  id="suspension-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#suspension-cnt"
                  role="tab"
                  aria-controls="suspension-cnt"
                  aria-selected="true"
                >
                  Suspension
                </a>
              </div>
            </div>
            <div className="col-lg-9">
              <div
                class="tab-content services-tab-details"
                id="services-tabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="ce-cnt"
                  role="tabpanel"
                  aria-labelledby="ce-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Collecting Electrodes</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/esp-spares/tabs-images/ce-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/ce-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/ce-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    With our Re-engineering background, we enjoy an enviable
                    strength when supply of spares is concerned. This further
                    enables us to meet short delivery requirements with ease.
                    Customer has been the focus of all our activities and
                    Customer Satisfaction has been the driving force for our
                    performance. UNICON has been Supplying ESP components for
                    the past 25 years. The components include Collecting
                    Electrodes, Discharge Electrodes/Emitting, Electrodes,
                    Hammers, Insulators, etc. Collecting Electrodes are
                    manufactured by cold roll forming and then finished by
                    carrying out various post forming operations. We are having
                    our own facilities for manufacturing of the collecting
                    electrodes. UNICON is supplying ZT-24, SIGMA TYPE, CSV TYPE
                    Collecting Electrodes regularly. Apart from the above, we
                    are having our own designed Collecting Electrodes.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="ede-cnt"
                  role="tabpanel"
                  aria-labelledby="ede-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Emitting / Discharge Electrodes</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/esp-spares/tabs-images/ede-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/ede-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    With our Re-engineering background, we enjoy an enviable
                    strength when supply of spares in concerned. This further
                    enables us to meet short delivery requirements with ease.
                    Customer has been the focus of all our activities and
                    Customer Satisfaction has been the driving force for our
                    performance. UNICON has been Supplying ESP components for
                    the past 20 years. The components include Collecting
                    Electrodes, Discharge Electrodes / Emitting Electrodes,
                    Hammers, Insulators, etc. Different type of Emitting
                    Electrodes are manufactured by using different formation
                    processes. UNICON can supply you with the material, style &
                    size to fit the requirements of your existing precipitation
                    system, or re-engineer to improve performance in both wire
                    and rigid electrode designs. Pipe and Spike.: Pipe & spike
                    discharge electrodes are formed from 2″ diameter tubing.
                    Each discharge electrode is attached to the high voltage
                    support frame by a single high strength bolted connection.
                    The individual discharge electrodes are bolted at the bottom
                    through a lower steadying frame. The entire assembly is
                    supported from the roof of the precipitator through a welded
                    high voltage structure. To facilitate ease of construction,
                    each electrode is allowed to hang freely from its support
                    channel and the entire assembly is then aligned to the
                    collecting surfaces prior to tightening the bolted
                    connection. The electrode is virtually maintenance free and
                    is resilient to operational upsets.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="hoa-cnt"
                  role="tabpanel"
                  aria-labelledby="hoa-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Hammers / Outer Arm</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/esp-spares/tabs-images/hoa-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/hoa-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/hoa-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly supplies Tumbling Hammers and Inner arms
                    for Collecting Rapping System, Emitting Rapping System and
                    GD Rapping System for all makes of Electrostatic
                    Precipitators. Tumbling Hammers are Fabricated or Forged as
                    per customer requirement. Material of Construction will be
                    selected based on application and customer preference like
                    Mild Steel, EN24, etc,.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="inner-arm-cnt"
                  role="tabpanel"
                  aria-labelledby="inner-arm-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Inner Arm</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/esp-spares/tabs-images/hoa-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/ia-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="">
                    UNICON Regularly supplies Inner Arm for Tumbling Hammers of
                    Collecting Rapping System, Emitting Rapping System and GD
                    Rapping System for all makes of Electrostatic Precipitators.
                  </p>
                  <p className="">
                    Inner Arms are Fabricated or Forged as per customer
                    requirement.
                  </p>
                  <p className="mb-0">
                    Material of Construction will be selected based on
                    application and customer preference like Mild Steel, EN24,
                    etc,.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="insulators-cnt"
                  role="tabpanel"
                  aria-labelledby="insulators-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Insulators</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/esp-spares/tabs-images/i-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/i-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/i-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/i-4.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/i-5.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly supplies the following Porcelain Insulators
                    for all makes of Electrostatic Precipitators. 1. Cylindrical
                    Support Insulators 2. Conical Support Insulators 3. Shaft
                    Insulators 4. Pin Insulators 5. Wall Through Bushing
                    Insulator 6. Bus Duct Insulators, etc,.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="heaters-cnt"
                  role="tabpanel"
                  aria-labelledby="heaters-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Heaters</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/esp-spares/tabs-images/h-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="">
                    UNICON Regularly supplies the following Heaters, RTDs, etc,
                    for all makes of Electrostatic Precipitators.
                  </p>
                  <ol>
                    <li>Hopper Heaters</li>
                    <li>Support Insulator Heaters</li>
                    <li>Shaft Insulator Heaters</li>
                  </ol>
                  <p>
                    All the heaters will be of tubular type with MOC of tube as
                    SS 316 or SS 304 according to the customer requirement.
                  </p>
                  <p className="mb-0">
                    Heater Junction Box will be of Fabricated type or Cast Iron
                    as per requirement.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="plain-b-cnt"
                  role="tabpanel"
                  aria-labelledby="plain-b-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Plain Bearing</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/esp-spares/tabs-images/pb-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly supplies Plain Bearings / Rapping Bearings
                    for all makes of Electrostatic Precipitators. Plain bearings
                    will be of Cast Iron as per required grade. The same will be
                    supplied with required fasteners as per the order.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="suspension-cnt"
                  role="tabpanel"
                  aria-labelledby="suspension-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Suspension</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/esp-spares/tabs-images/s-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/esp-spares/tabs-images/s-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly Designing, Manufacturing and Supplying
                    Collecting Suspension & Emitting Suspension in whole or part
                    for all make of ESPs. Normally both Collecting Suspension
                    and Emitting Suspensions are fabricated with Mild Steel.
                    Collecting Suspension is manufactured with Angles &
                    Collecting Hooks. Emitting Suspension is manufactured with
                    square tubes, rectangular tubes and Emitting Hook.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const BagHouseTabs = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div class="row g-0 wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
            <div className="col-lg-3">
              <div
                class="nav flex-column service-tab-wrapper"
                id="service-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  class="nav-link active"
                  id="fb-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#fb-cnt"
                  role="tab"
                  aria-controls="fb-cnt"
                  aria-selected="true"
                >
                  Filter Bags
                </a>

                <a
                  class="nav-link"
                  id="bc-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#bc-cnt"
                  role="tab"
                  aria-controls="bc-cnt"
                  aria-selected="false"
                >
                  Bag cages
                </a>

                <a
                  class="nav-link"
                  id="ven-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#ven-cnt"
                  role="tab"
                  aria-controls="ven-cnt"
                  aria-selected="false"
                >
                  Venturi
                </a>

                <a
                  class="nav-link"
                  id="sv-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#sv-cnt"
                  role="tab"
                  aria-controls="sv-cnt"
                  aria-selected="false"
                >
                  Solenoid Valves
                </a>

                <a
                  class="nav-link"
                  id="ts-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#ts-cnt"
                  role="tab"
                  aria-controls="ts-cnt"
                  aria-selected="false"
                >
                  Tube sheets
                </a>

                <a
                  class="nav-link"
                  id="pp-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pp-cnt"
                  role="tab"
                  aria-controls="pp-cnt"
                  aria-selected="false"
                >
                  Purging Pipes
                </a>

                <a
                  class="nav-link"
                  id="ej-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#ej-cnt"
                  role="tab"
                  aria-controls="ej-cnt"
                  aria-selected="false"
                >
                  Expansion joints
                </a>

                <a
                  class="nav-link"
                  id="duc-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#duc-cnt"
                  role="tab"
                  aria-controls="duc-cnt"
                  aria-selected="true"
                >
                  Ducting
                </a>
              </div>
            </div>
            <div className="col-lg-9">
              <div
                class="tab-content services-tab-details"
                id="services-tabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="fb-cnt"
                  role="tabpanel"
                  aria-labelledby="fb-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Filter Bags</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/baghouse-spares/tabs-images/fb-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/baghouse-spares/tabs-images/fb-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly supplies various type of Filter Bags for
                    Dust Collectors / Bag Filters/ Bag Houses in following
                    verities Fiberglass Fiberglass with PTFE membrane, PPS or
                    Ryton, PPS or Ryton with PTFE membrane, Aramid, Nomex,
                    Aramid with PTFE Membrane, Nomex with PTFE membrane, Acrylic
                    Homopolymer, Polypropylene, Polyester with PTFE membrane,
                    woven Polyester, and many more. All above with snap band
                    design & collar type design as per requirement. We also
                    supplies cartridge type and pleated type filter bags for
                    different applications.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="bc-cnt"
                  role="tabpanel"
                  aria-labelledby="bc-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Bag cages</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/baghouse-spares/tabs-images/bc-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/baghouse-spares/tabs-images/bc-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly supplies various type of Bag Cages for Dust
                    Collector / Bag Filters / Bag Houses which includes the
                    following types. With MOC Stainless Steel, Mild Steel and GI
                    Mild Steel with HR Aluminium Paint & Teflon Coating Single
                    Length & Split type.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="ven-cnt"
                  role="tabpanel"
                  aria-labelledby="ven-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Venturi</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/baghouse-spares/tabs-images/v-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly supplies various type of Venturies for Dust
                    Collector / Bag Filters / Bag Houses which includes the
                    following types. With MOC Aluminium & Mild Steel Spun Type
                    and Cast Aluminium Thickness as per customer requirement for
                    Cast Aluminium.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="sv-cnt"
                  role="tabpanel"
                  aria-labelledby="sv-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Solenoid Valves</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/baghouse-spares/tabs-images/sv-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/baghouse-spares/tabs-images/sv-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="">
                    UNICON Regularly supplies various type of Solenoid Valves
                    for Dust Collector / Bag Filters / Bag Houses which includes
                    the following types. Diaphragm type with 90 Deg Bend With
                    Aluminium body and Brass Body Voltage : 230 V AC type or 24
                    V DC type Range ½” to 3”
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="ts-cnt"
                  role="tabpanel"
                  aria-labelledby="ts-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Tube sheets</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/baghouse-spares/tabs-images/ts-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly Designing, Manufacturing and Supplying
                    various types of Tube Sheets for Dust Collectors / Bag
                    Filters / Bag Houses as below. Tube sheet with MOC Mild
                    Steel or Stainless Steel Tube sheet suitable for Snap Band
                    Type filter bags Tube sheet suitable for Collar Type Filter
                    Bags Tube sheet suitable for Cartridge Type filter bags.
                    Thickness upto 6 mm as per customer requirement. Tube sheet
                    with clean air chamber also will be supplied.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="pp-cnt"
                  role="tabpanel"
                  aria-labelledby="pp-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Purging Pipes</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/baghouse-spares/tabs-images/pp-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/baghouse-spares/tabs-images/pp-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="">
                    UNICON Regularly Designing, Manufacturing and Supplying
                    various types of Purging Pipes & Header Assembly for Dust
                    Collectors / Bag Filters / Bag Houses as below. Purging
                    Pipes with MOC Mild Steel or Stainless Steel Purging Pipes
                    with or without nozzles. Purging Pipe Size from ½” to 4”
                    Header Pipe with MOC Mild Steel Pipe size from 3” to 10”
                    Pipe Schedule : As per design / customer requirement.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="ej-cnt"
                  role="tabpanel"
                  aria-labelledby="ej-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Expansion joints</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/baghouse-spares/tabs-images/ej-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/baghouse-spares/tabs-images/ej-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly Designing, Manufacturing and Supplying
                    various types of Expansion Joints for Dust Collectors / Bag
                    Filters / Bag Houses / ESPs and other applications as below.
                    Expansion Joint – Metallic and Non-metallic MOC : Mild Steel
                    & Stainless Steel Fabric Material & Number of layers will be
                    selected based on the temperature and pressure. Types :
                    Round, Square and Rectangle Size : Round – upto 5.0 Meter
                    Diameter, Square – upto 5.0 x 5.0 Meter.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="duc-cnt"
                  role="tabpanel"
                  aria-labelledby="duc-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Ducting</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/baghouse-spares/tabs-images/dc-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/baghouse-spares/tabs-images/dc-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/baghouse-spares/tabs-images/dc-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON Regularly Designing, Manufacturing and Supplying
                    various types of Ducting & Chimney for Dust Collectors / Bag
                    Filters / Bag Houses / ESPs and other applications as below.
                    MOC : Mild Steel & Stainless Steel as per application and
                    requirement. Types : Round, Square and Rectangle Size :
                    Round – upto 5.0 Meter Diameter, Square – upto 5.0 x 5.0
                    Meter. Thermal Insulation : will be provided. Inside Lining
                    : will be provided.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const CementPlantTabs = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div class="row g-0 wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
            <div className="col-lg-3">
              <div
                class="nav flex-column service-tab-wrapper"
                id="service-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  class="nav-link active"
                  id="ks-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#ks-cnt"
                  role="tab"
                  aria-controls="ks-cnt"
                  aria-selected="true"
                >
                  Kiln Shells
                </a>

                <a
                  class="nav-link"
                  id="be-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#be-cnt"
                  role="tab"
                  aria-controls="be-cnt"
                  aria-selected="false"
                >
                  Bucket Elevator & Spares
                </a>

                <a
                  class="nav-link"
                  id="wl-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#wl-cnt"
                  role="tab"
                  aria-controls="wl-cnt"
                  aria-selected="false"
                >
                  Wearing Liner & Lifting Plates
                </a>

                <a
                  class="nav-link"
                  id="cf-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#cf-cnt"
                  role="tab"
                  aria-controls="cf-cnt"
                  aria-selected="false"
                >
                  Classifiers
                </a>

                <a
                  class="nav-link"
                  id="bca-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#bca-cnt"
                  role="tab"
                  aria-controls="bca-cnt"
                  aria-selected="false"
                >
                  Bucket Carriage
                </a>

                <a
                  class="nav-link"
                  id="cc-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#cc-cnt"
                  role="tab"
                  aria-controls="cc-cnt"
                  aria-selected="false"
                >
                  Clinker Cooler Spares
                </a>

                <a
                  class="nav-link"
                  id="ip-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#ip-cnt"
                  role="tab"
                  aria-controls="ip-cnt"
                  aria-selected="false"
                >
                  Immersion Pipe
                </a>

                <a
                  class="nav-link"
                  id="pg-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pg-cnt"
                  role="tab"
                  aria-controls="pg-cnt"
                  aria-selected="true"
                >
                  Pin Gates
                </a>
              </div>
            </div>
            <div className="col-lg-9">
              <div
                class="tab-content services-tab-details"
                id="services-tabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="ks-cnt"
                  role="tabpanel"
                  aria-labelledby="ks-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Kiln Shells</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/cm-spares/tabs-images/ks-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON with its state of art production centre in Coimbatore
                    has supplied Kiln Shells of various sizes to many cement
                    plants in INDIA. The Kiln shell thickness to a maximum of 40
                    mm thick can be manufactured and supplied by UNICON. We are
                    having expert welders to achieve the welding quality as per
                    standard requirements. Our welders are qualified as per IS
                    and ASME Standards.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="be-cnt"
                  role="tabpanel"
                  aria-labelledby="be-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Bucket Elevator</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/cm-spares/tabs-images/be-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/be-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON has supplied Bucket Elevators for various cement
                    plants in INDIA upto a maximum elevation of 40 Meters.
                    Bucket will be designed to suite the application and
                    requirement. It can be fabricated, forged or cast steel. MOC
                    of bucket can be Mild Steel, Stainless Steel, etc,. as per
                    application and requirement. We are supplying mostly Belt
                    Bucket Elevators. We are also supplies Chain Bucket
                    Elevators for special applications, where constraints.
                    UNICON Supplies all spares like Belt, Chain, Chain Links,
                    Drive & Driven Drums, Buckets, etc,. regularly for Bucket
                    Elevators.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="wl-cnt"
                  role="tabpanel"
                  aria-labelledby="wl-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Wearing Liner & Lifting Plates</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/cm-spares/tabs-images/wl-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/wl-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/wl-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/wl-4.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    UNICON regularly supplying cement mill balls, Liner Plates
                    and various other wearing plates which normally goes of over
                    a period time due to its normal wear and tear. We get the
                    right products customized to the customer needs and
                    specifications.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="cf-cnt"
                  role="tabpanel"
                  aria-labelledby="cf-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Classifiers</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/cm-spares/tabs-images/cf-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/cf-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="">
                    UNICON has supplied classifiers and its impeller for various
                    application of segregation of course particles in the
                    process of cement manufacturing. MOC of casing and impellers
                    will be designed based on the application and temperature.
                    Balancing will be carried out with the help of latest
                    technology.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="bca-cnt"
                  role="tabpanel"
                  aria-labelledby="bca-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Bucket Carriage</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/cm-spares/tabs-images/bca-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    In the process of Cement Manufacturing, bulk material
                    transfer is having a big role. The state of art technology
                    with UNICON helps their customers in achieving easy and
                    economic ways of material transfer from one place to another
                    place. UNICON has supplied various types of Bucket Carriages
                    for raw material transfer to various cement plants in INDIA.
                    MOC of bucket and special lining will be designed based on
                    the application and corrosive nature of raw material to be
                    transferred.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="cc-cnt"
                  role="tabpanel"
                  aria-labelledby="cc-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Clinker Cooler Spares</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/cm-spares/tabs-images/cc-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/cc-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/cc-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    Clinker cooler deals with High Temperature and corrosive
                    clinker. Sometimes, water spray also will be done to reduce
                    the temperature of the clinker in the cooler. This further
                    increases the corrosion of liners. UNICON has carried out
                    servicing of coolers of various cement plant, which includes
                    supply of spares and erection of the same. The cooler
                    grates, links, etc,. will be supplied to suite customer
                    requirements.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="ip-cnt"
                  role="tabpanel"
                  aria-labelledby="ip-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Immersion Pipe</h4>
                  <hr className="mb-4" />
                  <p className="">
                    UNICON is regularly supplying Immersion Pipes for cyclones
                    for Cement Industries. Immersion Pipes will be supplied in
                    segments as per the requirement.
                  </p>
                  <p>
                    We had supplied Immersion pipes upto a diameter of 5 Meter
                    for various stages.
                  </p>
                  <p className="mb-0">
                    MOC of Immersion pipes can be Mild Steel, Stainless Steel,
                    etc,. as per application and requirement.
                  </p>
                </div>

                <div
                  class="tab-pane fade"
                  id="pg-cnt"
                  role="tabpanel"
                  aria-labelledby="pg-tab"
                  tabindex="0"
                >
                  <h4 className="mb-4">Pin Gates</h4>
                  <hr className="mb-4" />
                  <div className="d-flex service-tab-images">
                    <img
                      src="/images/services/cm-spares/tabs-images/pg-1.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/pg-2.webp"
                      alt=""
                      className="img-fluid"
                    />
                    <img
                      src="/images/services/cm-spares/tabs-images/pg-3.webp"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="mb-0">
                    Pin gates are used in cement industry for regulating the
                    flow of Clinker, Slag, Gypsum from storage hoppers to the
                    feeding conveyors. Unicon is regularly supplying Pin Gates,
                    Manual Slide Gates, Pneumatic Slide Gates, Motorized Slide
                    Gates and Knife Gate valves for Cement Industries. MOC of
                    Gates can be Mild Steel or Stainless Steel as per
                    application and requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { ESPSpareTabs, BagHouseTabs, CementPlantTabs };
