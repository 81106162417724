import React, { useEffect } from 'react';
import { ScrewHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { ScrewAcc } from '../../../common/Components/AccordionSection';
import { ScrewCta } from '../../../common/Components/CtaSection';
import { ScrewQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Index = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <ScrewHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <ScrewAcc/>
        <ScrewCta/>
        <ScrewQUickLinks/>
    </>
  )
}

export default Index;