import React from "react";
import { Link } from "react-router-dom";

const SectionTwoThree = () => {
  return (
    <>
      <section className="complimentry-section-one ip-padding">
        <div className="container">
          <div className="row g-lg-4 g-3 align-items-center">
            <div
              className="col-md-6 wow fadeInLeft"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <div className="banner-gallery text-md-center">
                <img
                  style={{
                    boxShadow: "none",
                    borderRadius: "8px",
                    backgroundColor: "#f4f4f4",
                  }}
                  className="w-100 img-fluid"
                  src="/images/products/agitators/agi-main.webp"
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-md-6  wow fadeInRight"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInRight",
              }}
            >
              <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                <h4 className="section-heading  mb-2">
                Built for Performance
                </h4>
                <p className="section-paragraph">
                  <span className="text-primary-red">Unicon's</span> agitators
                  offer a simple design for ease of use and durability, ensuring
                  long-lasting performance across various industrial
                  applications.
                </p>
                <div className="d-flex gap-3  mt-4  ">
                  <ul className="icon-list style-two me-3 ps-0 mb-0">
                    <li>
                      <i className="bi bi-check-circle"></i>
                      User-Friendly Design
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Robust Construction
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Long-lasting Performance
                    </li>
                  </ul>
                  <ul className="icon-list style-two d-xl-block d-none ps-0">
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Versatile Applications
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Efficient Mixing
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Customizable Options
                    </li>
                  </ul>
                </div>

                <div className="mt-3">
                  <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="complimentry-section-two bg-light p-relative ip-padding">
        <div className="container">
          <div className="row g-lg-4 g-3 align-items-center">
            <div className="col-xl-6 col-md-6  p-relative">
              <div
                className="bn-content-wrapper wow fadeInRight"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInRight",
                }}
              >
                <h4 className="section-heading mb-2 pe-5 pe-md-0 w-md-75 w-100 mx-auto ">
                  {" "}
                  Why Choose UNICON
                </h4>
                <p className="section-paragraph">
                  <span className="text-primary-red">UNICON </span>
                  stands out for its commitment to delivering high-quality
                  agitators tailored to meet industry needs.
                </p>
                <div className="d-flex gap-3  mt-4 ">
                  <ul className="icon-list style-two me-3 ps-0 mb-0">
                    <li>
                      <i className="bi bi-check-circle"></i> Expertise You Can
                      Trust
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Exceptional Quality
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Cutting-Edge Innovations
                    </li>
                  </ul>
                  <ul className="icon-list style-two d-xl-block d-none ps-0">
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Robust Construction
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Tailored Solutions
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Comprehensive Support
                    </li>
                  </ul>
                </div>
                <div className="mt-4">
                  <a to="" className="bg-less-btn" data-bs-toggle="modal"
data-bs-target="#exampleModal">
                    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 wow fadeInLeft"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <div className="banner-gallery text-md-center mt-lg-0 mt-3">
                <img
                  style={{ borderRadius: "8px" }}
                  className="w-100 img-fluid"
                  src="/images/products/agitators/agi-main2.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <style>
        {`

                .ar, .ad{
                    font-size:30px;
                }
                    .adm{
                     font-size: 18px;
                      margin-top: -18px;
                    }
                .flow-diagram {
                    display: grid;
                    grid-template-columns: 1fr 50px 1fr 50px 1fr;
                    grid-template-rows: repeat(5, auto);
                    gap: 10px;
                    align-items: center;
                    text-align: center;
                }
                .box {
                    padding: 10px;
                    border-radius:8px;
                    font-weight: 600;
                    color: #1a1a1a;
                    border: 1px solid;
                    background-color: #fff;
                    position: relative;
                }
        
        @media only screen and (max-width: 991px) {
            .icon-list.style-three {
                display: flex;
            }
            ul.icon-list.style-three li {
                align-items: center;
                margin-bottom: 0;
                text-align: center;
            }
        }
        @media only screen and (max-width: 575px) {
            .flex-sm-d-wrap {
                flex-wrap: wrap;
            }
        }
        `}
      </style>
    </>
  );
};

export default SectionTwoThree;
