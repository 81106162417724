import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// ProductCard Component
const ProductCard = ({ id, imgSrc, title, selected, onSelect }) => {
  return (
    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
      <label htmlFor={`radio-card-${id}`} className="radio-card">
        <input
          type="radio"
          name="radio-card"
          id={`radio-card-${id}`}
          checked={selected}
          onChange={() => onSelect(id)}
        />
        <div className="card-content-wrapper h-100">
          <span className="check-icon"></span>
          <div className="card-content">
            <img
              src={imgSrc}
              alt={title}
              style={{
                marginBottom: "10px",
                width: "100%",
                borderRadius: "4px",
              }}
            />
            <h4
              style={{
                fontSize: "16px",
                letterSpacing: "-0.24px",
                textAlign: "center",
                color: "#1f2949",
              }}
            >
              {title}
            </h4>
          </div>
        </div>
      </label>
    </div>
  );
};

const products = [
  {
    id: 1,
    imgSrc: "/images/quote-prod/esp.webp",
    title: "Electrostatic Precipitator (ESP)",
  },
  { id: 2, imgSrc: "/images/quote-prod/bag-filter.webp", title: "Bag Filter" },
  {
    id: 3,
    imgSrc: "/images/quote-prod/ammonia-flue.webp",
    title: "Ammonia Flue Gas Conditioning",
  },
  {
    id: 4,
    imgSrc: "/images/quote-prod/pneumatic-conveying.webp",
    title: "Pneumatic Conveying Systems",
  },
  {
    id: 5,
    imgSrc: "/images/quote-prod/material-handling.webp",
    title: "Material Handling",
  },
  {
    id: 6,
    imgSrc: "/images/quote-prod/cfd.webp",
    title: "Computational Fluid Dynamics (CFD)",
  },
  { id: 7, imgSrc: "/images/quote-prod/gd-testing.webp", title: "GD Testing" },
  {
    id: 8,
    imgSrc: "/images/quote-prod/erection-commissioning.webp",
    title: "Erection & Commissioning",
  },
  { id: 9, imgSrc: "/images/quote-prod/retrofits.webp", title: "Retrofits" },
  {
    id: 10,
    imgSrc: "/images/quote-prod/rla.webp",
    title: "Residual Life Assessment",
  },
  {
    id: 11,
    imgSrc: "/images/quote-prod/spares-esp.webp",
    title: "Spares for ESP",
  },
];

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .test(
      "no-first-space",
      "First letter should not be a space",
      (value) => value && !/^ /.test(value)
    )
    .test(
      "only-one-space-between",
      "Only one space allowed",
      (value) => value && !/ {2,}/.test(value)
    )
    .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
    .min(3, "Name must be at least 3 characters Name must be at least 3 characters long")
    .max(50, "Name must be not more than 50 characters long"),
  designation: Yup.string()
    .required("Please enter designation & company name")
    .test(
      "no-first-space",
      "First letter should not be a space",
      (value) => value && !/^ /.test(value)
    )
    .test(
      "only-one-space-between",
      "Only one space allowed between words",
      (value) => value && !/ {2,}/.test(value)
    )
    .min(3, " Designation & company must be at least 3 characters long")
    .max(50, "Maximum 50 characters allowed"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]{10,15}$/, "Phone number is not valid")
    .test(
      "not-repetitive",
      "Please enter a valid number",
      (value) => !/(.)\1{9,14}/.test(value)
    ),
  email: Yup.string()
    .email("Please enter a valid email address")
    .test(
      "no-com.co",
      "Please enter a valid email address",
      (value) => !/\.com\.co$/.test(value)
    )
    .test(
      "no-single-letter-after-com",
      "Please enter a valid email address",
      (value) => !/\.com\.[a-zA-Z]$/.test(value)
    )
    .test(
      "no-spaces",
      "Please enter a valid email address",
      (value) => !/\s/.test(value)
    )
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address"
    )
    .test(
      "only-one-dot-com",
      "Please enter a valid email address",
      (value) => (value.match(/\.com/g) || []).length <= 1
    )
    .test(
      "no-repeated-domain",
      "Please enter a valid email address",
      (value) => {
        const domainExtensions = [".com", ".net", ".in", ".co"];
        const matches = value.match(/\.\w+/g);
        if (matches) {
          const uniqueExtensions = new Set(
            matches.map((match) => match.toLowerCase())
          );
          return uniqueExtensions.size === matches.length;
        }
        return true;
      }
    )
    .test(
      "laxEmail_new",
      "Please enter a valid email address.",
      function (value) {
        if (value) {
          if (
            value.indexOf("@") > 0 &&
            value.indexOf("@") === value.lastIndexOf("@")
          ) {
            const [localPart, domain] = value.split("@");
            const firstChar = localPart[0];
            const lastChar = localPart.charAt(localPart.length - 1);
            const afterAt = domain[0];

            if (
              firstChar === "." ||
              lastChar === "." ||
              /^[A-Za-z]$/.test(afterAt) === false
            ) {
              return false;
            }

            const domainParts = domain.split(".");
            if (domainParts.length < 2) {
              return false;
            }

            const topLevelDomain = domainParts.pop();
            if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
              return false;
            }

            return domainParts.every((part) => part.length > 1);
          } else {
            return false;
          }
        }
        return true;
      }
    )
    .required("Please enter your email address"),
  message: Yup.string()
    .test(
      "no-first-space",
      "First letter should not be a space",
      (value) => value && !/^ /.test(value)
    )
    .test(
      "only-one-space-between",
      "Only one space allowed between words",
      (value) => value && !/ {2,}/.test(value)
    )
    .min(3, "Message must be at least 3 characters")
    .max(500, "Message must be not more than 500 characters long"),
  file: Yup.array()
    .of(
      Yup.mixed().test("fileType", "Unsupported File Format", (value) => {
        if (!value) return true;
        const supportedFormats = [
          "application/pdf", // PDF
          "image/jpeg", // JPEG
          "image/jpg", // JPG
          "image/png", // PNG
          "application/msword", // Word (.doc)
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (.docx)
          "application/vnd.ms-excel", // Excel (.xls)
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (.xlsx)
          "application/vnd.oasis.opendocument.text", // OpenDocument Text (.odt)
          "application/vnd.oasis.opendocument.spreadsheet", // OpenDocument Spreadsheet (.ods)
        ];

        return supportedFormats.includes(value.type);
      })
    )
    .test("fileSize", "One or more files are too large", (files) => {
      if (!files || files.length === 0) return true; // Skip validation if no files are uploaded
      return files.every((file) => file.size <= 25 * 1024 * 1024);
    }),
});

const handleNameInput = (e) => {
  const value = e.target.value;
  const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
  e.target.value = filteredValue;
};

const handleKeyPress = (event) => {
  if (event.key === " ") {
    event.preventDefault();
  }
};

const ProductSelect = () => {
  const [selectedProductId, setSelectedProductId] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState("");
  const fileInputRef = useRef(null);
  const dragDropTextRef = useRef(null);
  const uploadIconRef = useRef(null);
  const progressBarRef = useRef(null);
  const navigate = useNavigate();

  const handleSelect = (id) => {
    setSelectedProductId(id);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      designation: "",
      phone: "",
      email: "",
      message: "",
      files: [], // Store multiple files
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("designation", values.designation);
      formData.append("phone", values.phone);
      formData.append("email", values.email);
      formData.append("message", values.message);

      // Append multiple files
      if (values.files.length > 0) {
        values.files.forEach((file) => {
          formData.append("files[]", file); // Use `files[]` as the name for the array of files
        });
      }

      const selectedProduct = products.find(
        (product) => product.id === selectedProductId
      );
      formData.append(
        "product_title",
        selectedProduct ? selectedProduct.title : ""
      );

      // Debug FormData content
      // for (let pair of formData.entries()) {
      //     console.log(`${pair[0]}: ${pair[1]}`);
      // }

      try {
        const response = await axios.post("backend/get_quotes.php", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        });
        const responseData = response.data;
        if (responseData === 1) {
          // Handle success
          //$("#getquoteform")[0].reset();
          setTimeout(() => {
            formik.resetForm();
            navigate("/thank-you");
           
          }, 10);
          setFileList([]);
          setProgress(0);
          if (dragDropTextRef.current) {
            dragDropTextRef.current.innerHTML = "Drag & drop any file here";
          }
          if (uploadIconRef.current) {
            uploadIconRef.current.innerHTML =
              '<i class="bi bi-cloud-arrow-up"></i>';
          }
        } else {
          console.error("Error submitting form data");
          alert(
            "There was an error submitting your request. Please try again later."
          );
        }
        setIsSubmitting(false);
      } catch (error) {
        console.error("Error:", error);
        alert(
          "There was an error submitting your request. Please try again later."
        );
      }
    },
  });

  const [fileList, setFileList] = useState([]); // Store all uploaded files

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    let validFiles = [];
    let error = "";

    // Check each file's size and filter valid files
    files.forEach((file) => {
      if (file.size > 25 * 1024 * 1024) {
        error = "One or more files exceed 25 MB";
      } else {
        validFiles.push(file);
      }
    });

    // Set error if any file is too large
    if (error) {
      setUploadError(error);
      return;
    }

    setUploadError("");
    setFileList((prevFiles) => {
      const updatedFiles = [...prevFiles, ...validFiles];
      formik.setFieldValue("files", updatedFiles); // Update Formik field with the new files
      return updatedFiles;
    });

    // Update visual feedback
    if (dragDropTextRef.current) {
      dragDropTextRef.current.innerHTML = "Files Selected Successfully!";
    }
    if (uploadIconRef.current) {
      uploadIconRef.current.innerHTML =
        '<i class="bi bi-file-earmark-check"></i>';
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files); // Convert FileList to Array
    let validFiles = [];
    let error = "";

    // Check each file's size and filter valid files
    files.forEach((file) => {
      if (file.size > 25 * 1024 * 1024) {
        error = "One or more files exceed 25 MB";
      } else {
        validFiles.push(file);
      }
    });

    // Set error if any file is too large
    if (error) {
      setUploadError(error);
      return;
    }

    setUploadError("");
    setFileList((prevFiles) => {
      const updatedFiles = [...prevFiles, ...validFiles];
      formik.setFieldValue("files", updatedFiles); // Update Formik field with the new files
      return updatedFiles;
    });

    // Update visual feedback
    if (dragDropTextRef.current) {
      dragDropTextRef.current.innerHTML = "Files Dropped Successfully!";
    }
    if (uploadIconRef.current) {
      uploadIconRef.current.innerHTML =
        '<i class="bi bi-file-earmark-check"></i>';
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = fileList.filter((_, i) => i !== index); // Remove specific file
    setFileList(updatedFiles);
    formik.setFieldValue("files", updatedFiles); // Update Formik field with the remaining files

    // Update visual feedback if all files are removed
    if (updatedFiles.length === 0) {
      if (dragDropTextRef.current) {
        dragDropTextRef.current.innerHTML = "Drag & drop any file here";
      }
      if (uploadIconRef.current) {
        uploadIconRef.current.innerHTML =
          '<i class="bi bi-cloud-arrow-up"></i>';
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (dragDropTextRef.current) {
      dragDropTextRef.current.innerHTML = "Drop your file here!";
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    if (dragDropTextRef.current) {
      dragDropTextRef.current.innerHTML = "Drag & drop any file here";
    }
  };

  return (
    <>
      <section className="wizard-section cust-padding3">
        <div className="container">
          <div className="row">
            <h4 className="section-heading text-center">Get a Quote</h4>

            <div className="mt-5">
              <div className="wow fadeInUpBig" data-wow-delay="0.1s">
                <h4 className="mb-3 form-guide">
                  1. Select your Product of Interest
                </h4>
                <div
                  className="multi-prod-card"
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "15px",
                    borderRadius: "0.75rem",
                    height: "100%",
                  }}
                >
                  <div className="scroll-sec">
                    <div className="row g-3">
                      {products.map((product) => (
                        <ProductCard
                          key={product.id}
                          id={product.id}
                          imgSrc={product.imgSrc}
                          title={product.title}
                          selected={selectedProductId === product.id}
                          onSelect={handleSelect}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="wow fadeInUpBig mt-5" data-wow-delay="0.4s">
                <h4 className="mb-3 form-guide">
                  2. Complete the form to request your quote
                </h4>
                <div
                  className="form-wrppr h-100"
                  style={{
                    padding: "25px",
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    border: "1px solid #dadada",
                  }}
                >
                  <form id="getquoteform" className="contact-form" onSubmit={formik.handleSubmit}>
                    <div className="row g-2">
                      <div className="col-lg-3">
                        <div className="form-group mb-2">
                          <label>
                            Name <span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onInput={handleNameInput}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="error-msg text-danger">
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-group mb-2">
                          <label>
                            Designation & Company Name  
                            <span className="text-red"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="designation"
                            placeholder="Enter Designation"
                            value={formik.values.designation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.designation &&
                          formik.errors.designation ? (
                            <div className="error-msg text-danger">
                              {formik.errors.designation}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-group mb-2">
                          <label>
                            Phone Number <span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            placeholder="Enter Phone Number"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="error-msg text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-group mb-2">
                          <label>
                            Email ID <span className="text-red">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter Email ID"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyDown={handleKeyPress}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="error-msg text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-2">
                          <label>
                            Message <span className="text-red">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            name="message"
                            rows="4"
                            placeholder="Enter Message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          ></textarea>
                          {formik.touched.message && formik.errors.message ? (
                            <div className="error-msg text-danger">
                              {formik.errors.message}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12">
                        <p
                          className="mb-0 upld-txt"
                          style={{ fontSize: "14px", fontWeight: "700" }}
                        >
                          If any file to be Upload (Max 25 MB)
                        </p>
                        <div>
                          <div
                            className="upload-files-container"
                            style={{
                              backgroundColor: "#fbfbfb",
                              width: "100%",
                              padding: "15px 30px",
                              borderRadius: "8px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginTop: "5px",
                              border: "1px solid #dadada",
                            }}
                          >
                            <div
                              className="drag-file-area"
                              style={{
                                border: "1px dashed #afafaf",
                                borderRadius: "8px",
                                margin: "10px 0 15px",
                                padding: "24px",
                                width: "100%",
                                textAlign: "center",
                                backgroundColor: "#fff",
                                position: "relative",
                                cursor: "pointer",
                              }}
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onDrop={handleDrop}
                              onClick={() =>
                                fileInputRef.current &&
                                fileInputRef.current.click()
                              }
                            >
                              <span
                                className="upload-icon"
                                ref={uploadIconRef}
                                style={{ fontSize: "30px" }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '<i class="bi bi-cloud-arrow-up"></i>',
                                }}
                              ></span>
                              <h3
                                className="dynamic-message"
                                ref={dragDropTextRef}
                              >
                                Drag & drop any file here
                              </h3>
                              <p className="mb-0">or</p>

                              <label
                                className="label d-block"
                                style={{ fontSize: "16px" }}
                              >
                                <span className="browse-files">
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{
                                      opacity: "0",
                                      position: "absolute",
                                      bottom: "0px",
                                      left: "0px",
                                    }}
                                    multiple // Allow multiple file selection
                                  />
                                  <span
                                    className="browse-files-text"
                                    style={{
                                      color: "#052d83",
                                      fontWeight: "bolder",
                                      cursor: "pointer",
                                    }}
                                  >
                                    browse file
                                  </span>
                                  <span> from device</span>
                                </span>
                              </label>
                            </div>

                            {fileList.length > 0 && (
                              <div
                                className="uploaded-files-details"
                                style={{ width: "100%", marginTop: "10px" }}
                              >
                                {fileList.map((file, index) => (
                                  <div
                                    key={index}
                                    className="uploaded-file-details"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      backgroundColor: "#e9f7ef",
                                      borderRadius: "5px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          margin: "0",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {file.name}
                                      </p>
                                      <p
                                        style={{
                                          margin: "0",
                                          fontSize: "12px",
                                          color: "#555",
                                        }}
                                      >
                                        {(file.size / 1024 / 1024).toFixed(2)}{" "}
                                        MB
                                      </p>
                                    </div>
                                    <button
                                      type="button"
                                      onClick={() => handleRemoveFile(index)}
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                        color: "#dc3545",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                      }}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}

                            {uploadError && (
                              <div className="error-msg text-danger">
                                {uploadError}
                              </div>
                            )}
                            {formik.touched.file && formik.errors.file ? (
                              <div className="error-msg text-danger">
                                {formik.errors.file}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="primary-btn-1 btn-hover mt-4"
                          disabled={isSubmitting}
                        >
                          Send Quote Request{" "}
                          <i className="icon-right-arrow"></i>
                         
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style>
        {`

                    .scroll-sec {
                        padding: 10px;
                    }
                    .multi-prod-card label {
                        display: flex;
                        width: 100%;
                        height: 100%;
                    }
                    label.radio-card .card-content h4 {
                        font-weight: 600 !important;
                    }
                    label.radio-card input[type=radio]:checked + .card-content-wrapper .check-icon {
                        background: #052d83;
                        border-color: #052d83;
                        transform: scale(1.2);
                        box-shadow: 0 0 0 .20rem rgba(13, 110, 253, .25);
                        transition: all 0.3s ease;
                    }
                    label.radio-card .check-icon {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        border: solid 2px #a5a5a5;
                        background: #fff;
                        border-radius: 50%;
                        transition: 200ms linear;
                        position: relative;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                    }
                    label.radio-card .card-content-wrapper {
                        background: #fff;
                        border-radius: 5px;
                        max-width: 100%;
                        min-height: 100% !important;
                        padding: 15px;
                        display: block;
                        box-shadow: 0 2px 4px 0 rgb(219 215 215 / 0%), 0 0 0 2px #e7e7e700;
                        transition: 200ms linear;
                        position: relative;
                        flex-grow: 1;
                        cursor: pointer;
                    }
                    label.radio-card input[type=radio]:checked + .card-content-wrapper {
                        box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #052d83;
                    }
                    label.radio-card input[type=radio]:checked + .card-content-wrapper .check-icon:before {
                        transform: scale(1);
                        opacity: 1;
                    }
                    label.radio-card .check-icon:before {
                        content: "";
                        position: absolute;
                        inset: 0;
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 12px;
                        background-position: center center;
                        transform: scale(1.6);
                        transition: 200ms linear;
                        opacity: 0;
                    }
                    label.radio-card input[type=radio] {
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }
                    .drag-file-area h3 {
                        font-size: 20px !important;
                        margin: 10px 0;
                    }
                    @media(max-width:1200px){
                        .scroll-sec {
                            max-height: 445px;
                            overflow-y: scroll;
                            overflow-x: hidden;
                        }
                    }
                    @media (max-width: 575px) {
                        .form-guide {
                            font-size: 18px;
                        }
                        .form-wrppr {
                            padding: 20px;
                        }
                    }
                `}
      </style>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"
      />
    </>
  );
};

export default ProductSelect;
