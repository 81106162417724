import React from 'react';
import { Link } from 'react-router-dom';
import SecondSection from '../../pages/Services/EspSpares/SecondSection';
import SecondSection1 from '../../pages/Services/BagHouseSpares/SecondSection';


const CfdSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 ">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ boxShadow: 'none', "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/cfd/comp-img.webp" alt="" />
                            </div>

                            <p className='mt-2'><small className="lh-base ctext-left">* Computational Fluid Dynamics (CFD) is pivotal in modern engineering, enabling detailed simulation and analysis of fluid flows and heat transfer in industrial systems, optimizing performance and problem-solving.</small></p>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Why CFD</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Reduce the shutdown time</li>
                                        <li><i className="bi bi-check-circle"></i> Accurate flow of gas</li>
                                        <li><i className="bi bi-check-circle"></i> Improve the performance of ESP</li>
                                        <li><i className="bi bi-check-circle"></i> Meet emission regulations with improved collection efficiency</li>
                                        <li><i className="bi bi-check-circle"></i> Lower operational costs through enhanced quality</li>
                                        <li><i className="bi bi-check-circle"></i> Cost Effectiveness</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const GdTestingSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/gd-testing/esp-gd.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Why GD Testing</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Improve the performance of ESP</li>
                                        <li><i className="bi bi-check-circle"></i>Meet the emission legislation by increasing equipment collection efficiency.</li>
                                        <li><i className="bi bi-check-circle"></i>Drive down the operational cost with improved quality.</li>
                                        <li><i className="bi bi-check-circle"></i>Cost Effectiveness.</li>
                                        <li><i className="bi bi-check-circle"></i>Separate shutdown is not required for implementation.</li>
                                        <li><i className="bi bi-check-circle"></i>Reduce the shutdown time.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="container">
                    <div className="d-flex ba-sec gap-5 align-items-center justify-content-center">
                        <div className="text-center wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <h5 className="mb-3">Before</h5>
                            <img src="/images/services/gd-testing/gd-main1.webp" className='img-fluid' alt="" />
                        </div>

                        <div className="anime-arrow wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <img src="/images/arrow-anime.gif" className='img-fluid' alt="" />
                        </div>

                        <div className="text-center wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <h5 className="mb-3">After</h5>
                            <img src="/images/services/gd-testing/gd-main2.webp" className='img-fluid' alt="" />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const EmissionSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/emission-testing/emission-main.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Overview</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Unicon is equipped to carryout emission level testing of any pollution control equipment like ESP, Bag Filter, Cyclones, etc,..</li>
                                        <li><i className="bi bi-check-circle"></i>This test program involves the simultaneous sampling of both the ESP inlet and ESP outlet conditions for particulate mass loading.</li>
                                        <li><i className="bi bi-check-circle"></i>The ESP will be tested under both normal baseline operating conditions with and without MCR.</li>
                                        <li><i className="bi bi-check-circle"></i>Four sets of paired inlet and outlet samples will be collected for both the baseline and MCR test conditions.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const SupervisorySS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/supervisory-service/ss.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Overview</h4>
                                <div className="mt-2 ">
                                    <p className='section-paragraph'>We have created our organization with a vision to provide world-class service to our clients in all areas where we are unquestionably strong. Our greatest strength lies in our highly skilled and committed work force. UNICON is fully equipped with spares and highly Skilled and experienced service department. UNICON assures fastest delivery and an immediate attention to our customer calls for any service requirement. We can offer our services with ESP’s, Bag Houses, Pneumatic Conveying systems, Wet scrubbers etc., for Thermal, Captive, Bagasse based power units, Steel industries, Sugar Factories, Cement industries, Paper Industries, etc,. We are regularly providing Supervisory services in the following fields.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const ErectionSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/erection-comissioning/ec.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Overview</h4>
                                <div className="mt-2 ">
                                    <p className='section-paragraph'>Unicon is fully equipped to carryout Erection, Trial Run and Commissioning of all type of Air Pollution Control Equipments. Our Strength is the availability of vast pool of expertise at various areas such as Design and Engineering, Manufacturing, Civil works, Erection and commissioning, Servicing, Up gradation and Augmentation of Air Pollution Control equipments such as Electrostatic Precipitator, Bag house, Cyclones, etc .</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const RetrofitsSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/retrofits/retrofit-main.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Why UNICON?</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Performance of Unicon Retrofits are very good.</li>
                                        <li><i className="bi bi-check-circle"></i>The cost advantage to the customer.</li>
                                        <li><i className="bi bi-check-circle"></i>Expertise to complete any critical job within the available shutdown time.</li>
                                        <li><i className="bi bi-check-circle"></i>Rich experience in Shut down maintenance services of all brands of ESP.</li>
                                        <li><i className="bi bi-check-circle"></i>Complete solution to the customer.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const UpgradesSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/upgrades/upgrades-main.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Need for Upgrades</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Improve the performance of ESP.</li>
                                        <li><i className="bi bi-check-circle"></i>Meet the emission legislation by increasing equipment collection efficiency.</li>
                                        <li><i className="bi bi-check-circle"></i>Drive down the operational cost with improved quality.</li>
                                        <li><i className="bi bi-check-circle"></i>Cost Effectiveness.</li>
                                        <li><i className="bi bi-check-circle"></i>Separate shutdown is not required for implementation.</li>
                                        <li><i className="bi bi-check-circle"></i>Reduce the shutdown time.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-one pt-0 ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper  pe-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Change in Inputs</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Change in fuel.</li>
                                        <li><i className="bi bi-check-circle"></i>Ash content is higher.</li>
                                        <li><i className="bi bi-check-circle"></i>Ash chemistry is changed.</li>
                                        <li><i className="bi bi-check-circle"></i>Calorific value is lower needing higher fuel consumption.</li>
                                        <li><i className="bi bi-check-circle"></i>Increased capacity of plant as a whole.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/upgrades/upgrades-main2.webp" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const ResidualSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/rla/rla-main.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Objective of RLA Study</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>To assess present condition of ESP & their auxiliaries.</li>
                                        <li><i className="bi bi-check-circle"></i>To identify reasons for deterioration of performance and suggest resolves thereof.</li>
                                        <li><i className="bi bi-check-circle"></i>To provide critical data input to plant and improve upon availability, reliability, efficiency & safety of the equipments.</li>
                                        <li><i className="bi bi-check-circle"></i>To furnish detailed project report (DPR) based on RLA study suggesting means & methods of improvement as per applicable standards and guidelines for the components under study.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const ShutdownSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/shutdown/shutdown-main.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">Overview</h4>
                                <div className="mt-2 ">
                                    <p className='section-paragraph'>Downtime of the plant and the down time cost are very critical. The proven method for reducing downtime is systematic and planned maintenance to avoid unexpected breakdowns. Planned maintenance involves preventive maintenance, condition monitoring etc. We undertake diagnostic study of the plant, design preventive maintenance system such as check points, periodicity of checking, and decide maintenance policies. System perpetually updated for efficiency improvement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const AnnualSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/annual-maintenance/annual-maintenance.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">About AMC</h4>
                                <div className="mt-2 ">
                                    <p className='section-paragraph'>Continuously operating Air pollution control equipments & Material Handling systems needs annual shut down for thorough checking and replacement of worn out and weak components and spare parts. Downtime cost of continuously operating Air pollution control equipment is quite high and hence annual shutdown maintenance time shall be bare minimum for any plant.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const EspSparesSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <SecondSection />
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">About ESP Spares</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> <div><b>Reduce the shutdown time</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>High performing and long lasting Spares for ESP with a lower cost modules.</p></div></li>
                                        <li><i className="bi bi-check-circle"></i> <div><b>Cost Effectiveness</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>Unicon is having vast experience in supply of spares for ESPs which reduces various cost elements and same will be transferred to client.</p></div></li>
                                        <li><i className="bi bi-check-circle"></i> <div><b>Spares for all make of ESPs</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>With our vast experience in Supply of Spares for ESP, we developed a system to supply spares for all make of ESPs.</p></div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const BHSparesSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <SecondSection1 />
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">About Baghouse Spares</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> <div><b>Engineered for longevity</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>High performing and long lasting Spares for Bag House with a lower cost modules.</p></div></li>
                                        <li><i className="bi bi-check-circle"></i> <div><b>Cost Effectiveness</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>Unicon is having vast experience in supply of spares for Houses which reduces various cost elements and same will be transferred to client.</p></div></li>
                                        <li><i className="bi bi-check-circle"></i> <div><b>Spares for all make of Bag Filters and Bag Houses</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>With our vast experience in Supply of Spares for Bag Houses, we developed a system to supply spares for all make of Bag Houses.</p></div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const CementPlantSS = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">
                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/services/cm-spares/cm-spares-main.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-md-4 ps-0 mt-lg-0 mt-2">
                                <h4 className="section-heading  mb-2">About Cement Plant Spares</h4>
                                <div className="mt-4 ">
                                    <ul className="icon-list style-two  ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> <div><b>Engineered for longevity</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>High performing and long lasting Spares for Cement Plants with a lower cost modules.</p></div></li>
                                        <li><i className="bi bi-check-circle"></i> <div><b>Cost Effectiveness</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>Unicon is having vast experience in supply of spares for Cement Plants which reduces various cost elements and same will be transferred to client.</p></div></li>
                                        <li><i className="bi bi-check-circle"></i> <div><b>Spares for all make of Cement Plant</b> <p className='section-paragraph' style={{ margin: '8px 0 0px', fontWeight: '500' }}>With our vast experience in Supply of Spares for cement plants, we developed a system to supply spares for cement plants.</p></div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export { CfdSS, GdTestingSS, EmissionSS, SupervisorySS, ErectionSS, RetrofitsSS, UpgradesSS, ResidualSS, ShutdownSS, AnnualSS, CementPlantSS, EspSparesSS, BHSparesSS };

<style>
    {`

        .ar, .ad{
             font-size:30px;
            }
         .adm{
             font-size: 18px;
             margin-top: -18px;
        }           
             
         .box {
            padding: 10px;
            border-radius:8px;
            font-weight: 600;
            color: #1a1a1a;
            border: 1px solid;
            background-color: #fff;
            position: relative;
        }
        
        @media only screen and (max-width: 991px) {
            .icon-list.style-three {
                display: flex;
            }
            ul.icon-list.style-three li {
                align-items: center;
                margin-bottom: 0;
                text-align: center;
            }
        }
        @media only screen and (max-width: 575px) {
            .flex-sm-d-wrap {
                flex-wrap: wrap;
            }
        }

        `}
</style>