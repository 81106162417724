import React, { useEffect } from 'react';
import { AMHero } from '../../../common/Components/HeroSection';
import { AnnualSS } from '../../../common/Components/ServiceSecondSec';
import { AMScroll } from '../../../common/Components/ScrollSec';
import { AnnualCta } from '../../../common/Components/CtaSection';
import { AnnualQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Annual = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
     

        <AMHero />
        <AnnualSS />
        <AMScroll />
        <AnnualCta />
        <AnnualQUickLinks />
     
    </>
  )
}

export default Annual;