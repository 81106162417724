import React, { useEffect } from 'react';
import { UpgradesHero } from '../../../common/Components/HeroSection';
import { UpgradesSS } from '../../../common/Components/ServiceSecondSec';
import { UpgradesScroll } from '../../../common/Components/ScrollSec';
import { UpgradesCta } from '../../../common/Components/CtaSection';
import { UpgradesQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const Upgraders = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        
        <UpgradesHero/>
        <UpgradesSS/>
        <UpgradesScroll/>
        <UpgradesCta/>
        <UpgradesQUickLinks/>
    </>
  )
}

export default Upgraders;