import React from 'react';
import { BagHouseTable } from '../../../common/Components/TablesProduct';


const SectionFive = () => {
    return (
        <>  
         
            <section className="product-specification bg-light position-relative ip-padding" id="prodSpecs">
                <div className="container">
                    <div className="spec-wrapper">
                        <div className="row g-lg-4 g-3 align-items-center">

                            <div className="col-xxl-6 col-lg-7 col-md-12 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                                <div className="specc-wrapper" style={{ "padding": "20px", "borderRadius": "0.75rem", "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", "background": "#fff" }}>
                                    <div className="table-responsive">

                                        <BagHouseTable />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6  col-lg-5 col-md-12 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                                <div className="bn-content-wrapper wow fadeInRight ps-xl-4 ps-0" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                                    <span className="section-sub-title  d-block">Bag House / Filter</span>
                                    <h4 className="section-heading mb-4">Product Specification</h4>
                                    <figure className="image m-img pt-0">
                                        <img src="/images/products/bag-house/cutout3.webp" alt="" className='img-fluid' style={{ borderRadius: '8px' }} />
                                    </figure>

                                    <div className="key-note lh-base mt-4" style={{ "backgroundColor": "#fff", "padding": "14px", "borderRadius": "8px", "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}><small className="lh-base">
                                        *Other Industry —  <br />Cement: Kiln, Mills, DBC, Dedusting of Conveyors;<br />
                                        Iron & Steel: Sintering, Pelletization, Sponge Iron Kiln, Cast House, Stock House, Dedusting of Conveyors;<br />
                                        Biomass fired boilers; <br />
                                        Waste to Energy plants;<br />
                                        Pulp & Paper: Recovery Boiler,
                                        Lime Kiln;<br />
                                        Non-Ferrous- Smelter, Converter; etc..,</small>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionFive;