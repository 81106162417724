import React from 'react';

const IntroSection = () => {
  return (
    <>
        <section className="inner-page-padding">
        <div className="container">
            <div className="career-head wow fadeInUp" data-wow-delay=".1s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUp", marginBottom:"40px"}}>
                <h6 className="mb-3 text-red text-center" style={{fontSize:'18px', fontWeight:'800'}}>Career</h6>
                <h4 className="section-heading text-center big-text">Unicon is renowned for it’s team & culture. We are looking <br className="d-lg-block d-none" />for some who fits the following criteria:</h4>
            </div>

            <hr className="cust-hr-line" />

            <div className="row g-0 align-items-center">

              
                <div className="col-md-6 wow fadeInLeft" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUp"}}>
                    <div className="pe-md-4 ps-0 c-btm-margin">
                        <div className="career-icon"><img src="/images/commitment_1.webp" alt="" /></div>
                        <h4 className="section-heading mb-3">Commitment</h4>
                        <p className="big-txt">Passionate commitment to provide the best work to enhance both company and you as a person.</p>
                    </div>
                </div>

                <div className="col-md-6 wow fadeInRight" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUp"}}>
                    <div className="text-center bg-border">
                        <img src="/images/commitment.webp" className="img-fluid w-100" alt=""/>
                    </div>
                </div>
            </div>

            <div className="row g-0 align-items-center">

              
                <div className="col-md-6 order-md-1 order-2 wow fadeInRight" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUp"}}>
                    <div className="text-center bg-border">
                        <img src="/images/communication.webp" className="img-fluid w-100" alt=""/>
                    </div>
                </div>

                <div className="col-md-6 order-md-2 order-1 wow fadeInLeft" data-wow-delay="0s"style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUp"}}>
                    <div className="ps-md-4 ps-0 ms-md-5 ms-0 c-btm-margin my-margin">
                        <div className="career-icon"><img src="/images/communication_1.webp" alt=""/></div>
                        <h4 className="section-heading mb-3">Communication</h4>
                        <p className="big-txt">Highly professional attitude, trust and communication is the most important factors inside our team.</p>
                    </div>
                </div>
            </div>

            <div className="row g-0 align-items-center">
               
                <div className="col-md-6 wow fadeInLeft" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUp"}}>
                    <div className="pe-md-4 pe-0 c-btm-margin my-margin">
                        <div className="career-icon"><img src="/images/consistency_1.webp" alt=""/></div>
                        <h4 className="section-heading mb-3">Consistency</h4>
                        <p className="big-txt">To achieve and produce high quality output throughout the year, we inhibit consistent effort throughout the year.</p>
                    </div>
                </div>

                <div className="col-md-6 wow fadeInRight" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUp"}}>
                    <div className="text-center bg-border">
                        <img src="/images/consistency.webp" className="img-fluid w-100" alt="" />
                    </div>
                </div>

            </div>

        </div>
    </section>

    <style>
        {`
        .cust-hr-line {
            margin-bottom: 50px !important;
        }
        .bg-border {
            padding: 20px;
            background: #ededfe;
        }
        .big-txt {
            font-size: 24px;
            margin-bottom: 0px;
        }
        .career-icon img {
            width: 70px;
        }
        @media (max-width: 991px) {
            .career-icon img {
                width: 40px;
            }
            .big-txt {
                font-size: 18px;
                margin-bottom: 0px;
            }
        }
        @media (max-width: 767px) {
         .c-btm-margin {
          margin-bottom: 22px;
            }
            .my-margin {
                margin-top: 3rem;
            }
        }
        `}
    </style>

    </>
  )
}

export default IntroSection;