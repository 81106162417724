import React from 'react';
import { AfgcTable } from '../../../common/Components/TablesProduct';

const SectionFour = () => {
    return (
        <>
            <section className="product-specification position-relative ip-padding" id="prodSpecs">
                <div className="container">
                    <div className="spec-wrapper">
                        <div className="row g-lg-4 g-3">
                        <div className="col-xxl-6  col-lg-5 col-md-12 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                                <div className="bn-content-wrapper wow fadeInLeft ps-xl-4 ps-0" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                                    <span className="section-sub-title  d-block">Ammonia Flue Gas Conditioning</span>
                                    <h4 className="section-heading mb-4">Results of Technology Demonstration</h4>
                                    <figure className="image m-img pt-0">
                                        <img src="/images/products/afgc/tab-img.webp" alt="" className='img-fluid' style={{ borderRadius: '8px' }} />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-lg-7 col-md-12 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                                <div className="specc-wrapper ps-md-0 ps-lg-5">
                                    <div className="table-responsive">
                                        <AfgcTable/>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionFour;