import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $, { data } from "jquery";
import axios from "axios";

const countries = [
  { id: 1, name: "USA", flag: "/images/flags/usa.webp" },
  { id: 2, name: "India", flag: "/images/flags/india.webp" },
  { id: 3, name: "UAE", flag: "/images/flags/uae.webp" },
  { id: 4, name: "Thailand", flag: "/images/flags/thailand.webp" },
  { id: 5, name: "Malaysia", flag: "/images/flags/malaysia.webp" },
  { id: 6, name: "Italy", flag: "/images/flags/italy.webp" },
  { id: 7, name: "Indonesia", flag: "/images/flags/indonesia.webp" },
  { id: 8, name: "Vietnam", flag: "/images/flags/vietnam.webp" },
  { id: 9, name: "Sri Lanka", flag: "/images/flags/sri_lanka.webp" },
  { id: 10, name: "Singapore", flag: "/images/flags/singapore.webp" },
  { id: 11, name: "Costa Rica", flag: "/images/flags/costa_rica.webp" },
  { id: 12, name: "Colombia", flag: "/images/flags/colombia.webp" },
  { id: 13, name: "Chile", flag: "/images/flags/chile.webp" },
  { id: 14, name: "Guinea", flag: "/images/flags/guinea.webp" },
  { id: 15, name: "Congo", flag: "/images/flags/congo.webp" },
  { id: 16, name: "Kenya", flag: "/images/flags/kenya.webp" },
  { id: 17, name: "Spain", flag: "/images/flags/spain.webp" },
  { id: 18, name: "Ireland", flag: "/images/flags/ireland.webp" },
  { id: 19, name: "Turkey", flag: "/images/flags/turkey.webp" },
  { id: 20, name: "Iraq", flag: "/images/flags/iraq.webp" },
  { id: 21, name: "Jordan", flag: "/images/flags/jordan.webp" },
  { id: 22, name: "Egypt", flag: "/images/flags/egypt.webp" },
  { id: 23, name: "Iran", flag: "/images/flags/iran.webp" },
  { id: 24, name: "Dubai", flag: "/images/flags/dubai.webp" },
  { id: 25, name: "Nepal", flag: "/images/flags/nepal.webp" },
];

const areaOfInterestOptions = [
  {
    id: 1,
    name: "Air Pollution Control Equipments",
    sub: [
      "Low NOx Burners",
      "Electrostatic Precipitators (ESP)",
      "Bag Filter / Bag House",
      "Flue Gas Conditioning System for Waste to Energy",
      "Ammonia Flue Gas Conditioning",
      "Mist Separators – Candle Filters",
    ],
  },
  {
    id: 2,
    name: "Material Handling",
    sub: [
      "Pneumatic Conveying System / Ash Handling System",
      "Rotary Airlock Valve",
      "Slide Gate",
      "Double Cone Valve",
      "Double Flap Valve",
      "Clam Shell Gate / Sector Gate",
      "Dome Valve",
      "Drag Chain Conveyor",
      "Belt Conveyor",
      "Screw Conveyor",
    ],
  },
  {
    id: 3,
    name: "Process Equipments",
    sub: [
      "Silencers",
      "Defence Equipments",
      "Storage Tank",
      "Agitators",
      "Decomposers",
      "Pressure Vessels",
      "Jacketed Vessels",
    ],
  },
  {
    id: 4,
    name: "Inspection & Testing",
    sub: [
      "Computational Fluid Dynamics (CFD)",
      "GD Testing(ESP)",
      "Emission Testing",
      "Supervisory Services",
    ],
  },
  {
    id: 5,
    name: "Projects Management",
    sub: [
      "Erection & Commissioning Services",
      "Retrofits",
      "Upgrades",
      "Residual Life Assessment",
      "Shut Down Maintenance",
      "Annual Maintenance",
    ],
  },
  {
    id: 6,
    name: "Plant Maintenance & Spares",
    sub: ["Spares for ESP", "Spares for Bag House", "Spares for Cement Plant"],
  },
];

const Connect = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [selectedAreaId, setSelectedAreaId] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubOption, setSelectedSubOption] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const validateField = (selector, validationFn, errorMessage) => {
      const value = $(selector).val();
      if (!validationFn(value)) {
        if (!$(selector).next(".error-msg").length) {
          $(selector).after(
            `<div class="error-msg text-danger">${errorMessage}</div>`
          );
        }
      } else {
        $(selector).next(".error-msg").remove();
      }
    };

    const validatePhone = (value) => {
      var pattern1 = /^\d{10}$/;
      var pattern2 = /^(?:\+\d{1,3}[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/;
      var pattern3 =
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

      return (
        pattern1.test(value) || pattern2.test(value) || pattern3.test(value)
      );
    };

    //const validateCompany = (value) => value.length >= 3 && value.length <= 50;

    const validateName = (value) => {
      // Check if the name is provided
      if (!value) {
        return "Name is required.";
      }

      // Check if the first character is not a space
      if (/^ /.test(value)) {
        return "First letter should not be a space.";
      }

      // Check if only one space is allowed between words
      if (/ {2,}/.test(value)) {
        return "Only one space allowed between words.";
      }

      // Check if the name contains only letters and spaces, and the length is within the range
      if (!/^[A-Za-z\s]*$/.test(value)) {
        return "Name must contain only letters and spaces.";
      }

      // Check if the name is between 3 and 50 characters long
      if (value.length < 3 || value.length > 50) {
        return "Name must be between 3 and 50 characters long.";
      }

      // If all validations pass, return null (no error)
      return null;
    };

    $("#connectusName").on("input", function () {
      const errorMsg = validateName($(this).val());

      // Clear previous error messages
      $(this).nextAll(".error-msg").remove();

      if (errorMsg) {
        // Display the error message
        $(this).after(`<div class="error-msg text-danger">${errorMsg}</div>`);
      }
    });

    const validateCompany = (value) => {
      if (!value) {
        return "Designation is required.";
      }

      if (/^ /.test(value)) {
        return "First letter should not be a space.";
      }

      if (/ {2,}/.test(value)) {
        return "Only one space allowed between words.";
      }

      if (value.length < 3 || value.length > 50) {
        return "Designation must be between 3 and 50 characters long.";
      }

      return null;
    };

    $("#connectusDesignation").on("input", function () {
      const errorMsg = validateCompany($(this).val());

      $(this).nextAll(".error-msg").remove();

      if (errorMsg) {
        $(this).after(`<div class="error-msg text-danger">${errorMsg}</div>`);
      }
    });

    const validateOrganisation = (value) => {
      if (!value) {
        return "Organisation is required.";
      }

      if (/^ /.test(value)) {
        return "First letter should not be a space.";
      }

      if (/ {2,}/.test(value)) {
        return "Only one space allowed between words.";
      }

      if (value.length < 3 || value.length > 50) {
        return "Organisation must be between 3 and 50 characters long.";
      }

      return null;
    };

    $("#connectusOranisation").on("input", function () {
      const errorMsg = validateOrganisation($(this).val());

      $(this).nextAll(".error-msg").remove();

      if (errorMsg) {
        $(this).after(`<div class="error-msg text-danger">${errorMsg}</div>`);
      }
    });

    $("#connectusEmail").on("input", () =>
      validateField(
        "#connectusEmail",
        validateEmail,
        "Please enter a valid email address."
      )
    );

    $("#connectusphone").on("input", () => {
      const phoneValue = $("#connectusphone").val();
      if (!validatePhone(phoneValue)) {
        if (!$("#con-phone-wrapper .error-msg").length) {
          $("#con-phone-wrapper").append(
            '<div class="error-msg text-danger">Please enter a valid phone number.</div>'
          );
        }
      } else {
        $("#con-phone-wrapper .error-msg").remove();
      }
    });
    $("#connectusphone").on("keydown", function (e) {
      if (
        $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (e.keyCode == 65 && e.ctrlKey === true) ||
        (e.keyCode == 67 && e.ctrlKey === true) ||
        (e.keyCode == 86 && e.ctrlKey === true) ||
        (e.keyCode == 88 && e.ctrlKey === true) ||
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        return;
      }

      // Allow numeric digits, '+', '(', and ')' keys only
      if (!/[\d()+]/.test(e.key)) {
        e.preventDefault();
      }
    });
    $("#connectusphone").on("keypress", function (event) {
      var phone = $(this).val();
      if (phone.length >= 15) {
        event.preventDefault();
      }
    });
  }, []);

  const handleValidation = () => {
    let isValid = true;
    $(".error-msg").remove(); // Clear existing error messages

    // Validate Name
    const name = $("#connectusName").val();
    if (!name) {
      isValid = false;
      $("#connectusName").after(
        '<div class="error-msg text-danger">Name is required.</div>'
      );
    } else if (
      !/^[A-Za-z\s]*$/.test(name) ||
      name.length < 3 ||
      name.length > 50
    ) {
      isValid = false;
      $("#connectusName").after(
        '<div class="error-msg text-danger">Name must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Email
    const email = $("#connectusEmail").val();
    if (!email) {
      isValid = false;
      $("#connectusEmail").after(
        '<div class="error-msg text-danger">Email is required.</div>'
      );
    } else if (!validateEmail(email)) {
      isValid = false;
      $("#connectusEmail").after(
        '<div class="error-msg text-danger">Please enter a valid email address.</div>'
      );
    }

    // Validate Phone
    const phone = $("#connectusphone").val();
    if (!phone) {
      isValid = false;
      $("#con-phone-wrapper").append(
        '<div class="error-msg text-danger mt-0">Phone number is required.</div>'
      );
    } else if (!validatePhone(phone)) {
      isValid = false;
      $("#con-phone-wrapper").append(
        '<div class="error-msg text-danger mt-0">Please enter a valid phone number.</div>'
      );
    }

    // Validate Designation
    const company = $("#connectusDesignation").val();
    if (!company) {
      isValid = false;
      $("#connectusDesignation").after(
        '<div class="error-msg text-danger">Designation is required.</div>'
      );
    } else if (company.length < 3 || company.length > 50) {
      isValid = false;
      $("#connectusDesignation").after(
        '<div class="error-msg text-danger">Designation must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Organisation
    const organisation = $("#connectusOranisation").val();
    if (!organisation) {
      isValid = false;
      $("#connectusOranisation").after(
        '<div class="error-msg text-danger">Organisation is required.</div>'
      );
    } else if (organisation.length < 3 || organisation.length > 50) {
      isValid = false;
      $("#connectusOranisation").after(
        '<div class="error-msg text-danger">Organisation must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Area of Interest and Sub-option
    const selectedAreaId = $("#connectareaOfInterest").val();
    const selectedSubOption = $("#connectussubField").val();
    //console.log(selectedAreaId)

    if (!selectedAreaId) {
      isValid = false;
      $("#connectareaOfInterest").after(
        '<div class="error-msg text-danger">Please select an area of interest.</div>'
      );
    } else if (!selectedSubOption) {
      isValid = false;
      $("#connectussubField").after(
        '<div class="error-msg text-danger">Please select a sub-option.</div>'
      );
    }

    return isValid;
  };

  const handleAreaChange = (e) => {
    const selectedId = e.target.value;
    const selectedArea = areaOfInterestOptions.find(
      (area) => area.id === parseInt(selectedId, 10)
    );

    setSelectedAreaId(selectedId);
    setSelectedAreaName(selectedArea ? selectedArea.name : "");
    setSubOptions(selectedArea ? selectedArea.sub : []);
    setSelectedSubOption("");

    $("#connectareaOfInterest").next(".error-msg").remove();
  };

  console.log(selectedAreaName);

  const handleSubOptionChange = (e) => {
    setSelectedSubOption(e.target.value);

    $("#connectussubField").next(".error-msg").remove();
  };

  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    e.target.value = filteredValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) return;

    setIsSubmitting(true);

    try {
      const response = await axios.post("backend/Contact_form.php", {
        name: $("#connectusName").val(),
        email: $("#connectusEmail").val(),
        phone: $("#connectusphone").val(),
        designation: $("#connectusDesignation").val(),
        organisation: $("#connectusOranisation").val(),
        message: $("#connectusmessage").val(),
        areaOfInterest: selectedAreaName,
        subField: selectedSubOption,
        mode: "addCenterModuleData_new",
      });

      const responseData = response.data;
      if (responseData === 1) {
        $("#connectusform")[0].reset();
        // Reset form fields

        setSelectedAreaId("");
        setSelectedAreaName("");
        setSubOptions([]);
        setSelectedSubOption("");

        navigate("/thank-you");
        window.location.reload();
      } else {
        console.error("Error submitting form data");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setIsSubmitting(false);
  };

  const validateEmail = (value) => {
    const domainExtensions = [".com", ".net", ".in", ".co"];
    const matches = value.match(/\.\w+/g);

    if (matches) {
      const uniqueExtensions = new Set(
        matches.map((match) => match.toLowerCase())
      );
      if (uniqueExtensions.size !== matches.length) {
        return false; // Reject if there are repeated domain extensions
      }
    }

    if (value.includes("..")) {
      return false; // Reject if there are consecutive dots
    }

    const [localPart, domainPart] = value.split("@");
    if (!localPart || !domainPart) {
      return false; // Reject if there is no local part or domain part
    }

    if (
      localPart.startsWith(".") ||
      localPart.endsWith(".") ||
      domainPart.startsWith(".")
    ) {
      return false; // Reject if local part or domain part starts or ends with a dot
    }

    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
  };

  const validatePhone = (value) => {
    return /^\+?[1-9]\d{1,14}$/.test(value);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // Instant scroll to the top
    });
  }, []);
  return (
    <>
      <section className="cust-padding3 bg-white" id="productslist">
        <div className="container">
          <div className="text-center">
            <h4 className="section-heading text-dark serv-prod-head">
              Get in touch with us
            </h4>
          </div>

          <div className="row">
            <div
              className="col-xxl-5 col-xl-5 col-lg-6 col-md-12 wow fadeInLeft "
              data-wow-delay=".5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInLeft",
              }}
            >
              <div className="card border-0">
                <div className="contact-box mb-3">
                  <i class="bi bi-geo-alt f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Location (Head Office)</p>
                    <p className="contact-info">
                      Bharathi Road,
                      <br />
                      Chinnavedampatty (PO), <br />
                      Coimbatore - 641 049, <br />
                      Tamil Nadu, India.
                    </p>
                  </div>
                </div>

                <div className="contact-box mb-3">
                  <i class="bi bi-phone f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Mobile</p>
                    <h4>
                      <a href="tel:+919487744417" className="contact-info">
                        +91 94877 44417
                      </a>
                    </h4>
                    <h4>
                      <a href="tel:+919442644463" className="contact-info">
                        +91 94426 44463
                      </a>
                    </h4>
                  </div>
                </div>

                <div className="contact-box mb-3">
                  <i class="bi bi-telephone f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Phone</p>
                    <h4>
                      <a href="tel:+914222362167" className="contact-info">
                        +91 422 2362167
                      </a>
                    </h4>
                    <h4>
                      <a href="tel:+914222362169" className="contact-info">
                        +91 422 2362169
                      </a>
                    </h4>
                    <h4>
                      <a href="tel:+914222362174" className="contact-info">
                        +91 422 2362174
                      </a>
                    </h4>
                  </div>
                </div>

                <div className="contact-box mb-3">
                  <i class="bi bi-printer f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Fax</p>
                    <a href="fax:+914222667309" className="contact-info">
                      +91 422 2667309
                    </a>
                  </div>
                </div>

                <div className="contact-box mb-3">
                  <i class="bi bi-envelope f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Email</p>
                    <h4 className="">
                      <a
                        href="mailto:marketing@uniconinter.com"
                        className="contact-info"
                      >
                        marketing@uniconinter.com
                      </a>
                    </h4>
                    <h4>
                      <a
                        href="mailto:info@uniconinter.com"
                        className="contact-info"
                      >
                        info@uniconinter.com
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-7 col-xl-7 col-lg-6 col-md-12 wow fadeInRight"
              data-wow-delay=".5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInRight",
              }}
            >
              <div className="card border-0">
                <div className="contact-form-area">
                  <div className="mb-2">
                    <h4 className="section-sub-title">LET’S TALK</h4>
                    <h4 className="mt-2">Contact Us</h4>
                  </div>
                  <div className="connect-form">
                    <form
                      id="connectusform"
                      className="contactform"
                      onSubmit={handleSubmit}
                    >
                      <div className="row g-4 p-3">
                        <div className="col-xl-6 col-lg-12  col-12">
                          <div className="form-group position-relative">
                            <label>
                              Name <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="connectusName"
                              name="name"
                              className="form-control"
                              placeholder="Enter your name"
                              onInput={handleNameInput}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12  col-12">
                          <div className="form-group position-relative">
                            <label>
                              Designation <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="connectusDesignation"
                              name="designation"
                              className="form-control"
                              placeholder="Enter Your Designation"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group position-relative">
                            <label>
                              Organisation <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="connectusOranisation"
                              name="organisation"
                              className="form-control"
                              placeholder="Enter Your Organisation"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-12">
                          <div className="form-group position-relative">
                            <label>
                              Email Address <span className="text-red">*</span>
                            </label>
                            <input
                              type="email"
                              id="connectusEmail"
                              name="email"
                              className="form-control"
                              placeholder="Enter Your Email Address"
                              onKeyDown={handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-12 position-relative">
                          <div className="form-group">
                            <label>
                              Phone Number <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="connectusphone"
                              name="phone"
                              className="form-control"
                              placeholder="Enter Your Phone Number"
                            />
                          </div>
                          <span id="con-phone-wrapper"></span>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group position-relative">
                            <label>
                              Area of Interest{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <select
                              name="area_of_Interest"
                              className="form-select border"
                              aria-label="Default select example"
                              onChange={handleAreaChange}
                              value={selectedAreaId}
                              id="connectareaOfInterest"
                            >
                              <option value="">Select</option>
                              {areaOfInterestOptions.map((interest) => (
                                <option key={interest.id} value={interest.id}>
                                  {interest.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {subOptions.length > 0 && (
                          <div className="col-lg-12 mt-0">
                            <div className="form-group position-relative">
                              <select
                                name="subField"
                                className="form-select mt-2 mb-1 border"
                                aria-label="Default select example"
                                onChange={handleSubOptionChange}
                                value={selectedSubOption}
                                id="connectussubField"
                              >
                                <option value="">Select</option>
                                {subOptions.map((subOption, index) => (
                                  <option key={index} value={subOption}>
                                    {subOption}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}

                        <div className="col-12">
                          <div className="form-group position-relative">
                            <label>
                              Message <small>(Optional)</small>
                            </label>
                            <textarea
                              id="connectusmessage"
                              name="message"
                              className="form-control"
                              placeholder="Write Message..."
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer  p-3 mt-2 mb-2">
                        <button
                          type="submit"
                          className="btn primary-btn-1"
                          style={{
                            color: "#fff",
                            fontWeight: "600",
                            fontSize: "18px",
                            textTransform: "none",
                            backgroundColor: "#052d83",
                            border: "1px solid #052d83",
                            padding: "8px 24px",
                            borderRadius: "8px",
                            whiteSpace: "normal",
                          }}
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12  ">
              <div
                className="country-list-section wow fadeInUp"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="country-list-title">
                  <p>
                    UNICON proudly maintains a global presence in over 25
                    countries
                  </p>
                </div>
                <div className="contry-list-wrapper d-flex">
                  {countries.map((country) => (
                    <div key={country.id} className="flag-pill">
                      <img src={country.flag} alt={country.name} />
                      <p className="country-name mb-0">{country.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <style>
        {`
            .error-msg {
              position: absolute;             
              font-size: 13px;
              line-height: 1;
            }
            .contact-box {
              padding: 20px;
              background: #F8F8F8;
              display: flex;
              justify-content: start;
              align-items: start;
              gap: 20px;
              border-radius: 0.75rem;
            }
            .f-50{
              font-size: 50px;
            }
            .text-dark-red{
              color: #cc0017;
            }
            .contact-info{
              margin-top: 8.4px;
              font-size: 18px;
              font-weight: 700;
              color: #1c1c1c !important;
              text-decoration: none;
            }
            .contact-form-area {
              padding: 44px 48px;
              background: #F8F8F8;
              border-radius: 0.75rem;
            }
            .contact-form-area input, .contact-form-area select, .contact-form-area textarea {
              height: auto;              
              border-radius: 0.5rem;
              border: 1px solid #cdcdcd;
              line-height: normal;
              padding: 12px;
              font-size: 16px;
            }
            .country-list-section{
              padding-top: 70px;
            }
            .country-list-title p{
              font-size: 24px;
              color: #101010;
              line-height: 1.5;
              margin-bottom: 1.5rem;
              text-align: center;
            }
            .contry-list-wrapper{
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              gap: .75rem;
            }
            .flag-pill{
              display: flex;
              column-gap: .3rem;
              align-items: center;
              padding: 5px 20px;
              background-color: #fff;
              border: 1px solid #d6d7f5;
              border-radius: 50px;
              text-align: left;
            }
            .flag-pill img {
              width: 24px;
            }
            .flag-pill p {
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 0px;
            }
              
            @media only screen and (max-width: 425px){
            .contact-form-area {
              padding: 20px;
            } 
            .contact-info {
              font-size: 14px;
            }
            }
        `}
      </style>
    </>
  );
};

export default Connect;
