import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import $, { data } from "jquery";
import axios from "axios";

const areaOfInterestOptions = [
  {
    id: 1,
    name: "Air Pollution Control Equipments",
    sub: [
      "Low NOx Burners",
      "Electrostatic Precipitators (ESP)",
      "Bag Filter / Bag House",
      "Flue Gas Conditioning System for Waste to Energy",
      "Ammonia Flue Gas Conditioning",
      "Mist Separators – Candle Filters",
    ],
  },
  {
    id: 2,
    name: "Material Handling",
    sub: [
      "Pneumatic Conveying System / Ash Handling System",
      "Rotary Airlock Valve",
      "Slide Gate",
      "Double Cone Valve",
      "Double Flap Valve",
      "Clam Shell Gate / Sector Gate",
      "Dome Valve",
      "Drag Chain Conveyor",
      "Belt Conveyor",
      "Screw Conveyor",
    ],
  },
  {
    id: 3,
    name: "Process Equipments",
    sub: [
      "Silencers",
      "Defence Equipments",
      "Storage Tank",
      "Agitators",
      "Decomposers",
      "Pressure Vessels",
      "Jacketed Vessels",
    ],
  },
  {
    id: 4,
    name: "Inspection & Testing",
    sub: [
      "Computational Fluid Dynamics (CFD)",
      "GD Testing(ESP)",
      "Emission Testing",
      "Supervisory Services",
    ],
  },
  {
    id: 5,
    name: "Projects Management",
    sub: [
      "Erection & Commissioning Services",
      "Retrofits",
      "Upgrades",
      "Residual Life Assessment",
      "Shut Down Maintenance",
      "Annual Maintenance",
    ],
  },
  {
    id: 6,
    name: "Plant Maintenance & Spares",
    sub: ["Spares for ESP", "Spares for Bag House", "Spares for Cement Plant"],
  },
];

const GetInTouch = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [selectedAreaId, setSelectedAreaId] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubOption, setSelectedSubOption] = useState("");

  const handleAreaChange = (e) => {
    const selectedId = e.target.value;
    const selectedArea = areaOfInterestOptions.find(
      (area) => area.id === parseInt(selectedId, 10)
    );

    setSelectedAreaId(selectedId);
    setSelectedAreaName(selectedArea ? selectedArea.name : "");
    setSubOptions(selectedArea ? selectedArea.sub : []);
    setSelectedSubOption("");

    $("#areaOfInterest").next(".error-msg").remove();
  };

  console.log(selectedAreaName);

  const handleSubOptionChange = (e) => {
    setSelectedSubOption(e.target.value);

    $("#subField").next(".error-msg").remove();
  };

  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    e.target.value = filteredValue;
  };

  const handleKeypress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const validateEmail = (value) => {
    const domainExtensions = [".com", ".net", ".in", ".co"];
    const matches = value.match(/\.\w+/g);

    if (matches) {
      const uniqueExtensions = new Set(
        matches.map((match) => match.toLowerCase())
      );
      if (uniqueExtensions.size !== matches.length) {
        return false; // Reject if there are repeated domain extensions
      }
    }

    if (value.includes("..")) {
      return false; // Reject if there are consecutive dots
    }

    const [localPart, domainPart] = value.split("@");
    if (!localPart || !domainPart) {
      return false; // Reject if there is no local part or domain part
    }

    if (
      localPart.startsWith(".") ||
      localPart.endsWith(".") ||
      domainPart.startsWith(".")
    ) {
      return false; // Reject if the local part starts or ends with a dot or if the domain part starts with a dot
    }

    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
  };

  const validatePhone = (value) => {
    var pattern1 = /^\d{10}$/;
    var pattern2 = /^(?:\+\d{1,3}[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/;
    var pattern3 =
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

    return pattern1.test(value) || pattern2.test(value) || pattern3.test(value);
  };

  useEffect(() => {
    const validateField = (selector, validationFn, errorMessage) => {
      const value = $(selector).val();
      if (!validationFn(value)) {
        if (!$(selector).next(".error-msg").length) {
          $(selector).after(
            `<div class="error-msg text-danger">${errorMessage}</div>`
          );
        }
      } else {
        $(selector).next(".error-msg").remove();
      }
    };

    //const validateName = (value) =>/^[A-Za-z\s]*$/.test(value) && value.length >= 3 && value.length <= 50;
    const validateName = (value) => {
      // Check if the name is provided
      if (!value) {
        return "Name is required.";
      }
    
      // Check if the first character is not a space
      if (/^ /.test(value)) {
        return "First letter should not be a space.";
      }
    
      // Check if only one space is allowed between words
      if (/ {2,}/.test(value)) {
        return "Only one space allowed between words.";
      }
    
      // Check if the name contains only letters and spaces, and the length is within the range
      if (!/^[A-Za-z\s]*$/.test(value)) {
        return "Name must contain only letters and spaces.";
      }
    
      // Check if the name is between 3 and 50 characters long
      if (value.length < 3 || value.length > 50) {
        return "Name must be between 3 and 50 characters long.";
      }
    
      // If all validations pass, return null (no error)
      return null;
    };
    
    $("#name").on("input", function() {
      const errorMsg = validateName($(this).val());
    
      // Clear previous error messages
      $(this).nextAll('.error-msg').remove();
    
      if (errorMsg) {
        // Display the error message
        $(this).after(`<div class="error-msg text-danger">${errorMsg}</div>`);
      }
    });

   // const validateCompany = (value) => value.length >= 3 && value.length <= 50;
    const validateMessage = (value) => value.length >= 3 && value.length <= 500;


    $("#email").on("input", () =>
      validateField(
        "#email",
        validateEmail,
        "Please enter a valid email address."
      )
    );
    // $("#designation").on("input", () =>
    //   validateField(
    //     "#designation",
    //     validateCompany,
    //     "Designation must be between 3 and 50 characters long."
    //   )
    // );
    // $("#organisation").on("input", () =>
    //   validateField(
    //     "#organisation",
    //     validateCompany,
    //     "Organisation must be between 3 and 50 characters long."
    //   )
    // );

    const validateCompany = (value) => {
      if (!value) {
        return "Designation is required.";
      }

      if (/^ /.test(value)) {
        return "First letter should not be a space.";
      }

      if (/ {2,}/.test(value)) {
        return "Only one space allowed between words.";
      }

      if (value.length < 3 || value.length > 50) {
        return "Designation must be between 3 and 50 characters long.";
      }

      return null;
    };

    $("#designation").on("input", function () {
      const errorMsg = validateCompany($(this).val());

      $(this).nextAll(".error-msg").remove();

      if (errorMsg) {
        $(this).after(`<div class="error-msg text-danger">${errorMsg}</div>`);
      }
    });

    const validateOrganisation = (value) => {
      if (!value) {
        return "Organisation is required.";
      }

      if (/^ /.test(value)) {
        return "First letter should not be a space.";
      }

      if (/ {2,}/.test(value)) {
        return "Only one space allowed between words.";
      }

      if (value.length < 3 || value.length > 50) {
        return "Organisation must be between 3 and 50 characters long.";
      }

      return null;
    };

    $("#organisation").on("input", function () {
      const errorMsg = validateOrganisation($(this).val());

      $(this).nextAll(".error-msg").remove();

      if (errorMsg) {
        $(this).after(`<div class="error-msg text-danger">${errorMsg}</div>`);
      }
    });
    $("#phone").on("input", () => {
      const phoneValue = $("#phone").val();
      if (!validatePhone(phoneValue)) {
        if (!$("#phone-wrapper .error-msg").length) {
          $("#phone-wrapper").append(
            '<div class="error-msg text-danger">Please enter a valid phone number.</div>'
          );
        }
      } else {
        $("#phone-wrapper .error-msg").remove();
      }
    });
    $("#phone").on("keydown", function (e) {
      if (
        $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (e.keyCode == 65 && e.ctrlKey === true) ||
        (e.keyCode == 67 && e.ctrlKey === true) ||
        (e.keyCode == 86 && e.ctrlKey === true) ||
        (e.keyCode == 88 && e.ctrlKey === true) ||
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        return;
      }

      // Allow numeric digits, '+', '(', and ')' keys only
      if (!/[\d()+]/.test(e.key)) {
        e.preventDefault();
      }
    });
    $("#phone").on("keypress", function (event) {
      var phone = $(this).val();
      if (phone.length >= 15) {
        event.preventDefault();
      }
    });
  }, []);

  const handleValidation = () => {
    let isValid = true;
    $(".error-msg").remove(); // Clear existing error messages

    // Validate Name
    const name = $("#name").val();
    if (!name) {
      isValid = false;
      $("#name").after(
        '<div class="error-msg text-danger">Name is required.</div>'
      );
    } else if (
      !/^[A-Za-z\s]*$/.test(name) ||
      name.length < 3 ||
      name.length > 50
    ) {
      isValid = false;
      $("#name").after(
        '<div class="error-msg text-danger">Name must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Email
    const email = $("#email").val();
    if (!email) {
      isValid = false;
      $("#email").after(
        '<div class="error-msg text-danger">Email is required.</div>'
      );
    } else if (!validateEmail(email)) {
      isValid = false;
      $("#email").after(
        '<div class="error-msg text-danger">Please enter a valid email address.</div>'
      );
    }

    // Validate Phone
    const phone = $("#phone").val();
    if (!phone) {
      isValid = false;
      $("#phone-wrapper").append(
        '<div class="error-msg text-danger">Phone number is required.</div>'
      );
    } else if (!validatePhone(phone)) {
      isValid = false;
      $("#phone-wrapper").append(
        '<div class="error-msg text-danger">Please enter a valid phone number.</div>'
      );
    }

    // Validate Designation
    const company = $("#designation").val();
    if (!company) {
      isValid = false;
      $("#designation").after(
        '<div class="error-msg text-danger">Designation is required.</div>'
      );
    } else if (company.length < 3 || company.length > 50) {
      isValid = false;
      $("#designation").after(
        '<div class="error-msg text-danger">Designation must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Organisation
    const organisation = $("#organisation").val();
    if (!organisation) {
      isValid = false;
      $("#organisation").after(
        '<div class="error-msg text-danger">Organisation is required.</div>'
      );
    } else if (organisation.length < 3 || organisation.length > 50) {
      isValid = false;
      $("#organisation").after(
        '<div class="error-msg text-danger">Organisation must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Area of Interest and Sub-option
    const selectedAreaId = $("#areaOfInterest").val();
    const selectedSubOption = $("#subField").val();

    if (!selectedAreaId) {
      isValid = false;
      $("#areaOfInterest").after(
        '<div class="error-msg text-danger">Please select an area of interest.</div>'
      );
    } else if (!selectedSubOption) {
      isValid = false;
      $("#subField").after(
        '<div class="error-msg text-danger">Please select a sub-option.</div>'
      );
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation()) return;

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "backend/Contact_form.php",
        {
          name: $("#name").val(),
          email: $("#email").val(),
          phone: $("#phone").val(),
          designation: $("#designation").val(),
          organisation: $("#organisation").val(),
          message: $("#message").val(),
          areaOfInterest: selectedAreaName,
          subField: selectedSubOption,
          mode: "addCenterModuleData_new",
        }
        // {
        //   headers: {
        //     "Content-Type": "application/json", // Ensure the Content-Type is set to application/json
        //   },
        // }
      );

      const responseData = response.data;
      if (responseData === 1) {
        // Reset form fields
        $("#contactform")[0].reset();

        // Reset state variables
        setSelectedAreaId("");
        setSelectedAreaName("");
        setSubOptions([]);
        setSelectedSubOption("");

        navigate("/thank-you");
        window.location.reload();
      } else {
        console.error("Error submitting form data");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setIsSubmitting(false);
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header p-3">
            <h6 className="section-sub_heading mb-0" id="staticBackdropLabel">
              Get in Touch!
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-0">
            <div className="reach-us_form">
              <form
                id="contactform"
                className="contactform"
                onSubmit={handleSubmit}
              >
                <div className="row g-4 p-3">
                  <div className="col-12">
                    <div className="form-group position-relative">
                      <label>
                        Name <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Full Name"
                        onInput={handleNameInput}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group position-relative">
                      <label>
                        Designation <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="designation"
                        name="designation"
                        className="form-control"
                        placeholder="Enter Your Designation"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group position-relative">
                      <label>
                        Organisation <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="organisation"
                        name="organisation"
                        className="form-control"
                        placeholder="Enter Your Organisation"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group position-relative">
                      <label>
                        Email Address <span className="text-red">*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email Address"
                        onKeyDown={handleKeypress}
                      />
                    </div>
                  </div>
                  <div className="col-12 position-relative" id="phone-wrapper">
                    <div className="form-group ">
                      <label>
                        Phone Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Enter Your Phone Number"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group position-relative">
                      <label>Area of Interest <span className="text-danger">*</span></label>
                      <select
                        name="area_of_Interest"
                        className="form-select mb-2 border"
                        aria-label="Default select example"
                        onChange={handleAreaChange}
                        value={selectedAreaId}
                        id="areaOfInterest"
                      >
                        <option value="">Select</option>
                        {areaOfInterestOptions.map((interest) => (
                          <option key={interest.id} value={interest.id}>
                            {interest.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {subOptions.length > 0 && (
                    <div className="col-lg-12 mt-0">
                      <div className="form-group position-relative">
                        <select
                          name="subField"
                          className="form-select mb-2 border"
                          aria-label="Default select example"
                          onChange={handleSubOptionChange}
                          value={selectedSubOption}
                          id="subField"
                        >
                          <option value="">Select</option>
                          {subOptions.map((subOption, index) => (
                            <option key={index} value={subOption}>
                              {subOption}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  
                  <div className="col-12">
                    <div className="form-group position-relative">
                      <label>
                        Message <small>(Optional)</small>
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        className="form-control"
                        placeholder="Write Message..."
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="modal-footer  p-3 mt-2">
                  <button
                    type="submit"
                    className="btn primary-btn-1"
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "18px",
                      textTransform: "none",
                      backgroundColor: "#052d83",
                      border: "1px solid #052d83",
                      padding: "8px 24px",
                      borderRadius: "8px",
                      whiteSpace: "normal",
                    }}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
