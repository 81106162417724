import React from "react";

const SectionFour = () => {
  return (
    <>
      <section className="complimentry-section-two bg-light p-relative ip-padding">
        <div className="container">
          <h4 className="section-heading mb-5 pe-5 pe-md-0 w-md-75 w-100 mx-auto ">
            Defense Equipments List
          </h4>
          <div className="boxed-layout">
            <div className="row g-lg-4 g-3 align-items-center">
              <div className="col-lg-8   p-relative">
                <div
                  className="bn-content-wrapper wow fadeInLeft"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <p className="section-paragraph">
                    UNICON is committed to delivering high-quality defence
                    equipment tailored to meet the specific needs of military
                    applications. Our solutions are designed for reliability and
                    performance, ensuring they withstand the demands of critical
                    operations. We prioritize innovation and efficiency,
                    providing equipment that enhances operational capabilities
                    while adhering to stringent safety standards. Trust UNICON
                    for advanced defence solutions that support mission
                    readiness and success.
                  </p> 
                  <h5 className="mb-0">List of some Defense Equipments by Unicon</h5>
                  <div className="d-md-flex gap-3  mt-4 ">
                    <ul className="list-style-two me-3 mb-0">
                      <li>
                        {" "}
                        Pressure vessels with MOC Mild Steel & Stainless Steel
                      </li>
                      <li>
                        Storage Tanks with MOC Mild Steel, Stainless Steel &
                        Aliminium
                      </li>
                      <li>Lead Lined RCC Tanks</li>
                      <li>FRP Lined Tanks</li>
                      <li>Jacketed Vessels</li>
                      <li>Agitators</li>
                      <li>Piping Systems</li>
                    </ul>
                    <ul className="list-style-two me-3 mb-0">
                      <li>Distillation Tray</li>
                      <li>Air Heating Apparatus</li>
                      <li>Special trucks for transportation of explosives</li>
                      <li>Crusher Grinder</li>
                      <li>Various type Heat Exchangers</li>
                      <li>Decomposers</li>
                      <li>Pistol cutting machine & Powder Cutting machine</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4  wow fadeInLeft"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <div className="banner-gallery text-md-center mt-lg-0 mt-3">
                  <img
                    style={{ borderRadius: "8px" }}
                    className="w-100 img-fluid"
                    src="/images/products/defence/dfc-vertical.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style>
        {`

            .ar, .ad{
                font-size:30px;
            }
                .adm{
                    font-size: 18px;
                    margin-top: -18px;
                }
            .flow-diagram {
                display: grid;
                grid-template-columns: 1fr 50px 1fr 50px 1fr;
                grid-template-rows: repeat(5, auto);
                gap: 10px;
                align-items: center;
                text-align: center;
            }
            .box {
                padding: 10px;
                border-radius:8px;
                font-weight: 600;
                color: #1a1a1a;
                border: 1px solid;
                background-color: #fff;
                position: relative;
            }

            .boxed-layout{
                background: #fff;
                padding: 20px;
                border-radius: 15px;
                border: 1px solid #ddd;
            }
            ul.list-style-two {
                padding-left: 30px;
            }
            .list-style-two li{
                list-style: disc;
                font-size: 18px;
                margin-bottom: 10px;
            }
        `}
      </style>
    </>
  );
};

export default SectionFour;
