import React from 'react';

const SectionTwo = () => {
    return (
        <>
            <section className="cust-padding bg-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"1s","animationName":"fadeInLeft"}}>
                            <div className="video-container inner-vdo-container" style={{"position":"relative","width":"100%","maxWidth":"100%","backgroundColor":"#fff","padding":"15px", "borderRadius":"10px"}}>
                                <video id="myVideo" className="video" autoPlay muted playsInline loop style={{"width":"100%","height":"auto","display":"block"}}>
                                    <source src="/videos/design.webm" type="video/mp4" />
                                       
                                </video>
                            </div>

                        </div>

                        <div className="col-lg-6 wow fadeInRight" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"1s","animationName":"fadeInLeft"}}>
                            <div className="list-card-feat">
                                <div className="d-flex gap-2">
                                    <h5><i className="bi bi-check2-circle"></i></h5>
                                    <div>
                                        <h5 className="mb-sm-3 mb-2">Optimum Sizing</h5>
                                        <p className="mb-0">With our vast experience in sizing of APC equipments, we are able to provide Optimum
                                            sizing to our customers.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="list-card-feat">
                                <div className="d-flex gap-2">
                                    <h5><i className="bi bi-check2-circle"></i></h5>
                                    <div>
                                        <h5 className="mb-sm-3 mb-2">Cost Effectiveness</h5>
                                        <p className="mb-0">As Unicon utilize modern technologies for designing our equipments and so we are providing cost effective solution to our customers.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="list-card-feat mb-0">
                                <div className="d-flex gap-2">
                                    <h5><i className="bi bi-check2-circle"></i></h5>
                                    <div>
                                        <h5 className="mb-sm-3 mb-2">Working with Space Constraints</h5>
                                        <p className="mb-0">Unicon is equipped with various tools to provide suitable solutions considering all space constraints.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <style>
                {`
                .list-card-feat {
                    padding: 20px;
                    border-radius: 10px;
                    background-color: #fff;
                    margin-bottom: 25px;
                    margin-left: 60px;
                }
                .list-card-feat h5 {
                    color: #052d83;
                }
                @media (max-width: 1399px) {
                    .list-card-feat {
                        margin-left: 20px;
                    }
                }
                @media (max-width: 991px) {
                    .video-container {
                        margin-bottom: 24px !important;
                    }
                    .list-card-feat {
                        margin-left: 0px;
                    }
                }

                `}
            </style>
        </>
    )
}

export default SectionTwo;