import React from 'react';
import { Link } from 'react-router-dom';


const SectionTwoThree = () => {
    return (
        <>
        
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/flue-gas-conditioning/img-1.webp" alt="" />

                            </div>
                        </div>
                        <div className="col-lg-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2"><span className="text-primary-red">Unicon's</span> High-Efficiency <br class="d-xl-block d-none" />Bag House Systems
                                </h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span> Bag House systems efficiently capture particulate matter with advanced fabric filters, ensuring cleaner exhaust gases. Our reliable cleaning mechanisms maintain optimal performance and air quality.</p>
                                <div className="d-flex gap-3 flex-wrap  mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Advanced Fabric Filter Bags</li>
                                        <li><i className="bi bi-check-circle"></i> Reliable Cleaning Mechanism </li>

                                    </ul>
                                    <ul className="icon-list style-two  ps-0">
                                        <li><i className="bi bi-check-circle"></i> Reliable Cleaning</li>
                                        <li><i className="bi bi-check-circle"></i> Advanced Filters</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-xl-6 col-lg-6  p-relative">
                            <div className="bn-content-wrapper wow fadeInLeft" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>

                                <h4 className="section-heading mb-4 pe-4 pe-md-0 w-md-75 w-100 mx-auto "> Advanced Sulfur Dioxide Removal FGD Systems
                                </h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span>  FGD systems efficiently remove sulfur dioxide from flue gases using absorbent materials like limestone slurry. This process reduces acid rain and ensures compliance with emission standards.</p>

                                <div className="d-flex gap-3 flex-wrap mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Efficient sulfur dioxide removal</li>
                                        <li><i className="bi bi-check-circle"></i> Reduces acid rain </li>

                                    </ul>
                                    <ul className="icon-list style-two  ps-0">
                                        <li><i className="bi bi-check-circle"></i> Uses absorbent materials</li>
                                        <li><i className="bi bi-check-circle"></i> Enhanced Air Quality</li>
                                    </ul>
                                </div>
                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/flue-gas-conditioning/img-2.webp" alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className=" w-100 img-fluid" src="/images/products/flue-gas-conditioning/img-3.webp" alt="" />

                            </div>
                        </div>
                        <div className="col-lg-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2">De NOx Reduction Solutions </h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span> De NOx technologies efficiently reduce nitrogen oxides (NOx) using advanced catalytic and non-catalytic methods. Our solutions ensure compliance with stringent emission standards for cleaner air.</p>
                                <div className="d-flex gap-3 flex-wrap  mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Catalytic/non-catalytic methods</li>
                                        <li><i className="bi bi-check-circle"></i> Reduces impact </li>

                                    </ul>
                                    <ul className="icon-list style-two  ps-0">
                                        <li><i className="bi bi-check-circle"></i> Converts NOx to N₂/O₂</li>
                                        <li><i className="bi bi-check-circle"></i> Meets strict standards</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-xl-6 col-lg-6  p-relative">
                            <div className="bn-content-wrapper wow fadeInLeft" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>

                                <h4 className="section-heading mb-4 pe-4 pe-md-0 w-md-75 w-100 mx-auto "> SCR Technology <br className="d-xl-block d-none" />
                                for NOx Control
                                </h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span>  SCR systems use a high-performance catalyst and reducing agents to convert NOx into nitrogen and water, ensuring high efficiency and regulatory compliance.</p>

                                <div className="d-flex gap-3 flex-wrap mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> High-performance catalyst</li>
                                        <li><i className="bi bi-check-circle"></i> Enhances air quality </li>

                                    </ul>
                                    <ul className="icon-list style-two  ps-0">
                                        <li><i className="bi bi-check-circle"></i> Uses ammonia or urea</li>
                                        <li><i className="bi bi-check-circle"></i> Improves air quality</li>
                                    </ul>
                                </div>
                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/flue-gas-conditioning/img-4.webp" alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className=" w-100 img-fluid" src="/images/products/flue-gas-conditioning/img-5.webp" alt="" />

                            </div>
                        </div>
                        <div className="col-lg-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2">SNCR Technology for NOx Reduction</h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span> SNCR systems reduce NOx emissions by injecting reducing agents into the flue gas stream, converting NOx into nitrogen and water at high temperatures without using catalysts.</p>
                                <div className="d-flex gap-3 flex-wrap  mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> No catalyst required</li>
                                        <li><i className="bi bi-check-circle"></i> Uses reducing agents </li>

                                    </ul>
                                    <ul className="icon-list style-two  ps-0">
                                        <li><i className="bi bi-check-circle"></i> Cost-effective solution</li>
                                        <li><i className="bi bi-check-circle"></i> Efficient NOx reduction</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-xl-6 col-lg-6  p-relative">
                            <div className="bn-content-wrapper wow fadeInLeft" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>

                                <h4 className="section-heading mb-4 pe-4 pe-md-0 w-md-75 w-100 mx-auto "> Dioxin Control with <br className="d-xl-block d-none" /> Activated Carbon Dosing
                                </h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span>  activated carbon dosing systems effectively remove dioxins and furans from flue gases, ensuring cleaner emissions and compliance with environmental standards.</p>

                                <div className="d-flex gap-3 flex-wrap mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Uses activated carbon</li>
                                        <li><i className="bi bi-check-circle"></i> Adsorbs toxic compounds </li>

                                    </ul>
                                    <ul className="icon-list style-two  ps-0">
                                        <li><i className="bi bi-check-circle"></i> Effective dioxin removal</li>
                                        <li><i className="bi bi-check-circle"></i> Ensures cleaner emissions</li>
                                    </ul>
                                </div>
                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/flue-gas-conditioning/img-6.webp" alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`

                .ar, .ad{
                    font-size:30px;
                }
                    .adm{
                     font-size: 18px;
                      margin-top: -18px;
                    }
                .flow-diagram {
                    display: grid;
                    grid-template-columns: 1fr 50px 1fr 50px 1fr;
                    grid-template-rows: repeat(5, auto);
                    gap: 10px;
                    align-items: center;
                    text-align: center;
                }
                .box {
                    padding: 10px;
                    border-radius:8px;
                    font-weight: 600;
                    color: #1a1a1a;
                    border: 1px solid;
                    background-color: #fff;
                    position: relative;
                }
        
                @media only screen and (max-width: 991px) {
                    .icon-list.style-three {
                        display: flex;
                    }
                    ul.icon-list.style-three li {
                        align-items: center;
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
                @media only screen and (max-width: 575px) {
                    .flex-sm-d-wrap {
                        flex-wrap: wrap;
                    }
                }
                `}
            </style>
        </>
    )
}

export default SectionTwoThree;