import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import ProductsMegamenu from "./ProductsMegamenu";
import ServiceMegamenu from "./ServiceMegamenu";

import MobileMenu from "./MobileMenu";
import GetInTouch from "../Components/GetInTouch";
import ScrolltoTop from "../Components/ScrolltoTop";

const Header = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 || location.pathname !== "/") {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Check on component mount
    handleScroll();

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location.pathname]);



  // MEGA MENU---------------
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState(false);
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);

  // Handlers for Products menu
  const handleProductsMenuOpen = () => {
    setIsProductsMenuOpen(true);
  };

  const handleProductsMenuClose = () => {
    setIsProductsMenuOpen(false);
  };

  // Handlers for Services menu
  const handleServicesMenuOpen = () => {
    setIsServicesMenuOpen(true);
  };

  const handleServicesMenuClose = () => {
    setIsServicesMenuOpen(false);
  };

   // Common handler to close all menus
   const closeAllMenus = () => {
    setIsProductsMenuOpen(false);
    setIsServicesMenuOpen(false);
  };

  return (
    <>

      <ScrolltoTop />


      <div className="socials-bucket z-4">
        <a
          target="_blank"
          href="https://www.facebook.com/UniconEngineersCBE/"
          className="social-btn bt-facebook"
        >
          <i className="bi bi-facebook"></i>
        </a>
        <a target="_blank" href="https://www.instagram.com/unicon_esp/" className="social-btn bt-instagram">
          <i className="bi bi-instagram"></i>
        </a>
        <a target="_blank" href="https://x.com/EspUnicon/" className="social-btn bt-twitter">
          <i className="bi bi-twitter-x"></i>
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/unicon-engineers/about/"
          className="social-btn bt-linkedin"
        >
          <i className="bi bi-linkedin"></i>
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/@uniconengineers6035"
          className="social-btn bt-youtube"
        >
          <i className="bi bi-youtube"></i>
        </a>
      </div>

      <div className="common-cta_bucket">
        <button
          type="button"
          className="btn primary-btn-1 custom-cta_common"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Get in Touch!
        </button>
        <GetInTouch />
      </div>

      <header>
        <div
          className="header-area"
          id={isSticky ? "header-sticky-active" : "header-sticky"}
        >
          <div className="container">
            <div className="mega-menu-wrapper">
              <div className="header-main">
                <div className="header-left">
                  <Link to="/" className="header-logo">
                    <img
                      src="/images/logo-dark.webp"
                      className="dark-logo"
                      alt=""
                    />
                    <img
                      src="/images/logo-white.webp"
                      className="light-logo"
                      alt=""
                    />
                  </Link>
                </div>

                <div className="header-right d-flex justify-content-end align-items-center">
                  <div className="mean__menu-wrapper d-none d-lg-block">
                    <div className="main-menu">
                      <nav id="mobile-menu">
                        <ul>
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            <Link to="/profile">Profile</Link>
                          </li>
                          <li>
                            <Link to="/design">Design</Link>
                          </li>
                          <li className="has-dropdown products-nav-list" onMouseEnter={handleProductsMenuOpen}
                            onMouseLeave={handleProductsMenuClose}>
                            <Link to="/products" onClick={closeAllMenus}>
                              Products{" "}
                              <i className="bi bi-chevron-down fs-6 ms-1"></i>
                            </Link>
                            <div className="dropdown-menu w-100" style={{ display: isProductsMenuOpen ? 'block' : 'none' }} >

                              <ProductsMegamenu closeMenu={handleProductsMenuClose} />
                            </div>

                          </li>
                          <li className="has-dropdown service-nav-list" onMouseEnter={handleServicesMenuOpen}
                            onMouseLeave={handleServicesMenuClose}>
                            <Link to="/services" className="cls-btn" onClick={closeAllMenus}>
                              Services{" "}
                              <i className="bi bi-chevron-down fs-6 ms-1"></i>
                            </Link>

                            <div className="dropdown-menu w-100" style={{ display: isServicesMenuOpen ? 'block' : 'none' }} >
                              <ServiceMegamenu closeMenu={handleServicesMenuClose} />
                            </div>
                          </li>
                          <li>
                            <Link to="/job">Job</Link>
                          </li>
                          <li>
                            <Link to="/contact">Connect to Us</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="header-action d-none d-lg-inline-flex gap-5">
                    <div className="header-link">
                      <Link to="/get-a-quote" className="primary-btn-1 btn-hover">
                        Get a Quote <i class="bi bi-arrow-right ps-2"></i>
                        <span
                          style={{ top: "147.172px", left: "108.5px" }}
                        ></span>
                      </Link>
                    </div>
                  </div>
                  <div className="header__hamburger d-lg-none d-block my-auto">
                    <div className="sidebar__toggle">
                      <MobileMenu />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
