import React, { useEffect } from 'react';


const SectionTwo = () => {

 
  return (
    <>

<section className="cust-padding bg-purp">
      <div className="container">
         <div className="row g-4">
            <div className="col-12 wow fadeInUp" data-wow-delay="0s "  data-wow-offset="100" style={{"visibility":"visible","animationDelay":"1s","animationName":"fadeInUp"}}>
               <div className="text-center abt-inner mb-sm-4 mb-0">
                  <h4 className="section-heading">Delivering technology and services with<br className="d-lg-block d-none" /> more than 35 years of experience</h4>
               </div>
            </div>

            <div className="col-lg-4 wow fadeInDown" data-wow-delay=".2s" data-wow-offset="100"  style={{"visibility":"visible","animationDelay":"1s","animationName":"fadeInUp"}}>
               <div className="card h-100 abt-card-inner">
                  <div className="card-body">
                     <div><img src="/images/vision.webp" alt="" style={{"marginBottom":"20px","width":"70px"}}/></div>
                     <h5 className='mb-3'>Our Vision</h5>
                     <p className='mb-0' style={{fontWeight:'500'}}>To become the world-className Environmental protection industry by delivering Technology and Services.</p>
                  </div>
               </div>
            </div>

            <div className="col-lg-4 wow fadeInDown" data-wow-delay=".2s" style={{"visibility":"visible","animationDelay":"1s","animationName":"fadeInUp"}}>
               <div className="card h-100 abt-card-inner">
                  <div className="card-body">
                     <div><img src="/images/mission.webp" alt="" style={{"marginBottom":"20px","width":"70px"}}/></div>
                     <h5 className='mb-3'>Our Mission</h5>
                     <p className='mb-0' style={{fontWeight:'500'}}>Passionate commitment to provide cost- effective & world className manufacturing process and Services.</p>
                  </div>
               </div>
            </div>

            <div className="col-lg-4 wow fadeInDown" data-wow-delay=".2s" style={{"visibility":"visible","animationDelay":"1s","animationName":"fadeInUp"}}>
               <div className="card h-100 abt-card-inner">
                  <div className="card-body">
                     <div><img src="/images/quality.webp" alt="" style={{"marginBottom":"20px","width":"70px"}}/></div>
                     <h5 className='mb-3'>Quality Policy</h5>
                     <p className='mb-0' style={{fontWeight:'500'}}>To achieve enhanced customer satisfaction by providing quality products and services, on time, each time, continually.</p>
                  </div>
               </div>
            </div>


         </div>
      </div>
   </section>

   <style>
    {`
    .abt-card-inner {
        border: none;
        border-radius: 20px;
        padding: 25px;
    }
        `}
   </style>
    </>
  )
}

export default SectionTwo;