import React from "react";

// Services Section Scroll Content
const CFDScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h4 className="section-heading mb-4 ">
                  Enhancing Gas Distribution Efficiency in ESP
                </h4>
                <p className="section-paragraph">
                  Ensuring uniform gas distribution across the ESP's
                  cross-section is crucial for optimizing the utilization of the
                  Collecting Area and achieving peak performance efficiency.
                  Leveraging our advanced Computational Fluid Dynamics (CFD)
                  software, we simulate the entire system—from ducting preceding
                  the ESP to the ESP itself. Here's how we ensure optimal gas
                  flow:
                </p>
              </div>

              <div className="">
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/cfd/icons/1.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Detailed Analysis and Simulation:
                    </h6>
                    <p className="section-paragraph">
                      We analyze flow patterns and introduce necessary flow
                      correction devices to enhance gas distribution within the
                      ESP.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/cfd/icons/2.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Non-intrusive Study:
                    </h6>
                    <p className="section-paragraph">
                      This study can be conducted without plant shutdowns,
                      allowing for continuous operation.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/cfd/icons/3.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Customized Recommendations:
                    </h6>
                    <p className="section-paragraph">
                      Based on our findings, we recommend specific flow
                      correction devices for installation.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/cfd/icons/4.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Flexible Implementation:
                    </h6>
                    <p className="section-paragraph">
                      Installation can be scheduled during planned plant
                      shutdowns, ensuring minimal disruption.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/cfd/icons/5.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Verification and Validation:
                    </h6>
                    <p className="section-paragraph">
                      Post-installation, we can conduct Site GD measurements to
                      confirm performance improvements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/cfd/cfd-scroll.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const GDTScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <span className="section-sub-title fw-bold d-block">
                  Test Procedure
                </span>
                <h4 className="section-heading mb-4 ">
                  Optimizing Air Flow in ESP Chambers
                </h4>
                <p className="" style={{ fontSize: '18px' }}>
                  To ensure optimal air flow in ESP chambers, we follow a
                  precise process. We run ID, FD, and PA air fans at suitable
                  loadings and record fan loadings and furnace draught for
                  consistency. Velocity measurements are taken using a vane type
                  anemometer.
                </p>
              </div>

              <div className="">
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/gd-testing/icons/1.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Velocity Measurements:
                    </h6>
                    <p className="section-paragraph">
                      Measure velocities between the first and second field.
                      Mark points on the trailing edge of the first field’s
                      collecting electrodes, spaced 1000mm apart with a 500mm
                      gap from the top and bottom, and take readings at every
                      alternate electrode spacing.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/gd-testing/icons/2.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Trial and Adjustment:
                    </h6>
                    <p className="section-paragraph">
                      Conduct multiple trials to achieve acceptable gas
                      distribution, making adjustments to deflector/throttling
                      plates based on test results. Repeat the process until the
                      desired distribution is achieved.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/gd-testing/icons/3.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Acceptance Criteria:
                    </h6>
                    <p className="section-paragraph">
                      Ensure the coefficient of variation for gas distribution
                      is less than 20%.
                    </p>
                  </div>
                </div>
              </div>

              <p className="section-paragraph">
                This methodical approach guarantees efficient gas distribution
                and enhances the ESP chamber's performance.
              </p>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/gd-testing/chamber.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const EmissionTestingScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h4 className="section-heading mb-4 ">
                  ESP Electrical Condition Monitoring
                </h4>
                <p className="" style={{ fontSize: '18px' }}>
                  ESP electrical conditions will read manually from meters on
                  the transformer-rectifier controller cabinets. All of the
                  sampling and data collection will be coordinated with the
                  control room operators to assure that the testing is conducted
                  during full load and normal operating conditions. Will submit
                  detailed report after completion of the test.
                </p>
              </div>

              <div className="">
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/emission-testing/icons/1.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">Manual Reading:</h6>
                    <p className="section-paragraph">
                      Electrical conditions of the ESP will be manually read
                      from meters on the transformer-rectifier controller
                      cabinets.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/emission-testing/icons/2.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">Coordination:</h6>
                    <p className="section-paragraph">
                      Sampling and data collection will be coordinated with
                      control room operators to ensure testing occurs during
                      full load and normal operating conditions.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/emission-testing/icons/3.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">Detailed Reporting:</h6>
                    <p className="section-paragraph">
                      A comprehensive report will be submitted upon completion
                      of the test.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/emission-testing/emmission.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SupervisoryServiceScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h4 className="section-heading mb-4 ">
                  We are regularly providing Supervisory services in the
                  following fields.
                </h4>
              </div>

              <div className="">
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/1.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      1. Erection & Commissioning
                    </h6>
                    <p className="section-paragraph">
                      We ensure precise assembly and start-up of equipment,
                      guaranteeing optimal performance from the outset.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/2.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      2. Retrofitting / Revamping of ESP
                    </h6>
                    <p className="section-paragraph">
                      We upgrade existing Electrostatic Precipitators (ESPs) to
                      enhance efficiency and extend their service life.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/3.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      3. Complete Revamping of ESP Fields
                    </h6>
                    <p className="section-paragraph">
                      Our comprehensive overhaul services restore ESP fields to
                      their full operational capacity, improving particulate
                      collection.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/4.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      4. Planned Maintenance
                    </h6>
                    <p className="section-paragraph">
                      We conduct scheduled maintenance to prevent unexpected
                      breakdowns and ensure continuous operation.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/5.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      5. Shutdown Maintenance
                    </h6>
                    <p className="section-paragraph">
                      During planned shutdowns, we perform extensive maintenance
                      tasks to optimize system reliability and performance.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/6.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      6. Breakdown Maintenance
                    </h6>
                    <p className="section-paragraph">
                      Our team provides immediate repair services to minimize
                      downtime and restore functionality swiftly after a
                      failure.
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/7.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      7. Short Term and Long Term Operation and Maintenance
                    </h6>
                    <p className="section-paragraph">
                      We offer both short-term and long-term maintenance
                      contracts to support ongoing operational efficiency and
                      reliability.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/8.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">8. Field Alignment</h6>
                    <p className="section-paragraph">
                      We ensure precise alignment of all components to maximize
                      the effectiveness and lifespan of the equipment.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/9.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">9. No Load Test</h6>
                    <p className="section-paragraph">
                      We conduct tests under no-load conditions to verify the
                      correct installation and readiness of the system.
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/10.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">10. Full Load Test</h6>
                    <p className="section-paragraph">
                      We perform full-load tests to confirm the system's
                      performance and capacity under operational conditions.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/supervisory-service/icons/11.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      11. Visual Inspection of ESP, Bag Filters, Cyclones
                    </h6>
                    <p className="section-paragraph">
                      Our experts carry out thorough visual inspections to
                      identify and address any potential issues before they
                      become major problems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/supervisory-service/ss2.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ECScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h4 className="section-heading mb-4 ">
                  Erection, Trial Runs and Commissioning Services
                </h4>
                <p className="" style={{ fontSize: '18px' }}>
                  UNICON is equipped to handle the erection and commissioning of
                  Air Pollution Control Equipment across multiple industries,
                  ensuring optimal performance and client satisfaction through
                  expert planning and rigorous trials.
                </p>
              </div>

              <div className="">
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/erection-comissioning/icons/1.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Expert Erection Services
                    </h6>
                    <p className="section-paragraph">
                      UNICON excels in erecting mechanical, electrical,
                      instrumentation, and control systems for Air Pollution
                      Control Equipment across various industries including
                      Power Plants, Cement Plants, Chemical Plants, Paper
                      Plants, and Steel Plants.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/erection-comissioning/icons/2.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Comprehensive Commissioning Services:
                    </h6>
                    <p className="section-paragraph">
                      We provide commissioning services and ensure process
                      stabilization with guaranteed performance parameters
                      across diverse industrial sectors.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/erection-comissioning/icons/3.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Efficient Trials and Swift Stabilization:
                    </h6>
                    <p className="section-paragraph">
                      Leveraging our expertise in systematic no-load and load
                      trials, we achieve rapid commissioning and plant
                      stabilization, delivering substantial operational benefits
                      to our clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/erection-comissioning/ec2.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const RetrofitsScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <span className="section-sub-title fw-bold d-block">
                  Test Procedure
                </span>
                <h4 className="section-heading mb-4 ">
                  Specialized Services and Critical Projects
                </h4>
                <p className="" style={{ fontSize: '18px' }}>
                  UNICON stands out in retrofitting and installing Air Pollution
                  Control Equipment across diverse industries, demonstrating
                  expertise in swift project execution and ensuring enhanced
                  operational efficiency and performance parameters.
                </p>
              </div>

              <div className="">
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <h1 className="scroll-text px-1">1</h1>
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Revamping of 300 MW ESP:
                    </h6>
                    <p className="section-paragraph">
                      Executed within a short timeframe, including supply of
                      Collecting Electrodes, Emitting Electrodes, and other
                      internals.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <h1 className="scroll-text px-1">2</h1>
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Design, Engineering, and Commissioning of 110 MW Thermal
                      Power Plant ESP:
                    </h6>
                    <p className="section-paragraph">
                      Successfully managed the complete lifecycle from design
                      and engineering to manufacturing, erection, and
                      commissioning.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <h1 className="scroll-text px-1">3</h1>
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Swift Dismantling and Installation Project:
                    </h6>
                    <p className="section-paragraph">
                      Completed dismantling of existing ESP and installation of
                      a new one within 18 days, surpassing the order deadline of
                      20 days.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <h1 className="scroll-text px-1">4</h1>
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Retrofitting of 67.5 MW ESP:
                    </h6>
                    <p className="section-paragraph">
                      Retrofit project included retrofitting of 16 fields and
                      filling of 2 dummy fields to enhance operational
                      efficiency.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <h1 className="scroll-text px-1">5</h1>
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Overhaul of Sinter Plant ESP:
                    </h6>
                    <p className="section-paragraph">
                      Scope involved replacement of internals for 6 fields and
                      overhaul of 2 fields, completed successfully within a
                      12-day shutdown period.
                    </p>
                  </div>
                </div>
              </div>

              <p className="section-paragraph">
                These projects highlight UNICON's expertise in retrofitting,
                revamping, and installing Air Pollution Control Equipment across
                diverse industrial settings with precision and efficiency.
              </p>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/retrofits/retrofit2.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const UpgradesScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h4 className="section-heading mb-4 ">Change in Regulations</h4>
                <p className="section-paragraph">
                  Change in inputs results in emission levels higher than
                  original design. As years pass regulations have been becoming
                  strict. Emission levels which were &gt;700mg/Nm3 in the early
                  70s has over the years come down to 100mg/Nm3 today. This
                  reduction is becoming steeper in recent years with 50mg/Nm3
                  being conceived generally and even 10mg/Nm3 not rare.
                </p>
              </div>

              <div className="">
                <h4 className="section-heading mb-4 ">Improved Performance</h4>
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/upgrades/icons/1.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Enhancing ESP Performance Levels and Augmenting Capacity:
                    </h6>
                    <p className="section-paragraph">
                      We retrofit existing ESPs to improve their performance and
                      increase their capacity, ensuring they meet current
                      operational demands.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/upgrades/icons/2.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Benchmarking and Solution Implementation:
                    </h6>
                    <p className="section-paragraph">
                      Our process involves benchmarking with international
                      standards, identifying deficient areas, and implementing
                      tailored solutions with our expert team.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/upgrades/icons/3.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Continuous Benchmark Updates:
                    </h6>
                    <p className="section-paragraph">
                      We continuously update benchmarks as part of our efficient
                      manufacturing management, ensuring sustained improvements
                      and compliance with evolving standards.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/upgrades/icons/4.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Cost Reduction and Optimization:
                    </h6>
                    <p className="section-paragraph">
                      By optimizing operation and performance parameters, we
                      significantly reduce production costs and minimize the
                      consumption of input materials.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/upgrades/icons/5.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Significant Cost Savings in Large Capacity Plants:
                    </h6>
                    <p className="section-paragraph">
                      Our optimization assignments in large capacity plants
                      offer a payback period of less than one year, with
                      continued benefits over the plant’s lifetime.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/upgrades/icons/6.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Comprehensive Expertise for Process and Engineering
                      Plants:
                    </h6>
                    <p className="section-paragraph">
                      We possess the expertise to provide specialized services
                      for any process or engineering plant, enhancing overall
                      performance and efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/upgrades/upgrades-scroll.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const RLAScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInRight mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInRight",
                }}
              >
                <div className="mb-lg-5 mb-4">
                  <h4 className="section-heading mb-lg-3 mb-2 ">
                    Brief Scope of Work for RLA Study
                  </h4>
                  <p className="" style={{ fontSize: '18px' }}  >
                    The scope of work includes the condition assessment of the
                    ESP, RLA of all necessary components of the ESP, and a
                    review of previous studies, if any. It also involves
                    conducting an energy audit for baseline assessment of the
                    ESP, insulation audit, auxiliary power consumption
                    assessment, and performance testing of the ESP.
                    Additionally, the scope covers addressing generic problems
                    of the ESP, conducting a feasibility study for the
                    incorporation of modern technologies, and performing an
                    advantages and cost-benefit analysis for efficient,
                    reliable, economical, and safe performance. Finally, the
                    work includes preparing a detailed project report (DPR) that
                    outlines the complete scope and justification, as well as
                    creating detailed scope of work and cost estimates, with an
                    exploration of cost-effective alternative rehabilitation
                    options.
                  </p>
                </div>
                <div className="mb-lg-5 mb-4">
                  <h4 className="section-heading mb-lg-3 mb-2">
                    Methodology for Preparation of Detailed Project Report (DPR)
                  </h4>
                  <div className="mt-4 ">
                    <ul className="icon-list style-two  ps-0 mb-0">
                      <li>
                        <i className="bi bi-check-circle"></i> Phase-I :
                        Pre-Shutdown: Start up meeting with O&M personnel of the
                        plant regarding present performance, details of
                        modifications / replacements carried out in various
                        systems followed by preparatory works before shutdown of
                        unit.
                      </li>
                      <li>
                        <i className="bi bi-check-circle"></i>Phase-II: Shutdown
                        period testing and Study.{" "}
                      </li>
                      <li>
                        <i className="bi bi-check-circle"></i> Phase-III: Post
                        Shutdown testing and study.
                      </li>
                      <li>
                        <i className="bi bi-check-circle"></i> Phase-IV:
                        Analysis of Data and findings.
                      </li>
                      <li>
                        <i className="bi bi-check-circle"></i> Phase-V: Draft
                        Final report preparation & Submission for discussion &
                        approval Phase-VI: Final Report submission.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mb-3">
                  <h4 className="section-heading mb-lg-3 mb-2 ">
                    Deliverables
                  </h4>
                  <p className="" style={{ fontSize: '18px' }}>
                    Daily Progress report during testing at site will be
                    submitted to RLA monitoring team. This report shall contain
                    daily activities over components under study. Preliminary
                    Inspection Report, It shall be compilation of daily reports
                    along with preliminary observations. Final Report
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/rla/rla-main2.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SMScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h4 className="section-heading mb-4 ">
                  Preventive maintenance is condition monitoring
                </h4>
                <p className="section-paragraph">
                  Parameters like vibration, sound, temperature etc. are
                  continuously monitored. We also provide people to implement
                  the designed system and ensure guaranteed working. We ensures
                  near about cent percent availability of the pollution control
                  equipments of the plant at minimum maintenance cost.
                </p>
              </div>

              <div className="">
                <h4 className="section-heading mb-4 ">
                  Breakdown Maintenance Services
                </h4>
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/shutdown/icons/1.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">Proactive Planning:</h6>
                    <p className="section-paragraph">
                      Despite planned maintenance, unexpected breakdowns can
                      occur due to various process reasons. We proactively study
                      client's plants in advance to identify vulnerable points
                      for potential breakdowns.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/shutdown/icons/2.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Qualified Technicians and Ready Resources:
                    </h6>
                    <p className="section-paragraph">
                      Our team of qualified technicians, along with spare parts
                      and pre-assembled sub-assemblies, are kept ready to
                      address breakdown repairs at the shortest notice possible.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/shutdown/icons/3.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      On-Site Repair Team:
                    </h6>
                    <p className="section-paragraph">
                      Upon receiving breakdown intimation, our team rushes to
                      the site with tools and kits to repair the plant in the
                      minimum time.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/shutdown/icons/4.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Cost Reduction and Optimization:
                    </h6>
                    <p className="section-paragraph">
                      By optimizing operation and performance parameters, we
                      significantly reduce production costs and minimize the
                      consumption of input materials.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-center wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/shutdown/icons/5.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Permanent On-Site Maintenance Team:
                    </h6>
                    <p className="section-paragraph">
                      For plants with high breakdown severity, a trained
                      maintenance team with tools and kits can be stationed at
                      the plant on a permanent basis, if desired by the client.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/shutdown/shutdown-main2.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const AMScroll = () => {
  return (
    <>
      <section className="nox-burners bg-light p-relative ip-padding">
        <div className="container">
          <div className="row sroll-wrapper">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 left">
              <div
                className="bn-content-wrapper wow fadeInLeft mb-4"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h4 className="section-heading mb-4 ">
                  Comprehensive Maintenance Services
                </h4>
              </div>

              <div className="">
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/1.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Diagnostic Study and Planning:
                    </h6>
                    <p className="section-paragraph">
                      We conduct thorough diagnostic studies and prepare
                      detailed shutdown maintenance plans using the latest
                      planning and scheduling techniques.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/2.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Advanced Spare Management:
                    </h6>
                    <p className="section-paragraph">
                      Predicting and arranging the required spares and
                      fabricated items in advance ensures smooth maintenance
                      activities.
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/3.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Efficient Shutdown Maintenance:
                    </h6>
                    <p className="section-paragraph">
                      We provide people, tools, spares, and components (supplied
                      by us or the client) to commence shutdown maintenance
                      activities efficiently.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/4.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Timely Completion and Reliability:
                    </h6>
                    <p className="section-paragraph">
                      Ensuring the completion of annual shutdown jobs within the
                      shortest possible time with utmost reliability.
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/5.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Short-Term and Long-Term Contracts:
                    </h6>
                    <p className="section-paragraph">
                      Offering short-term (annual) and long-term operations and
                      maintenance contracts, covering preventive maintenance,
                      shutdown jobs, and breakdown jobs.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/6.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Comprehensive Staffing Solutions:
                    </h6>
                    <p className="section-paragraph">
                      Providing all required operating staff, maintenance staff,
                      supervisory personnel, and managerial support.
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/7.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      Guaranteed Performance Parameters:
                    </h6>
                    <p className="section-paragraph">
                      Ensuring guaranteed performance parameters on operation
                      contracts, with minimum downtime and cost.
                    </p>
                  </div>
                </div>
                <hr />

                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/8.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">
                      On-Site Maintenance Team:
                    </h6>
                    <p className="section-paragraph">
                      Stationing skilled personnel with necessary tools and
                      tackles at plant premises to attend to jobs as required by
                      the client.
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  className="d-flex gap-4 align-items-start wow fadeInRight my-4"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="scroll-icon">
                    <img
                      src="/images/services/annual-maintenance/icons/9.webp"
                      alt=""
                      className="img-fluid roof"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className="content">
                    <h6 className="section-sub_heading">Quality Assurance:</h6>
                    <p className="section-paragraph">
                      Providing guarantees on the quality of maintenance
                      services, ensuring client satisfaction and operational
                      efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 right wow fadeInLeft float-md-end"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <figure className="image text-center vertical-align-center">
                <img
                  src="/images/services/annual-maintenance/annual-maintenance2.webp"
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export {
  CFDScroll,
  GDTScroll,
  EmissionTestingScroll,
  SupervisoryServiceScroll,
  ECScroll,
  RetrofitsScroll,
  UpgradesScroll,
  RLAScroll,
  SMScroll,
  AMScroll,
};
