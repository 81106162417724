import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';


const BagHouseAcc = () => {
    return (
        <>

            <section className="product-specification ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Maximizing Performance & Benefits of Bag Houses</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Designed for Superior Performance
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div className="bn-content-wrapper ">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advanced Filtration Technology</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Our Bag Houses utilize state-of-the-art filtration technology to capture up to 99.9% of particulate matter.</li>
                                                        <li>Ensures exceptionally clean air and enhances environmental compliance.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Durable Construction</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Built with high-quality materials to withstand harsh industrial conditions.</li>
                                                        <li>Provides long-lasting performance and reduces maintenance costs.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Automated Cleaning System</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Features PLC-controlled cleaning cycles for efficient dust removal.</li>
                                                        <li>Minimizes manual intervention and operational downtime.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Energy Efficiency</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Designed to minimize power consumption while maintaining high performance.</li>
                                                        <li>Reduces operational costs and supports sustainability initiatives.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        Key Benefits
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <div className='bn-content-bucket'>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Health and Safety</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li><strong>Improves Air Quality:</strong> Captures harmful dust and particles, ensuring cleaner air for workers.</li>
                                                        <li><strong>Enhances Safety:</strong> Reduces respiratory issues and maintains a safer working environment.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Environmental Compliance</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li><strong>Meets Regulations:</strong> Helps industries comply with stringent environmental standards.</li>
                                                        <li><strong>Reduces Emissions:</strong> Minimizes the release of pollutants into the atmosphere.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Cost Savings</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li><strong>Lowers Maintenance Costs:</strong> Durable construction and automated systems reduce the need for frequent maintenance.</li>
                                                        <li><strong>Increases Efficiency:</strong> Energy-efficient design lowers operational expenses.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Customization</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc mb-0 ps-3 text-dark">
                                                        <li><strong>Tailored Solutions:</strong> Customizable to meet specific industrial needs and space constraints.</li>
                                                        <li><strong>Versatile Applications:</strong> Suitable for various industries, including cement, steel, and power plants.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

const AmmoniaAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Ammonia Flue Gas Conditioning</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">

                                            <div class="row w-100 align-items-center">
                                                <div class="col-sm-8">
                                                    <div>
                                                        <p>This technology results in reduction of SPM emission drastically from a level of 600-700 mg/Nm3 to 20-70 mg/Nm<sup>3</sup>.
                                                        </p>

                                                        <p class="mb-0">The quality of coal, increased ash content, ash resistivity and many other factors result in the lower performance of ESP and consequent increase in SPM. Many technologies have been tried out for improving the performance of ESP but limited success. With Environmental Standards being continuously upgraded for cleaned air quality, time has come for upgrading and developing new technology for overcoming the above problem.</p>
                                                    </div>
                                                </div>

                                                <div class="col-sm-4">
                                                    <div class="text-center">
                                                        <img src="/images/products/afgc/formula-img.webp" class="img-fluid formula-img" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages:</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc mb-0 ps-3 text-dark">
                                                        <li><b>Safety and Performance: </b>Ensures high safety standards and delivers significant performance improvements.</li>
                                                        <li><b>Integration: </b>Can be seamlessly integrated into existing plants with minimal modifications.</li>
                                                        <li><b>No Shutdown Required: </b> Implementation does not require shutdown time, minimizing operational disruptions.</li>
                                                        <li><b>Cost-Effectiveness: </b>Achieves SPM reduction at a low cost, making it economically viable.</li>
                                                        <li><b>Revitalizes Existing Equipment: </b>Enhances the capabilities of older installations without extensive upgrades.</li>
                                                    </ol>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const PcsAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Pneumatic Conveying System</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Key Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div className="bn-content-wrapper ">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Economical and Reliable</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Cost-effective solution for material handling.</li>
                                                        <li>Proven reliability in various industries</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Power Efficiency</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Efficient use of energy during conveying.</li>
                                                        <li>Reduces operational costs.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">High Capacity Conveying Line</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Handles large volumes of material.</li>
                                                        <li>Ideal for continuous production.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Flexible Control Options</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Most preferable type of control.</li>
                                                        <li>Performs relay logic, timing, counting, and sequencing functions.</li>
                                                        <li>Allows customization based on specific needs.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Conveying Distance</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Capable of conveying materials up to 2000 meters.</li>
                                                        <li>Suitable for long-distance applications.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Vessel Sizes</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Wide range of vessel sizes available.</li>
                                                        <li>Tailor the system to your requirements.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Quick Delivery</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Shortest delivery time for system installation.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Troubleshooting and Expansion</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc mb-0 ps-3 text-dark">
                                                        <li>Ability to troubleshoot, modify, and expand the system.</li>
                                                        <li>Adaptable to changing needs.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

const RotaryAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Rotary Airlock Valves</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">

                                            <div class="">
                                                <p>Unicon Products are distributed worldwide, offering a comprehensive range of standard components, as well as international standards sanitary versions and explosion-proof models.</p>

                                                <p class="mb-0">UNICON’s Rotary Air Lock Valves are engineered for economical and reliable material metering and airlock service, ideal for pollution control applications beneath ESPs, dust collectors, and cyclone separators. Designed for light-duty dust collection, our RAVs provide a cost-effective airlock solution.</p>

                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Manufacturing Excellence:</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li><b>Materials: </b>Available in Mild Steel (M.S), Stainless Steel (SS), Cast Iron (CI), Aluminum (AL), and Cast Alloy.</li>
                                                        <li><b>Sizes: </b>Up to 1200 mm.</li>
                                                        <li><b>Flanges: </b> Tailor-made flange types to meet your specific needs.</li>
                                                    </ol>
                                                </div>
                                            </div>

                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Key Features:</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li><b>High Filling Efficiency: </b>Ensures optimal material flow.</li>
                                                        <li><b>Sheared Angle Inlet: </b>Enhances material handling.</li>
                                                        <li><b>Minimum Air Leakage: </b> Maintains system integrity.</li>
                                                        <li><b>Explosion-Proof Models: </b> Available up to 10 bar for added safety.</li>
                                                        <li><b>Customized Solutions: </b> Tailored to your unique requirements.</li>
                                                        <li><b>Versatile Flanges: </b> Square and circular options meet international standards.</li>
                                                        <li><b>Robust Construction: </b> Adequately stiffened body prevents distortion.</li>
                                                        <li><b>Various Drive Options: </b> Choose from chain, direct, pneumatic, and hydraulic drives to suit your application.</li>
                                                    </ol>
                                                </div>
                                                <p className='mt-2'>Explore how UNICON’s Rotary Air Lock Valves can enhance your operations with their superior design and functionality.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}



const SlideGateAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Slide Gate</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">

                                            <div class="">
                                                <p>Unicon Products are distributed worldwide. Besides an extensive range of standard components, we supply international standards sanitary versions and explosions proof models. We have been designing and manufacturing Slide Gate/Knife Gate Valves for bulk solid industry for more than 30 years. Offering tailor made solutions for cement, coal, power plant, Paper, Sugar, Chemical, Steel, etc.,</p>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages:</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li>Low Profile Design and superior construction allows for easier installation.</li>
                                                        <li>Low maintenance.</li>
                                                        <li>Cast iron body with stainless steel gate operates in temperatures of 750° F.</li>
                                                        <li>Distinct drill pattern so the gate will stay in place if equipment below needs to be removed.</li>
                                                        <li>Graphite gate seals with adjustable seal retainers provide a stronger, longer lasting seal to reduce production downtime.</li>
                                                        <li>One-piece cast housing for higher durability and greater dependability.</li>
                                                        <li>Fabricated slide gates can supply in various sizes according to customer requirement.</li>
                                                    </ol>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const DoubleConeAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Double Cone Valve</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">

                                            <div class="">
                                                <p>Unicon Products are distributed worldwide. Besides a extensive range of standard components, we supply international standards sanitary versions and explosions proof models. We have been designing and manufacturing Double Cone Valves for bulk solid industry for more than 30 years. Offering tailor made solutions for cement, coal, power plant, Paper, Sugar, Chemical, Steel, etc.,</p>
                                                <p className='mb-0'>Our Double cone valves are designed specifically for long life in handling. The wide selection of available actuators and control component allow easy integration with any degree of automation. A choice of mild steel Hardox 400, Hardox 500 & stainless steel materials gives the option of selection material of construction to suit your particular needs.</p>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages:</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li>Robust design for continual operation.</li>
                                                        <li>Sizes can be supplied according to client requirement.</li>
                                                        <li>Actuation will be manual, motorized, pneumatic.</li>
                                                        <li>Rectangular and Square inlet and outlets are available.</li>
                                                        <li>100% sealing at high temperature can be provided for special requirements.</li>
                                                    </ol>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}



const DoubleFlapAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Double Flap Valve</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">

                                            <div class="">
                                                <p>UNICON distributes products globally, including a wide range of standard components, international standard sanitary versions, and explosion-proof models. With over 30 years of experience, we specialize in designing and manufacturing Double Flap Valves for the bulk solids industry. Our valves are tailored for various sectors such as cement, coal, power plants, paper, sugar, chemicals, and steel, ensuring robust performance and longevity in material handling applications.</p>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages:</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li><b>Longevity:</b>  Designed for durability and extended operational life.</li>
                                                        <li><b>Versatility:</b> Compatible with a variety of actuators and control components, facilitating integration with automation systems.</li>
                                                        <li><b>Customization:</b> Tailored solutions available to meet specific industry needs.</li>
                                                        <li><b>Material Options:</b> Choice of materials including mild steel and Hardox for enhanced wear resistance.</li>
                                                        <li><b>Global Reach:</b> Comprehensive distribution and support services worldwide ensure reliable supply and customer satisfaction.</li>
                                                    </ol>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const ClamShellAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Clam Shell Gate / Sector Gate</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">

                                            <div class="">
                                                <p>UNICON's Gear Gate Systems are engineered for durability and reliability in material handling operations. Constructed from minimum 6mm mild steel plate and reinforced as needed, these systems feature welded gear sets for precise timing and minimal maintenance. Actuated through PLC systems, they ensure smooth material flow without the need for lubrication, offering robust performance per AWS standards.</p>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages:</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li>Low Stresses Seen By the Gear Teeth during Operation.</li>
                                                        <li>Lubrication Of The Teeth Is Not Required.</li>
                                                        <li>Once the gear sets are located and properly timed, they are welded in place eliminating any further adjustment or need for maintenance.</li>
                                                        <li>The assembly and reassembly of the gate can be accomplished without affecting timing of the gate blades.</li>
                                                        <li>The gate actuation through a PLC system to control the desired opening to facilitate smooth flow of material.</li>
                                                        <li>Construction is of minimum 6mm mild steel plate, heavily reinforced where necessary. Bolting flange is constructed of heavy structural steel angle. All welding is per AWS standards. Gate pivot shafts are C-1045 steel shafting, and are attached to heavy backing plates welded to the gate body.</li>

                                                    </ol>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const DomeAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Dome Valve</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    Special feature
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages:</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Leak proof closure</li>
                                                        <li>Wear compensating</li>
                                                        <li>1 million cycles between inspections</li>
                                                        <li>Maintenance free proven design</li>
                                                        <li>Standard design to 100psig & 650°F</li>
                                                        <li>Custom designs to 600psig & 650°F</li>
                                                    </ol>
                                                </div>
                                            </div>

                                            <div className="bn-content-wrapper mt-3">
                                                <h4 className="fw-medium section-sub_heading  mb-2">High Temperature</h4>
                                                <div className="mt-2">
                                                    <p>Thermal expansion prevents consistent valve seat action. The inflatable seal provides compensation throughout the temperature range 0º to 660ºF (350ºC). Temperatures above this range may be tolerated using special valve configurations.</p>
                                                </div>
                                            </div>

                                            <div className="bn-content-wrapper mt-3">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Reliability</h4>
                                                <div className="mt-2">
                                                    <p>A heavy-duty valve designed to perform where other valves cannot. Rated for 1 million cycles between inspections in approved applications.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const DragChainAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Drag Chain Conveyor
                        </h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages of Drag Chain Conveyors</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li>Drag Chain Conveyors is a conveyor in which the open links of a chain, drag material along the bottom of a specially designed cast or forged trough.</li>
                                                        <li>The flights are fixed between two strands of chain, drags the material from feeding end to the discharge end in open or closed trough. These are available in different widths and lengths as per the siterequirements and are also used for handling slightly sluggish, loose or lumpy hot material.</li>
                                                        <li>Drag Chain Conveyors are designed for horizontal and inclined transport of bulk goods like clinker, cement, sinter dust, raw meal, etc,.</li>
                                                        <li>Main feature of our conveyors is dust-proof, low noise and sealed design.</li>
                                                        <li>Our conveyors are designed with single or multiple inlets and outlet openings provided at required locations along the conveyor path.</li>
                                                        <li>Our conveyors are manufactured according to application using Carbon Steel, Stainless Steel or other such material with various types of lining as per application requirement.</li>
                                                        <li>Chains are made of alloy steel drop forgings. They are machined and heat treated to achieve desired strength and hard outer surface to withstand wear due to abrasion.</li>
                                                        <li>Sprockets suitable for the forged chains are also manufactured by us in Carbon Steel and Alloy Carbon Steel which are case hardened for longer life.</li>
                                                        <li>Flights of different shapes and construction are welded or bolted to the chain links as per application requirement.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const BeltAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Belt Conveyor</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div>
                                                <p>Belt conveyor systems are some of the most universally used and recognized machines in any industrial setting. They are essential for transporting goods and materials, automating assembly and processing, shipping, packing, and more. One of the key reasons for their widespread use is their incredible versatility. With different conveyor belting options, configurations, and specifications, belt conveyors can be customized to accommodate a variety of needs and applications. Flat Belt Conveyors, in particular, are highly prevalent and versatile, utilizing a series of powered pulleys to move a continuous flat belt made of natural or synthetic fabrics like polyester or nylon. For conveying products up or down, inclined belt conveyors with cleated or textured belts are often the best solution, ensuring products remain secure during transport.</p>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li><b>Wide Usage: </b> Ideal for multiple applications in industrial settings.</li>
                                                        <li><b>Customizable:</b> Various belting options and configurations to suit different needs.</li>
                                                        <li><b>Versatile:</b> Suitable for flat, inclined, and cleated belt applications.</li>
                                                        <li><b>Efficient Transport:</b> Smooth and continuous movement of products.</li>
                                                        <li><b>Flexible Material Options:</b> Available in natural or synthetic fabrics like polyester and nylon.</li>
                                                        <li><b>Adaptable to Inclines: </b> Effective for transporting products up or down with cleated or textured belts.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

const ScrewAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Screw Conveyor</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div className="bn-content-wrapper ">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Comprehensive Overview of Screw Conveyors</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li className='mb-1'>Screw conveyors are used for transport of materials continuously with the help of a rotating helical screw blade (known as flights) by shear and tumbling effect inside a “U” shaped trough or a round tube. They are generally used for handling fines of coal, crushed material, powders, cement, chemicals, etc,. The periphery of leading side of the helix is provided with hard face welding for handling abrasive material on request.</li>
                                                        <li className='mb-1'>Screw conveyors are one of the most reliable and cost-effective methods for conveying bulk materials. Due to their versatility, screw conveyors can convey a wide variety of bulk materials ranging from dry, free-flowing Portland cement to wet, sluggish dewatered bio-solids.</li>
                                                        <li className='mb-1'>Efficiently distributes bulk materials to various locations using multiple inlet and discharge points.</li>
                                                        <li className='mb-1'>Horizontal screw conveyors are the most widely used type of screw conveyor. Used to convey bulk materials from one part of a process to another, horizontal screw conveyors are available in a wide range of sizes, lengths, configurations and materials of construction.</li>
                                                        <li className='mb-1'>UNICON Make Standard screw conveyors of carbon steel or stainless steel depending on the operating conditions.</li>
                                                        <li className='mb-1'>Intermediate hanger bearings are provided for long conveyors for the screw to maintain allowable sag in the rotating member.</li>
                                                        <li className='mb-1'>Proper selection of angular speed for the Screw conveyor is critical in order to avoid wobbling effect during operation.</li>
                                                        <li className='mb-1'>The tough filling factor is selected according to nature of material and also with reference to standard with typically value of around 30%, however, the drive power rating is calculated based on 100% filling factor.</li>
                                                        <li>For high temperature application a jacketed trough and box type flights are provided with circulating cooling water through, shaft & box flights. The quantity of cooling water depends on the properties & temperature of conveying material.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}




const DefenceEquipmentsAcc = () => {
    return (
        <>
            <section
                className="product-specification bg-light ip-padding wow fadeInUp"
                data-wow-delay="0s"
                style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInUp",
                }}
            >
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Defence Equipments</h4>
                    </div>

                    <div className="product-accordion">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                    >
                                        Special Features
                                    </button>
                                </h4>
                                <div
                                    id="panelsStayOpen-collapseOne"
                                    className="accordion-collapse collapse show"
                                >
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div class="">
                                                <p>
                                                    UNICON specializes in advanced manufacturing
                                                    technologies, employing various welding processes such
                                                    as SMAW, GTAW, GMAW, and FCAW. Our expertise spans a
                                                    wide range of materials, including carbon steels,
                                                    stainless steel, and high-performance alloys, with
                                                    welders qualified to international standards. We
                                                    handle the installation, testing, and commissioning of
                                                    equipment and piping across various defense sectors.
                                                    Our offerings include storage tanks, jacketed vessels,
                                                    agitators, heat exchangers, and precision machined
                                                    components. With over 30 years of experience, UNICON
                                                    designs and manufactures cost-effective, long-lasting
                                                    special equipment on a turnkey basis.
                                                </p>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">
                                                    Advantages:
                                                </h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>Expertise in advanced welding processes.</li>
                                                        <li>
                                                            Qualified welders adhering to international
                                                            standards.
                                                        </li>
                                                        <li>
                                                            Comprehensive installation and commissioning
                                                            services.
                                                        </li>
                                                        <li>Wide range of durable equipment options.</li>
                                                        <li>Over 30 years of industry experience.</li>
                                                        <li>
                                                            Cost-effective solutions utilizing modern
                                                            technologies.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const StorageTankAcc = () => {
    return (
        <>
            <section
                className="product-specification bg-light ip-padding wow fadeInUp"
                data-wow-delay="0s"
                style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInUp",
                }}
            >
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Storage Tank / Storage Silo</h4>
                    </div>

                    <div className="product-accordion">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                    >
                                        Special Features
                                    </button>
                                </h4>
                                <div
                                    id="panelsStayOpen-collapseOne"
                                    className="accordion-collapse collapse show"
                                >
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div class="">
                                                <p className="fs-5 mb-1">
                                                    UNICON’s Commitment to Quality and Safety
                                                </p>
                                                <p>
                                                    With over 30 years of experience, UNICON specializes
                                                    in the design, fabrication, supply, erection, and
                                                    commissioning of storage tanks and silos. Our in-house
                                                    non-destructive testing (NDT) facilities ensure the
                                                    highest quality standards, with rigorous pressure
                                                    testing and thorough examination of welding joints
                                                    using advanced techniques. We adhere to both Indian
                                                    and international standards, guaranteeing reliable and
                                                    safe solutions for all your storage needs.
                                                </p>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">
                                                    Advantages:
                                                </h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>
                                                            Unicon is having more than 30 years experience in
                                                            Design, Fabrication, Supply, Erection and
                                                            Commissioning of Storage Tanks & Silos.
                                                        </li>
                                                        <li>
                                                            Unicon is having in-house facilities for NDT.
                                                        </li>
                                                        <li>
                                                            Unicon Storage tanks will be pressure tested for
                                                            minimum 0.5 kg/Cm2 pressure.
                                                        </li>
                                                        <li>
                                                            All welding joints will be tested with DP, UT, RT,
                                                            etc,. as per requirement.
                                                        </li>
                                                        <li>
                                                            Manufacturing technology involving welding process
                                                            such as SMAW, GTAW, GMAW, FCAW etc,.
                                                        </li>
                                                        <li>
                                                            Storage Tanks and Silos are manufactured as per
                                                            Indian as well as International Standards.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const AgitatorsAcc = () => {
    return (
        <>
            <section
                className="product-specification bg-light ip-padding wow fadeInUp"
                data-wow-delay="0s"
                style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInUp",
                }}
            >
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Agitators</h4>
                    </div>

                    <div className="product-accordion">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                    >
                                        Special Features
                                    </button>
                                </h4>
                                <div
                                    id="panelsStayOpen-collapseOne"
                                    className="accordion-collapse collapse show"
                                >
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div class="">
                                                <p className="fs-5 mb-1">
                                                    UNICON’s Commitment to Quality and Safety
                                                </p>
                                                <p>
                                                    UNICON agitators offer adjustable speeds ranging from
                                                    500-6,000 RPM for smaller models and 300-3,000 RPM for
                                                    larger units, ensuring minimal material degradation
                                                    and enhanced quality. With various designs—including
                                                    manual mixers, pale-mounted, bung, and C-clamp
                                                    options—our agitators are versatile for different
                                                    applications. Equipped with wide, full-width
                                                    impellers, they provide thorough mixing, reducing
                                                    sediment buildup in tanks. Additionally, air motors
                                                    ranging from 0.25 kW to 3 kW are available, either
                                                    integrated or separately.
                                                </p>
                                            </div>
                                            <div className="bn-content-wrapper mt-4">
                                                <h4 className="fw-medium section-sub_heading  mb-2">
                                                    Advantages:
                                                </h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 text-dark">
                                                        <li>
                                                            Adjustable Speed for optimal mixing control.
                                                        </li>
                                                        <li>
                                                            Minimized Material Degradation for better quality.
                                                        </li>
                                                        <li>
                                                            Versatile Designs to suit various applications.
                                                        </li>
                                                        <li>Effective Mixing with wide impellers.</li>
                                                        <li>
                                                            Reduced Sediment Buildup in tanks and drums.
                                                        </li>
                                                        <li>
                                                            Range of Air Motors for flexible power options.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const JaketedAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Jacketed Vessels</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div>
                                                <p>Jacketed vessels are essential in chemical engineering for controlling the temperature of their contents through a circulating heating or cooling fluid. The jacket, an external cavity, ensures uniform heat exchange with the vessel walls. Various jacket designs, including conventional, half-pipe coil, and dimple jackets, allow for flexibility in application. These vessels are utilized as reactors to dissipate heat from reactions or to reduce the viscosity of thick fluids, with agitation enhancing the homogeneity of properties like temperature and concentration. UNICON is your comprehensive source for jacketed vessels, offering expertise in all design types and material combinations, along with additional features like baffles and agitators.</p>
                                            </div>
                                            <div className="bn-content-wrapper ">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li>Efficient Temperature Control through jacketed design.</li>
                                                        <li>Versatile Applications for chemical reactions and viscosity reduction.</li>
                                                        <li>Multiple Jacket Designs to meet specific needs.</li>
                                                        <li>Custom Material Combinations available for vessels and jackets.</li>
                                                        <li>Enhanced Homogeneity with agitation options.</li>
                                                        <li>Expert Guidance from UNICON for optimal solutions.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

const PressureAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Pressure Vessels
                        </h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div>
                                                <p>Pressure differentials in pressure vessels can pose significant dangers, and historical accidents highlight the importance of stringent design and manufacturing standards. UNICON’s pressure vessels are engineered in compliance with regulations set by recognized engineering authorities, ensuring safety across various applications. Used in sectors ranging from industrial compressed air systems to domestic hot water tanks, our vessels are versatile and designed to meet specific operational requirements. Available in carbon steel, mild steel, or stainless steel, UNICON pressure vessels are equipped with built-in temperature control systems and gauges for monitoring internal pressure and temperature.</p>
                                            </div>
                                            <div className="bn-content-wrapper ">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li>Safety Compliance with engineering regulations.</li>
                                                        <li>Versatile Applications across multiple industries.</li>
                                                        <li>Materials Selection tailored to specific needs.</li>
                                                        <li>Temperature Control Systems for volatile chemicals.</li>
                                                        <li>Custom Designs to fit installation space.</li>
                                                        <li>Integrated Gauges for accurate monitoring of conditions.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const DecomposersAcc = () => {
    return (
        <>
            <section className="product-specification bg-light ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="contentwrapper p-relative text-center mb-sm-5 mb-4">
                        <h4 className="section-heading text-dark">Decomposers</h4>
                    </div>

                    <div className="product-accordion">

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item mb-4">
                                <h4 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Special Features
                                    </button>
                                </h4>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bn-content-bucket">
                                            <div>
                                                <p>UNICON is renowned for its expertise in producing high-quality surface finishes, achieving a precise 400 grit (0.2 microns). Our inner and outer jackets for decomposers, supplied to the Ordnance Factory, undergo rigorous surface roughness testing. This exceptional finish is accomplished through a specialized surface treatment process, ensuring durability and performance.</p>
                                            </div>
                                            <div className="bn-content-wrapper ">
                                                <h4 className="fw-medium section-sub_heading  mb-2">Advantages</h4>
                                                <div className="mt-2">
                                                    <ol className="list-disc ps-3 mb-0 text-dark">
                                                        <li>High-Quality Surface Finish of 400 grit (0.2 microns).</li>
                                                        <li>Rigorous Surface Roughness Testing for quality assurance.</li>
                                                        <li>Supplied to Ordnance Factory, ensuring reliability.</li>
                                                        <li>Specialized Surface Treatment Process for superior results.</li>
                                                        <li>Expertise in Precision Manufacturing for enhanced performance.</li>
                                                        <li>Durability that meets industry standards.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
export {
    BagHouseAcc, AmmoniaAcc, PcsAcc, RotaryAcc, SlideGateAcc, DoubleConeAcc, DoubleFlapAcc, ClamShellAcc, DomeAcc, DragChainAcc, BeltAcc, ScrewAcc,
    DefenceEquipmentsAcc,
    StorageTankAcc,
    AgitatorsAcc, JaketedAcc, PressureAcc, DecomposersAcc
};