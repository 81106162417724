import React from "react";
import { SilencersTable } from "../../../common/Components/TablesProduct";
import { Link } from "react-router-dom";

const SectionFour = () => {
  return (
    <>
      <section class="product-specification ip-padding" id="prodSpecs">
        <div class="container">
          <div class="spec-wrapper">
            <div class="row g-lg-5 g-3">
              <div
                class="col-lg-5 col-md-12 wow fadeInLeft"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <div
                  class="bn-content-wrapper wow fadeInLeft"
                  data-wow-delay=".5s"
                  style={{
                    visibility: "visible",
                    animationDelay: ".4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <span class="section-sub-title  d-block">Silencers</span>
                  <h4 class="section-heading mb-4">Product Specification</h4>
                </div>
                <figure class="image m-img pt-0">
                  <img
                    src="/images/products/silencers/tab-img.webp"
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "8px" }}
                  />
                </figure>

              </div>
              <div
                class="col-lg-7 col-md-12 wow fadeInRight"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: ".4s",
                  animationName: "fadeInLeft",
                }}
              >
                <div class="specc-wrapper ps-md-0 ps-lg-2">
                  <div class="table-responsive">
                    <SilencersTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <style>
        {`
            .text-primary {
                color: #0e2e7e !important;
            }
        `}
      </style>
    </>
  );
};

export default SectionFour;
